import React, { useState, useEffect } from 'react';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

// components
import AwesomeLink from '@/components/common-components/AwesomeLink';
import OrderHistoryOrderDetails from './OrderHistoryOrderDetails';
import { ReferralIdsDisplay } from '@/components/common-components/ReferralIdsDisplay';

const OrderHistoryArchivedTab = ({ orders }) => {
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrderInfo, setSelectedOrderInfo] = useState({});
  const [selectedRxOrder, setSelectedRxOrder] = useState({});

  const [itemAdministrations, setItemAdministrations] = React.useState([]);
  const [itemPreMeds, setItemPreMeds] = React.useState([]);

  useEffect(() => {
    if (selectedOrderInfo.archivedReferral) {
      setItemAdministrations(
        selectedOrderInfo.archivedReferral.referralOrder.administrations.map(
          (item) => ({
            ...item,
            text: item.orderName,
            value: item.orderName
          })
        )
      );

      setItemPreMeds(
        selectedOrderInfo.archivedReferral.referralOrder.preMedications.map(
          (item) => ({
            ...item,
            text: item.orderName,
            value: item.orderName
          })
        )
      );
    }
    setSelectedRxOrder(selectedOrderInfo?.archivedReferral?.rxOrderFile);
  }, [selectedOrderInfo]);

  const handleOnRowClick = (dataItem) => {
    if (dataItem.orderName) {
      setSelectedOrderInfo({ ...dataItem });
      setShowOrderDetails(true);
    }
  };

  const dosageCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem.dosage} {dataItem.unitOfMeas}
      </td>
    );
  };

  const calcDosageCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem.calculated} {dataItem.unitOfMeas}
      </td>
    );
  };

  const viewDetailsCell = ({ dataItem }) => {
    return (
      <td>
        <AwesomeLink
          onClick={() => handleOnRowClick(dataItem)}
          icon='magnifying-glass'
          label='View Details'
        />
      </td>
    );
  };

  const orderNameCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem?.orderName}
        <ReferralIdsDisplay
          referralId={dataItem?.archivedReferral?.referralId}
          referralUUID={dataItem?.archivedReferral?.referralUUID}
          grid
        />
      </td>
    );
  };

  return (
    <>
      <div className='col-md-12'>
        <div className='row pt-3' style={{ minHeight: '650px' }}>
          <div className='col'>
            <Grid data={orders}>
              <Column title='ORDER NAME' cell={orderNameCell} width={'300px'} />
              <Column title='DOSAGE' cell={dosageCell} />
              <Column
                field='calculated'
                title='CALC DOSAGE'
                cell={calcDosageCell}
              />
              <Column field='orderDate' title='ORDER DATE' />
              <Column field='archivedDate' title='ARCHIVED DATE' />
              <Column field='expirationDate' title='EXP DATE' />
              <Column field='agentId' title='AGENT' />
              <Column cell={viewDetailsCell} />
            </Grid>
          </div>
        </div>
      </div>
      {showOrderDetails && (
        <OrderHistoryOrderDetails
          selectedOrderInfo={{
            ...selectedOrderInfo,
            itemAdministrations,
            itemPreMeds,
            selectedRxOrder
          }}
          onClose={() => setShowOrderDetails(false)}
        />
      )}
    </>
  );
};

export default OrderHistoryArchivedTab;
