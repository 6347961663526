import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// gql
import { connectToGraphqlAPI, getQueryClient } from '@/provider';
import {
  getProgramsByPatient,
  getPatientBucket,
  getPatientLocker,
  getPatientWorkItems,
  getBillingHistoryByPatientId,
  checkIsNewPatient,
  getScheduleEventsByPatient,
  getSystemScheduledItemsByPatient
} from '@/graphql/queries';
import { addUpdateNotes, releaseLocker } from '@/graphql/mutations';

// utils
import { formatDateToAWSDateTime } from '@/common/DateHelper';
import { patientStatusCodes } from '@/constants/enum';

// context
import { LogContext, StatusContext, UserContext, NotifContext } from './index';

export const PatientContext = createContext();
export const PatientConsumer = PatientContext.Consumer;

const PatientContextProvider = ({ children }) => {
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);
  const { showError, showWarning } = useContext(NotifContext);
  const { logApiException } = useContext(LogContext);
  const { agentId } = useContext(UserContext);
  const [summaryPatientInfo, setSummaryPatientInfo] = useState({
    patientId: null
  });
  const [selectedPatientInfo, _setSelectedPatientInfo] = useState({
    patientId: null
  });
  const [isNewPatient, setIsNewPatient] = useState(false);
  // used to store patient appointment data
  const [patientAppointments, setPatientAppointments] = useState();

  // uses for generating PDF logic in completed infusions that required FAX sending
  const [completedTreatment, setCompletedTreatment] = useState(null);

  const [selectedLocker, setSelectedLocker] = useState('');
  const [selectedTab, setSelectedTab] = useState();

  // organize patient prescriber data
  const [patientPrescribers, setPatientPrescribers] = useState([]);

  // patient billing data
  const [patientBillings, setPatientBillings] = useState();

  // patient status drawer
  const [showStatusLog, setShowStatusLog] = useState(false);

  // filtered includes only most recent (updated) billing data
  const [recentBillings, setRecentBillings] = useState();

  // \/\/\/\/\/\/\/\/\//\/\/\\//\/\/\/\/\/\/\/\/\/\/\/\/\/\/\\/\/\/\/\/\/\/

  const [patientScheduledItems, setPatientScheduledItems] = useState(null);

  const getSystemScheduledItemsByPatientCall = async (id) => {
    const patientId = id || selectedPatientInfo?.patientId;

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getSystemScheduledItemsByPatient,
        variables: { patientId, sortDirection: 'ASC' }
      });

      if (data?.data?.getSystemScheduledItemsByPatient) {
        const patientStatusData =
          data.data.getSystemScheduledItemsByPatient?.items;
        // Filter out items with itemStatus equal to INACTIVE
        const filteredPatientStatusData = patientStatusData.filter(
          (item) => item.itemStatus !== patientStatusCodes.INACTIVE
        );
        const hasStatusData = !!filteredPatientStatusData;
        setPatientScheduledItems(
          hasStatusData ? filteredPatientStatusData : []
        );
      }
    } catch (err) {
      showError(
        'Failed to retrive patient scheduled event items. Check console for details.'
      );
      console.error(
        'PatientContext::getSystemScheduledItemsByPatientCall err:',
        err
      );
      logApiException(err, {
        view: 'PatientContext',
        endpoint: 'getSystemScheduledItemsByPatient',
        patientId
      });
    }
  };

  const history = useHistory();
  const { pathname } = history.location;
  const isPatientPortal = pathname === '/patient-portal';

  const getPatientBillingHistory = async (patientId) => {
    setPatientBillings();
    setRecentBillings();

    try {
      const data = await getQueryClient({
        query: getBillingHistoryByPatientId,
        path: 'getBillingHistoryByPatient',
        payload: { patientId }
      });
      if (data.length) {
        // first need to parse AWSJSON to JSON (if present)
        data.forEach((item) => {
          if (item.treatmentHistory) {
            item.treatmentHistory = JSON.parse(item.treatmentHistory);
          }
        });
        setPatientBillings(data);

        // filter only most recent billing data
        const filteredData = [];

        // define unique NPIDs list
        const uniqueIds = [
          ...new Set(data.map((item) => item.nursingProcessId))
        ];

        // now walk through the list and find the most recent one
        uniqueIds.forEach((id) => {
          // first - filter by NPID
          const infusionBillings = data.filter(
            (billing) => billing.nursingProcessId === id
          );

          if (infusionBillings.length) {
            // second - find the recent one
            const mostRecent = infusionBillings.reduce((prev, current) => {
              return new Date(
                prev.treatmentHistory?.infusionDetail?.updatedAt
              ) > new Date(current.treatmentHistory?.infusionDetail?.updatedAt)
                ? prev
                : current;
            });

            filteredData.push(mostRecent);
          }
        });

        setRecentBillings(filteredData.length ? filteredData : []);
      } else {
        setPatientBillings([]);
        setRecentBillings([]);
      }
    } catch (error) {
      console.error('PatientContext::getPatientBillingHistory error: ', error);
      logApiException(error, {
        view: 'PatientContext',
        endpoint: 'getBillingHistoryByPatient',
        patientId
      });
    }
  };

  const getPatientProgrammsCall = async (agentId, patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getProgramsByPatient,
        variables: { agentId, patientId }
      });

      if (data?.data?.getProgramsByPatient) {
        const isInViiv = data.data.getProgramsByPatient.programs.some(
          (program) => program.id === 'viiv'
        );
        const isInPDP = data.data.getProgramsByPatient.programs.some(
          (program) => program.id === 'pdp'
        );

        setSelectedPatientInfo((prev) => ({ ...prev, isInViiv, isInPDP }));
      }
    } catch (err) {
      console.error('PatientContext::getPatientProgrammsCall err:', err);
    }
  };

  const isPatientLocked = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientLocker,
        variables: { caseId: patientId }
      });
      // API returns null if the patient is not locked
      if (!data?.data?.getPatientLocker) {
        return false;
      }
      const lockedAgent = data?.data?.getPatientLocker?.lockedBy;
      showWarning(
        `Patient locked by ${lockedAgent}. Retrieving next available item.`
      );
      return true;
    } catch (error) {
      console.error('PatientContext::isPatientLocked error: ', error);
      logApiException(error, {
        view: 'PatientContext',
        endpoint: 'getPatientLocker'
      });
      showError('Error at getPatientLocker method. Check console for details');
    }
  };

  const definePatientPreferences = async (patientInfo) => {
    let pInfo = { ...patientInfo };

    if (!patientInfo?.patientProfile?.patientInfo?.preference) {
      const bucketInfo = await getPatientBucketCall(patientInfo.patientId);
      pInfo = { ...patientInfo, ...bucketInfo };
      setSelectedPatientInfo((prev) => ({ ...prev, ...pInfo }));
    }

    const isSurveyOptedOut =
      pInfo?.patientProfile?.patientInfo?.preference?.surveys?.preferred ===
      false;

    return { isSurveyOptedOut };
  };

  const getPatientBucketCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientBucket,
        variables: { patientId }
      });

      if (data?.data?.getPatientBucket) {
        const patientInfo = data.data.getPatientBucket;

        // add prescribers info separately
        if (patientInfo.hcpProfile?.items?.length > 0) {
          // at the backend level this list is not flat, so we need to flatten it
          const flattenPrescribers = patientInfo.hcpProfile.items.map(
            (prescriber) => {
              // some addresses are empty, so we need to generate better title to be displayed
              const officeAddresses = prescriber.prescriber.officeAddresses.map(
                (address) => {
                  return {
                    ...address,
                    addressTitle: `${address.streetName}, ${address.city}, ${address.state}`
                  };
                }
              );
              return {
                id: prescriber.prescriberId,
                fullName: `${prescriber.prescriber.prescriberFirstName} ${prescriber.prescriber.prescriberLastName}`,
                ...prescriber.prescriber,
                officeAddresses
              };
            }
          );
          setPatientPrescribers(flattenPrescribers);
        } else {
          setPatientPrescribers([]);
        }

        setSelectedPatientInfo((prev) => ({
          ...prev,
          ...patientInfo
        }));

        // reset flag, since it'll be valid only when we get work items by patient later
        setIsNewPatient(false);
        return patientInfo;
      }
    } catch (error) {
      console.error('PatientContext::getPatientBucketCall err:', error);
      logApiException(error, {
        view: 'PatientContext',
        endpoint: 'getPatientBucket',
        patientId
      });
    }
  };

  const addPatientNotes = async ({ agentId, patientId, notes, type }) => {
    try {
      const res = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: {
          input: {
            agentId,
            patientId,
            notes: [
              {
                date: formatDateToAWSDateTime(),
                note: notes,
                type,
                createdBy: agentId,
                modifiedNote: false // send false to add new
              }
            ]
          }
        }
      });

      return res;
    } catch (error) {
      console.error('PatientContext::addPatientNotes error: ', error);
      logApiException(error, {
        view: 'PatientContext',
        endpoint: 'addPatientNotes',
        agentId,
        patientId,
        type
      });
    }
  };

  const goToProfile = async (patientId) => {
    // fetch related user data before we redirect to its profile
    const data = await getPatientBucketCall(patientId);
    if (data?.data?.getPatientBucket) {
      setSelectedPatientInfo(data.data.getPatientBucket);
      setStatusUpdate(!statusUpdate);
      setIsNewPatient(false);
    }
    // redirect to the patient profile
    // if the user is not in patient portal, redirect to patient portal and if selecting named link only redirect if not in patient portal.
    if (!isPatientPortal) {
      history.push('/patient-portal', {
        searchType: 'Patient'
      });
    }
  };

  const releasePatientLocker = async () => {
    try {
      if (selectedLocker && selectedLocker.length > 0) {
        await connectToGraphqlAPI({
          graphqlQuery: releaseLocker,
          variables: {
            agentId,
            lockerId: selectedLocker
          }
        });
        setSelectedLocker('');
      }
    } catch (err) {
      console.error(
        'PatientContext::releasePatientLocker releaseWork err:',
        err
      );

      logApiException(err, {
        view: 'PatientContext',
        endpoint: 'releaseLocker',
        lockerId: selectedLocker,
        agentId
      });
    }
  };

  // Pulls patient appointment data
  const getPatientAppointmentData = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getScheduleEventsByPatient,
        variables: { patientId }
      });
      if (data?.data?.getScheduleEventsByPatient?.events) {
        setPatientAppointments(data.data.getScheduleEventsByPatient.events);
        return data.data.getScheduleEventsByPatient.events;
      }
    } catch (err) {
      console.error('PatientContext::getPatientAppointmentData err:', err);
    }
  };

  // returns ALL work items for patient no matter of their status
  const getPatientWorkItemsCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientWorkItems,
        variables: { patientId }
      });

      if (data?.data?.getAllWorkItemsByPatient?.items) {
        return data.data.getAllWorkItemsByPatient.items;
      }
      return [];
    } catch (err) {
      console.error('PatientContext::getPatientWorkItemsCall err:', err);
    }
  };

  const hasPrescribers = () => {
    return patientPrescribers.length > 0;
  };

  // add some calc props to the patient info
  const setSelectedPatientInfo = (patientInfo) => {
    // add full name
    patientInfo.displayName = `${patientInfo.patientFirstName} ${patientInfo.patientLastName}`;

    _setSelectedPatientInfo(patientInfo);
  };

  // if patient doesn't have work items - we still can check if it's new patient using this API call
  // be careful, this is very expensive call, so use it wisely
  const checkIsNewPatientCall = async (patientId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: checkIsNewPatient,
        variables: { patientId }
      });

      if (data?.data?.isNewPatient?.statusCode === '200') {
        try {
          const status = JSON.parse(data.data.isNewPatient.results);
          return status.newPatient || false;
        } catch (error) {
          console.error(
            'PatientContext::checkIsNewPatientCall, JSON parsing error: ',
            error
          );
        }
      } else return false;
    } catch (err) {
      console.error('PatientContext::checkIsNewPatientCall err:', err);
    }
  };

  const findSnapshotsByNursingProcessId = (nursingProcessId) => {
    if (patientBillings) {
      return patientBillings.filter(
        (billing) =>
          billing.nursingProcessId === nursingProcessId &&
          billing.treatmentHistory
      );
    }

    return null;
  };

  return (
    <PatientContext.Provider
      value={{
        releasePatientLocker,
        summaryPatientInfo,
        setSummaryPatientInfo,
        selectedPatientInfo,
        setSelectedPatientInfo,
        setSelectedLocker,
        selectedLocker,
        completedTreatment,
        setCompletedTreatment,
        getPatientProgrammsCall,
        selectedTab,
        setSelectedTab,
        definePatientPreferences,
        getPatientBucketCall,
        goToProfile,
        addPatientNotes,
        isPatientLocked,
        getPatientWorkItemsCall,
        patientPrescribers,
        hasPrescribers,
        getPatientBillingHistory,
        patientBillings,
        isNewPatient,
        setIsNewPatient,
        setShowStatusLog,
        showStatusLog,
        checkIsNewPatientCall,
        patientScheduledItems,
        setPatientScheduledItems,
        getSystemScheduledItemsByPatientCall,
        checkIsNewPatientCall,
        recentBillings,
        findSnapshotsByNursingProcessId,
        getPatientAppointmentData
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};

export default PatientContextProvider;
