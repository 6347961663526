// general schema doc types - enum PatientDocumentType from schema
export const DOC_TYPE_CHART_NOTES = 'ChartNotes';
export const DOC_TYPE_CHART_NOTES_TITLE = 'Chart Notes';
export const DOC_TYPE_TREATMENT_NOTES = 'TreatmentNotes';
export const DOC_TYPE_TREATMENT_NOTES_TITLE = 'Treatment Notes';
export const DOC_TYPE_CLINICAL = 'Clinical';
export const DOC_TYPE_CLINICAL_TITLE = 'Clinical';
export const DOC_TYPE_CONSENT_FORM = 'ConsentForm';
export const DOC_TYPE_CONSENT_FORM_TITLE = 'Consent Form';
export const DOC_TYPE_FIN_OBLIGATION = 'FinancialObligation';
export const DOC_TYPE_FIN_OBLIGATION_TITLE = 'Financial Obligation';
export const DOC_TYPE_FREE_DRUG = 'FreeDrug';
export const DOC_TYPE_FREE_DRUG_TITLE = 'Free Drug';
export const DOC_TYPE_INSURANCE_CARD = 'InsuranceCard';
export const DOC_TYPE_INSURANCE_CARD_TITLE = 'Insurance Card';
export const DOC_TYPE_LAB_TESTS = 'LabTests';
export const DOC_TYPE_LAB_TESTS_TITLE = 'Lab Test Results';
export const DOC_TYPE_LAB_DRAW_TITLE = 'Lab Draw Order';
export const DOC_TYPE_LAB_DRAW = 'LabDrawOrder';
export const DOC_TYPE_LAB_REQUISITION_TITLE = 'Lab Requisition';
export const DOC_TYPE_LAB_REQUISITION = 'LabRequisition';
export const DOC_TYPE_ORDER_REFILL = 'RefillOrder';
export const DOC_TYPE_ORDER_REFILL_TITLE = 'Refill Order';
export const DOC_TYPE_OTHER = 'Other';
export const DOC_TYPE_OTHER_TITLE = 'Other';
export const DOC_TYPE_PATIENT_ENROLLMENT = 'PatientEnrollment';
export const DOC_TYPE_PATIENT_ENROLLMENT_TITLE = 'Patient Enrollment';
export const DOC_TYPE_PATIENT_RIGHTS = 'PatientRights';
export const DOC_TYPE_PATIENT_RIGHTS_TITLE = 'Patient Rights';
export const DOC_TYPE_PRESCRIPTION = 'Prescription';
export const DOC_TYPE_PRESCRIPTION_TITLE = 'Prescription';
export const DOC_TYPE_PRIOR_AUTH_INFO = 'PriorAuthInfo';
export const DOC_TYPE_PRIOR_AUTH_INFO_TITLE = 'Prior Auth';
export const DOC_TYPE_PRIOR_AUTH_APPROVAL = 'PriorAuthApproval';
export const DOC_TYPE_PRIOR_AUTH_APPROVAL_TITLE = 'Prior Auth Approval';
export const DOC_TYPE_PRIOR_AUTH_DENIAL = 'PriorAuthDenial';
export const DOC_TYPE_PRIOR_AUTH_DENIAL_TITLE = 'Prior Auth Denial';
export const DOC_TYPE_REFERRAL = 'Referral';
export const DOC_TYPE_REFERRAL_TITLE = 'Referral';
export const DOC_TYPE_REFERRAL_ADDENDUM = 'ReferralAddendum';
export const DOC_TYPE_REFERRAL_ADDENDUM_TITLE = 'Referral Addendum';
export const DOC_TYPE_RX_ORDER = 'RXOrder';
export const DOC_TYPE_RX_ORDER_TITLE = 'RXOrder';

export const patientDocTypesWithWorkItems = [
  DOC_TYPE_CHART_NOTES,
  DOC_TYPE_PRIOR_AUTH_INFO,
  DOC_TYPE_PRIOR_AUTH_APPROVAL,
  DOC_TYPE_PRIOR_AUTH_DENIAL,
  DOC_TYPE_LAB_TESTS,
  DOC_TYPE_LAB_DRAW,
  DOC_TYPE_OTHER,
  DOC_TYPE_REFERRAL,
  DOC_TYPE_ORDER_REFILL
];

// enum PatientDocumentType on schema
export const patientDocumentTypes = [
  {
    title: DOC_TYPE_CHART_NOTES_TITLE,
    value: DOC_TYPE_CHART_NOTES
  },
  {
    title: DOC_TYPE_CLINICAL_TITLE,
    value: DOC_TYPE_CLINICAL
  },
  {
    title: DOC_TYPE_CONSENT_FORM_TITLE,
    value: DOC_TYPE_CONSENT_FORM
  },
  {
    title: DOC_TYPE_FIN_OBLIGATION_TITLE,
    value: DOC_TYPE_FIN_OBLIGATION
  },
  {
    title: DOC_TYPE_FREE_DRUG_TITLE,
    value: DOC_TYPE_FREE_DRUG
  },
  {
    title: DOC_TYPE_INSURANCE_CARD_TITLE,
    value: DOC_TYPE_INSURANCE_CARD
  },
  {
    title: DOC_TYPE_LAB_TESTS_TITLE,
    value: DOC_TYPE_LAB_TESTS
  },
  {
    title: DOC_TYPE_LAB_REQUISITION_TITLE,
    value: DOC_TYPE_LAB_REQUISITION
  },
  {
    title: DOC_TYPE_LAB_DRAW_TITLE,
    value: DOC_TYPE_LAB_DRAW
  },
  {
    title: DOC_TYPE_OTHER_TITLE,
    value: DOC_TYPE_OTHER
  },
  {
    title: DOC_TYPE_PATIENT_ENROLLMENT_TITLE,
    value: DOC_TYPE_PATIENT_ENROLLMENT
  },
  {
    title: DOC_TYPE_PRESCRIPTION_TITLE,
    value: DOC_TYPE_PRESCRIPTION
  },
  {
    title: DOC_TYPE_PRIOR_AUTH_INFO_TITLE,
    value: DOC_TYPE_PRIOR_AUTH_INFO
  },
  {
    title: DOC_TYPE_PRIOR_AUTH_APPROVAL_TITLE,
    value: DOC_TYPE_PRIOR_AUTH_APPROVAL
  },
  {
    title: DOC_TYPE_PRIOR_AUTH_DENIAL_TITLE,
    value: DOC_TYPE_PRIOR_AUTH_DENIAL
  },
  {
    title: DOC_TYPE_REFERRAL_TITLE,
    value: DOC_TYPE_REFERRAL
  },
  {
    title: DOC_TYPE_REFERRAL_ADDENDUM_TITLE,
    value: DOC_TYPE_REFERRAL_ADDENDUM
  },
  {
    title: DOC_TYPE_ORDER_REFILL_TITLE,
    value: DOC_TYPE_ORDER_REFILL
  },
  {
    title: DOC_TYPE_TREATMENT_NOTES_TITLE,
    value: DOC_TYPE_TREATMENT_NOTES
  },
  {
    title: DOC_TYPE_PATIENT_RIGHTS_TITLE,
    value: DOC_TYPE_PATIENT_RIGHTS
  }
];

export const workItemStatuses = [
  {
    code: 'CANCELLED_INFUSION',
    text: 'Infusion Cancelled'
  },
  {
    code: 'COMPLETED_INFUSION',
    text: 'Infusion Completed'
  },
  {
    code: 'CANCELLED',
    text: 'Infusion Cancelled'
  },
  {
    code: 'SCHEDULED',
    text: 'Appointment Scheduled'
  },
  {
    code: 'RESCHEDULED',
    text: 'Appointment Rescheduled'
  },
  {
    code: 'STARTED_INFUSION',
    text: 'Infusion Started'
  },
  {
    code: 'CHECKED_IN',
    text: 'Checked In'
  },
  {
    code: 'INVALID',
    text: 'Invalid'
  }
];

export const noteTypes = [
  { value: 'ALLERGIES', text: 'Allergies' },
  { value: 'APPOINTMENT', text: 'Appointment' },
  { value: 'APPOINTMENT_NOTE', text: 'Appointment Note' },
  { value: 'AQCCA', text: 'AQCCA' },
  { value: 'BENEFIT_INVESTIGATION', text: 'Benefits Inv' },
  { value: 'BILLING', text: 'Billing' },
  { value: 'CANCEL_TREATMENT', text: 'Cancel Treatment' },
  { value: 'CLINICAL', text: 'Clinical' },
  { value: 'CLOSE_TREATMENT', text: 'Close Treatment' },
  { value: 'COPAY_ASSISTANCE', text: 'Copay Assistance' },
  { value: 'FOLLOW_UP', text: 'Follow-Up' },
  { value: 'FINANCIAL_ASSIST_CALL', text: 'Financial Assist Call' },
  { value: 'FREE_DRUG', text: 'Free Drug' },
  { value: 'INTAKE', text: 'Intake' },
  { value: 'LAB_TESTS', text: 'Lab Tests' },
  { value: 'NURSING', text: 'Nursing' },
  { value: 'OUTBOUND_CALL', text: 'Outbound Call' },
  { value: 'PATIENT_COMMUNICATIONS', text: 'Patient Communications' },
  { value: 'PAYER', text: 'Payor' },
  { value: 'PAYMENT', text: 'Payment' },
  { value: 'PENDING_PA_APPROVAL', text: 'Pending PA Approval' },
  { value: 'PRIOR_AUTHORIZATION', text: 'Prior Auth' },
  { value: 'PRESCRIBER', text: 'Prescriber' },
  { value: 'REFERRAL', text: 'Referral' },
  { value: 'REFERRAL_NEW', text: 'Referral New' },
  { value: 'REFERRAL_REFILL', text: 'Referral Refill' },
  { value: 'REFERRAL_RENEWAL', text: 'Referral Renewal' },
  { value: 'REFERRAL_UPDATES', text: 'Referral Updates' },
  { value: 'RESCHEDULE', text: 'Reschedule' },
  { value: 'SCHEDULE', text: 'Scheduling' },
  { value: 'SCHEDULING_INBOUND_CALL', text: 'Scheduling Inbound Call' },
  { value: 'SCHEDULING_OUTBOUND_CALL', text: 'Scheduling Outbound Call' },
  { value: 'VIIV_CALL', text: 'VIIV Call' },
  { value: 'WELCOME_CALL', text: 'Welcome Call' }
];

export const workItemTitles = {
  NEW_REFERRAL: 'Complete New Referral',
  ORDER_REFILL: 'Complete Order Refill',
  PATIENT_CALLS_MISSING_INFO: 'Complete Patient Profile (Missing Info)'
};

export const workItemArchiveReasons = [
  { id: 1, text: 'Referral Entry Error - Wrong Drug' },
  { id: 2, text: 'Referral Entry Error - Wrong Dosage' },
  { id: 3, text: 'Referral Entry Error - Wrong MD' },
  { id: 4, text: 'Referral Entry Error - Wrong # Treatments' },
  { id: 5, text: 'Referral Entry Error - Wrong ICD' },
  { id: 6, text: 'Referral Entry Error - Wrong date(s)' },
  { id: 7, text: 'Referral Entry Error - Wrong route' },
  { id: 8, text: 'Referral Entry Error - Wrong UOM' },
  { id: 9, text: 'Referral Entry Error - Other' },
  { id: 10, text: 'Treatment Complete' },
  { id: 11, text: 'New Referral Received' },
  { id: 12, text: 'Patient Deceased' },
  { id: 13, text: 'MD Discontinued' },
  { id: 14, text: 'Patient Discontinued' },
  { id: 15, text: 'MD changed drug - Non-AIC Drug' },
  { id: 16, text: 'MD changed drug - AIC drug' },
  { id: 17, text: 'Other' }
];
export const workItemCancelReasons = [
  { id: 1, text: 'Duplicate' },
  { id: 2, text: 'Referral Entry Error - Wrong Dosage' }
];

export const biWorkItemCancelReasons = [
  { id: 1, text: 'Plan OON' },
  { id: 2, text: 'Not Credentialed' },
  { id: 3, text: 'Not Contracted' },
  { id: 4, text: 'Cancelled' },
  { id: 5, text: 'Specialty' }
];

export const taskStatus = {
  ARCHIVED: 'ARCHIVED',
  ALERTED: 'ALERTED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  ON_HOLD: 'ON_HOLD',
  OPENED: 'OPENED',
  OVERDUE: 'OVERDUE',
  PENDING: 'PENDING',
  REASSIGNED: 'REASSIGNED',
  RESCHEDULED: 'RESCHEDULED',
  SNOOZED: 'SNOOZED'
};

export const eventStatus = {
  CHECKED_IN: 'CHECKED_IN',
  CONFIRMED: 'CONFIRMED',
  SCHEDULED: 'SCHEDULED',
  CANCELLED: 'CANCELLED',
  RESCHEDULED: 'RESCHEDULED',
  CANCELLED_INFUSION: 'CANCELLED_INFUSION',
  ORDER_REQUESTED: 'ORDER_REQUESTED',
  COMPLETED_INFUSION: 'COMPLETED_INFUSION',
  STARTED_INFUSION: 'STARTED_INFUSION',
  INVALID: 'INVALID',
  PENDING: 'PENDING',
  COMPLETED_LAB_DRAW: 'COMPLETED_LAB_DRAW',
  STARTED_LAB_DRAW: 'STARTED_LAB_DRAW'
};

export const queueNameType = {
  INTAKE: 'INTAKE',
  BI: 'BI',
  PA: 'PA',
  SALES: 'SALES'
};

export const workItemTypes = {
  RE_VERIFICATION: 'RE_VERIFICATION',
  NEW_REFERRAL: 'NEW_REFERRAL',
  UPDATE_LAB_TESTS: 'UPDATE_LAB_TESTS',
  REFILL: 'REFILL',
  MISCELLANEOUS: 'MISCELLANEOUS',
  FOLLOW_UP: 'FOLLOW_UP',
  PLAN_OON: 'PLAN_OON',
  All_EXPIRED_REFERRALS: 'All_EXPIRED_REFERRALS',
  OUTBOUND_CALL: 'OUTBOUND_CALL',
  CHART_NOTES: 'CHART_NOTES',
  PENDING_APPT: 'PENDING_APPT',
  EXTEND_REFERRAL: 'EXTEND_REFERRAL',
  EXTEND_ADMINS: 'EXTEND_ADMINS',
  EXTEND_INSURANCE: 'EXTEND_INSURANCE',
  LABS: 'LABS',
  EXTEND_LAB_TESTS: 'EXTEND_LAB_TESTS',
  EXTEND_PA: 'EXTEND_PA',
  EXPIRED_REFERRAL: 'EXPIRED_REFERRAL',
  BENEFIT_INVESTIGATION: 'BENEFIT_INVESTIGATION',
  EXTEND_PA_VISITS: 'EXTEND_PA_VISITS',
  EXTEND_PA_UNITS: 'EXTEND_PA_UNITS',
  PATIENT_REACTIVATION_REVIEW: 'PATIENT_REACTIVATION_REVIEW',
  PRIOR_AUTHORIZATION: 'PRIOR_AUTHORIZATION',
  PRIOR_AUTH_INFO: 'PRIOR_AUTH_INFO',
  NETWORK_CHECKING: 'NETWORK_CHECKING',
  BI_NETWORK: 'BI_NETWORK',
  PRE_ORDER: 'PRE_ORDER',
  DISCONTINUATION_REVIEW: 'DISCONTINUATION_REVIEW',
  WELCOME_CALL: 'WELCOME_CALL',
  FINANCIAL_ASSIST_CALL: 'FINANCIAL_ASSIST_CALL',
  PENDING_PA_APPROVAL: 'PENDING_PA_APPROVAL',
  PA_DENIED: 'PA_DENIED',
  CLINICIAN_REVIEW: 'CLINICIAN_REVIEW',
  SCHEDULE: 'SCHEDULE',
  EXTEND_SCHEDULE: 'EXTEND_SCHEDULE'
};

export const followUpNameType = {
  CHART_NOTES_TEXT: 'Chart Notes Received',
  DOCUMENT_MISC_TEXT: 'Documents/Misc/Other',
  LABS_RECEIVED_TEXT: 'Labs Received',
  WELCOME_CALL_TEXT: 'Welcome Call'
};

export const supportedWorkItemTypes = [
  workItemTypes.PATIENT_REACTIVATION_REVIEW,
  workItemTypes.NEW_REFERRAL,
  workItemTypes.RE_VERIFICATION,
  workItemTypes.UPDATE_LAB_TESTS,
  workItemTypes.REFILL,
  workItemTypes.MISCELLANEOUS,
  workItemTypes.FOLLOW_UP,
  workItemTypes.PLAN_OON,
  workItemTypes.All_EXPIRED_REFERRALS,
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.CHART_NOTES,
  workItemTypes.PENDING_APPT,
  workItemTypes.EXTEND_REFERRAL,
  workItemTypes.EXTEND_ADMINS,
  workItemTypes.EXPIRED_REFERRAL,
  workItemTypes.EXTEND_LAB_TESTS,
  workItemTypes.LABS,
  workItemTypes.EXTEND_PA,
  workItemTypes.BENEFIT_INVESTIGATION,
  workItemTypes.EXTEND_PA_VISITS,
  workItemTypes.EXTEND_PA_UNITS,
  workItemTypes.PRIOR_AUTHORIZATION,
  workItemTypes.PRIOR_AUTH_INFO,
  workItemTypes.BI_NETWORK,
  workItemTypes.PRE_ORDER,
  workItemTypes.DISCONTINUATION_REVIEW,
  workItemTypes.WELCOME_CALL,
  workItemTypes.FINANCIAL_ASSIST_CALL,
  workItemTypes.PENDING_PA_APPROVAL,
  workItemTypes.PA_DENIED,
  workItemTypes.SCHEDULE,
  workItemTypes.EXTEND_SCHEDULE,
  workItemTypes.CLINICIAN_REVIEW
];

export const notSupportedWorkItemStatus = [
  taskStatus.PENDING,
  taskStatus.SNOOZED
];
export const snoozeWorkItemTypes = [
  workItemTypes.FOLLOW_UP,
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.WELCOME_CALL,
  workItemTypes.FINANCIAL_ASSIST_CALL
];
export const filterDropDownTypes = {
  Drug: 'Drug',
  Physician: 'Physician',
  Program: 'Program',
  Payer: 'Payer',
  State: 'State',
  ProductCategory: 'Drug Category',
  Sales: 'Sales',
  Priority: 'Priority',
  PatientStatus: 'Patient Status'
};
export const filterFieldNames = {
  Drug: 'drug',
  Physician: 'physician',
  Payer: 'payer',
  State: 'state',
  ProductName: 'productName',
  ProductOnSelector: 'productOnSelector',
  PrescriberName: 'prescriberName',
  Name: 'name'
};

export const taskStatusesMapped = {
  CREATED: 'OPEN',
  OPENED: 'OPEN',
  IN_PROGRESS: 'IN PROCESS',
  PENDING: 'PENDING',
  COMPLETED: 'CLOSED',
  ARCHIVED: 'CANCEL',
  CANCELED: 'CANCEL',
  OVERDUE: 'OVERDUE',
  REASSIGNED: 'REASSIGNED',
  SNOOZED: 'WAITING',
  RESCHEDULED: 'RESCHEDULED',
  ON_HOLD: 'ON HOLD',
  ALERTED: 'ALERTED'
};

export const taskAssignType = {
  TBD: 'TBD'
};

export const groupQueues = {
  INBOUND: 'INBOUND',
  INTAKE: 'INTAKE',
  BI: 'BI',
  PA: 'PA',
  SALES: 'SALES',
  SCHEDULE: 'SCHEDULE',
  CLINICIAN: 'CLINICIAN'
};

export const callWorkTypes = [
  workItemTypes.OUTBOUND_CALL,
  workItemTypes.WELCOME_CALL,
  workItemTypes.FINANCIAL_ASSIST_CALL,
  workItemTypes.PENDING_APPT,
  workItemTypes.SCHEDULE,
  workItemTypes.EXTEND_SCHEDULE
];

export const priorityNumbers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10'
];

export const patientStatusCodes = {
  ACTIVE: 'ACTIVE',
  EXPEDITED: 'EXPEDITED',
  INACTIVE: 'INACTIVE',
  ON_HOLD: 'ON_HOLD',
  SEASONAL: 'SEASONAL',
  STAT: 'STAT'
};

export const patientStatusList = [
  { value: patientStatusCodes.ACTIVE, text: 'Active' },
  { value: patientStatusCodes.EXPEDITED, text: 'Expedited' },
  { value: patientStatusCodes.ON_HOLD, text: 'On Hold' },
  { value: patientStatusCodes.SEASONAL, text: 'Seasonal' },
  { value: patientStatusCodes.STAT, text: 'Stat' }
];

export const inventorySources = {
  BUY_AND_BILL: 'BUY_AND_BILL',
  FREE_DRUG: 'FREE_DRUG'
};

export const SCHEDULE_EVENTS = {
  APPOINTMENT: 'APPOINTMENT',
  APPOINTMENT_OFFICE_VISIT: 'APPOINTMENT_OFFICE_VISIT',
  APPOINTMENT_TELEHEALTH: 'APPOINTMENT_TELEHEALTH',
  ALL_DAY_NOTES: 'ALL_DAY_NOTES',
  PLACEHOLDER: 'PLACEHOLDER'
};
