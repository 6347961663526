import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Storage } from 'aws-amplify';

// Kendo Components
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { Button } from '@progress/kendo-react-buttons';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { orderBy } from '@progress/kendo-data-query';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

// Components
import Preloader from '@/components/common-components/Preloader';
import { LogHeader } from './common';
import TreatmentHistoryDialog from './TreatmentHistoryDialog';
import TreatmentHistoryRevisions from './TreatmentHistoryRevisions';
import { ReferralIdsDisplay } from '@/components/common-components/ReferralIdsDisplay';

// GraphQL
import { connectToGraphqlAPI } from '@/provider';
import {
  getTreatmentHistoryByPatientNew,
  getEvent,
  getProductInfoByName
} from '@/graphql/queries';
import { addUpdatePatientDocs } from '@/graphql/mutations';

// Context
import {
  UserContext,
  PatientContext,
  LocationContext,
  NotifContext,
  TreatmentContext,
  UserRoleTypes
} from '@/context';

// Utils
import {
  formatDateToDefault,
  formatTimeToDefault,
  formatDateToAWSDateTime,
  formatDateToAWS,
  removeLeadingZeroes
} from '@/common/DateHelper';
import { productLabNames } from '@/components/Referral/ReferralMaps';
import { taskStatusesMapped } from '@/constants/enum';

// styles
import styles from './TreatmentHistory.module.scss';

const initialSort = [{ field: 'startTime', dir: 'dsc' }];
const initialDateRange = { start: null, end: null };

const TreatmentHistory = ({ selectedPatientInfo }) => {
  const [sort, setSort] = useState(initialSort);
  const [showTreatmentHistoryDetails, setShowTreatmentHistoryDetails] =
    useState(false);
  const [showNursingNotesDetails, setShowNursingNotesDetails] = useState(false);
  const [treatmentHistory, setTreatmentHistory] = useState([]);
  const [selectedTreatmentHistory, setSelectedTreatmentHistory] = useState([]);
  const [adminSeqNumber, setSequenceNumber] = useState(0);
  const [dialogOption, setDialogOption] = useState({});
  const [loading, setLoading] = useState(true);
  const [jCodeData, setJcodeData] = useState();
  const [dateRange, setDateRange] = useState(initialDateRange);

  const { user, canEdit } = useContext(UserContext);
  const { showSuccess } = useContext(NotifContext);
  const { updateTreatmentSnapshot } = useContext(TreatmentContext);
  const {
    completedTreatment,
    setCompletedTreatment,
    getPatientBillingHistory,
    recentBillings
  } = useContext(PatientContext);
  const { getDateByLocation } = useContext(LocationContext);

  const history = useHistory();

  const pdfContainer = React.useRef(null);

  const showTreatmentHistoryDialog = () => {
    setShowTreatmentHistoryDetails(true);
  };

  const hideTreatmentHistoryDialog = () => {
    setShowTreatmentHistoryDetails(false);
  };

  const hideNursingNotesDialog = () => {
    setShowNursingNotesDetails(false);
  };

  const handleSnapshotModal = (snapshot) => {
    showTreatmentHistoryDialog();
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    setSelectedTreatmentHistory(snapshot);
    updateTreatmentSnapshot(snapshot);
  };

  const customCell = ({ dataItem }) => {
    const npid = dataItem.infusionDetail.id;
    const snapshotFromBilling = recentBillings?.find(
      (billing) => billing.nursingProcessId === npid
    );

    let snapshotToUse;

    if (snapshotFromBilling && !!snapshotFromBilling.treatmentHistory) {
      // OPTML-1902: mix the updated provider since snapshot doesn't reflect this change
      const updatedProvider = {
        providerNPI: dataItem.providerNPI,
        providerFirstName: dataItem.providerFirstName,
        providerLastName: dataItem.providerLastName
      };
      snapshotFromBilling.treatmentHistory.updatedProvider = updatedProvider;
      snapshotToUse = snapshotFromBilling;
    } else snapshotToUse = { treatmentHistory: dataItem };

    return (
      <td>
        <Button
          look='flat'
          icon='preview'
          onClick={() => handleSnapshotModal(snapshotToUse)}
        >
          Show Details
        </Button>
        <TreatmentHistoryRevisions
          npid={npid}
          handleModal={handleSnapshotModal}
        />
      </td>
    );
  };

  const revisitBtn = (props) => {
    if (!canEdit(UserRoleTypes.Infusion)) return <td>&nbsp;</td>;

    const { dataItem } = props;
    if (
      dataItem.orderStatus === taskStatusesMapped.COMPLETED &&
      dataItem.orderName !== productLabNames.LAB_DRAW_NAME
    ) {
      return (
        <td>
          <Button
            look='flat'
            icon='reload-sm'
            onClick={() =>
              history.push(`/infusion/${dataItem.infusionDetail.id}`)
            }
          >
            Revisit Infusion
          </Button>
        </td>
      );
    }

    return <td>&nbsp;</td>;
  };

  const statusCell = (props) => {
    const { orderStatus, reasonForCancellation } = props.dataItem;
    const isCancelled = orderStatus === 'CANCELLED';

    const renderReasonHint = () => {
      return isCancelled ? (
        <>
          <br />
          <small>({reasonForCancellation})</small>
        </>
      ) : null;
    };

    return (
      <td>
        {orderStatus}
        {renderReasonHint()}
      </td>
    );
  };

  const getTreatmentHistoryByPatientCall = async (patientId, period) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getTreatmentHistoryByPatientNew,
        variables: {
          patientId,
          period
        }
      });
      if (!!data?.data?.getTreatmentHistoryByPatient?.treatments) {
        setTreatmentHistory(
          data.data.getTreatmentHistoryByPatient.treatments.map((treatment) => {
            const { patient } = data.data.getTreatmentHistoryByPatient;
            const locId = treatment.locationId;
            const treatmentDate = getDateByLocation(
              locId,
              treatment.infusionDetail.startTime
            );
            const timePdfFaxSent = getDateByLocation(
              locId,
              treatment.infusionDetail.departTime
            );
            return {
              ...treatment,
              patientId: selectedPatientInfo.patientId,
              dob: patient.dob,
              gender: patient.gender,
              patientFirstName: patient.patientFirstName,
              patientLastName: patient.patientLastName,
              startTime: treatment.arrivalTime,
              endTime: treatment.departTime,
              orderName: treatment.referralOrder.orderName,
              orderType: treatment.referralOrder.orderType,
              orderStatus: treatment.infusionDetail.status,
              reasonForCancellation:
                treatment.infusionDetail.reasonForCancellation,
              treatmentDate: formatDateToDefault(treatmentDate),
              timePdfFaxSent: formatTimeToDefault(timePdfFaxSent),
              eventId: treatment.infusionDetail.scheduleEventId
            };
          })
        );
      } else {
        setDialogOption({
          title: 'Treatment History',
          message: 'No Treatment History Records Found',
          showDialog: true
        });
      }
    } catch (err) {
      console.error(
        'TreatmentHistory::getTreatmentHistoryByPatientCall err: ',
        err
      );
      setDialogOption({
        title: 'Treatment History',
        message: 'Error: getTreatmentHistoryByPatientCall',
        showDialog: true
      });
    }

    setLoading(false);
  };

  // eslint-disable-next-line no-extend-native
  String.prototype.toProperCase = function () {
    return this.replace(/([^\W_]+[^\s_]*) */g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }).replace(/_/g, ' ');
  };

  useEffect(() => {
    const id = selectedPatientInfo.patientId;
    getTreatmentHistoryByPatientCall(id);
    getPatientBillingHistory(id);
  }, []);

  useEffect(() => {
    if (selectedTreatmentHistory.eventId) {
      callGetEvent(selectedTreatmentHistory.eventId);
    }
  }, [selectedTreatmentHistory]);

  // Generate Fax PDF for the Outbound Queue
  useEffect(() => {
    if (treatmentHistory.length > 0 && completedTreatment) {
      // try to find out the NPID for the completed treatment
      const ct = treatmentHistory.find(
        (treatment) => treatment.infusionDetail?.id === completedTreatment
      );

      if (ct) {
        // show TH in the modal
        handleSnapshotModal({ treatmentHistory: ct });

        // @NOTE: need this to prevent generating PDF for the whole document instead of TH body
        setTimeout(() => {
          // PDF it
          const startDate = removeLeadingZeroes(
            formatDateToAWS(ct.infusionDetail?.startTime)
          );
          const fileName = `outboundFax/${startDate}/treatmentNotes_${ct.infusionDetail.id}.pdf`;
          uploadToS3(fileName);
          setCompletedTreatment(null); // reset patient context value
        }, 500);
      }
    }
  }, [completedTreatment, treatmentHistory]);

  const callGetEvent = async (eventId) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getEvent,
        variables: {
          id: eventId
        }
      });
      if (data?.data?.getEvent) {
        setSequenceNumber(data.data.getEvent.adminSequenceNumber);
      }
    } catch (err) {
      console.error('TreatmentHistory::callGetEvent err: ', err);
    }
  };

  const callAddUpdatePatientDocs = async (documentPath) => {
    const req = {
      patientId: selectedPatientInfo.patientId,
      agentId: user.username,
      patientDocuments: {
        documentType: 'TreatmentNotes',
        documentPath,
        date: formatDateToAWSDateTime()
      }
    };
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdatePatientDocs,
        variables: {
          input: req
        }
      });

      if (!!data?.data?.addUpdatePatientDocs) {
        showSuccess('Treatment History document uploaded sucessfully');
      }
    } catch (err) {
      console.error('Treatment History::callAddUpdatePatientDocs err: ', err);
    }
  };

  const base64ToArrayBuffer = (data) => {
    const bString = window.atob(data);
    const bLength = bString.length;
    const bytes = new Uint8Array(bLength);
    for (let i = 0; i < bLength; i++) {
      const ascii = bString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const uploadToS3 = (fileName) => {
    const element = pdfContainer.current || document.body;
    drawDOM(element, {
      paperSize: 'A4',
      margin: 5,
      landscape: true
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        const blob = dataUri.split(';base64,')[1];
        const bufferArray = base64ToArrayBuffer(blob);
        const blobStore = new Blob([bufferArray], { type: 'application/pdf' });

        const file = new File([blobStore], 'TreatmentHistory', {
          type: 'application/pdf'
        });

        Storage.put(fileName, file, {
          level: 'public',
          contentType: 'application/pdf'
        })
          .then((res) => {
            callAddUpdatePatientDocs(res.key);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  const productName =
    selectedTreatmentHistory?.referralOrder?.administrations[0]?.drugName;

  useEffect(() => {
    if (productName) {
      getProductInfoByNameCall(productName);
    }
  }, [productName]);

  const getProductInfoByNameCall = async () => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getProductInfoByName,
        variables: { productName }
      });
      setJcodeData(data?.data?.getProductInfoByName?.items[0]?.jCode);
    } catch (err) {
      console.error('TreatmentHistory::getProductInfoByNameCall err: ', err);
      setJcodeData(''); // todo: show error dialog
    }
  };

  const onDateRangeChange = ({ value }) => {
    const { start, end } = value;
    if (start && end) {
      const period = {
        startDate: start.toISOString(),
        endDate: end.toISOString()
      };
      getTreatmentHistoryByPatientCall(selectedPatientInfo.patientId, period);
    }

    setDateRange(value);
  };
  const orderNameCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem?.orderName}
        <ReferralIdsDisplay
          referralId={dataItem?.referralId}
          referralUUID={dataItem?.referralUUID}
          grid
        />
      </td>
    );
  };

  return (
    <div className='container-fluid'>
      <DateRangePicker
        className={styles.dateRangePicker}
        value={dateRange}
        onChange={onDateRangeChange}
        format='MM/dd/yy'
      />
      <div className='row mt-3'>
        <div className='col-md-12 ml-1 mr-1'>
          <Preloader show={loading}>
            <Grid
              className='a-grid'
              selectedField='selected'
              sort={sort}
              data={orderBy(treatmentHistory, sort)}
              style={{ height: 600 }}
              sortable
              onSortChange={(e) => {
                setSort(e.sort);
              }}
            >
              <Column field='treatmentDate' title='Treatment Date' sortable />
              <Column field='locationName' title='Location' sortable />
              <Column title='Order Name' sortable cell={orderNameCell} />
              <Column field='orderType' title='Order Type' sortable />
              <Column
                field='orderStatus'
                title='Order Status'
                cell={statusCell}
                sortable
              />
              <Column field='action' title='' cell={customCell} />
              <Column field='revisit' title='' cell={revisitBtn} />
            </Grid>
          </Preloader>
        </div>
      </div>
      {showTreatmentHistoryDetails && (
        <TreatmentHistoryDialog
          canEdit={canEdit}
          hideTreatmentHistoryDialog={hideTreatmentHistoryDialog}
          dialogOption={dialogOption}
          selectedPatientInfo={selectedPatientInfo}
          pdfContainer={pdfContainer}
          jCodeData={jCodeData}
          adminSeqNumber={adminSeqNumber}
          uploadToS3={uploadToS3}
        />
      )}
      {showNursingNotesDetails && (
        <Dialog
          title='Nursing Narrative Notes'
          style={{ backgroundColor: '#fff', minHeight: '300px' }}
          initialHeight={500}
          initialTop={50}
          initialLeft={100}
          width={1000}
          onClose={hideNursingNotesDialog}
        >
          <LogHeader />
          <div className='mt-3 ml-0 notesWrapper'>
            <b>Narrative Notes:</b>
          </div>
          <div>{selectedTreatmentHistory?.referralOrder?.notes}</div>
        </Dialog>
      )}
    </div>
  );
};

export { TreatmentHistory };
