import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import * as moment from 'moment';

// kendo
import { Button, DropDownButton } from '@progress/kendo-react-buttons';
import { Form, Field } from '@progress/kendo-react-form';
import { Input, Checkbox, TextArea } from '@progress/kendo-react-inputs';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import {
  SchedulerViewItem,
  SchedulerItem,
  SchedulerItemContent
} from '@progress/kendo-react-scheduler';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Popup } from '@progress/kendo-react-popup';
import '@progress/kendo-date-math/tz/all';
import { DropDownList } from '@progress/kendo-react-dropdowns';

// components
import CurrentPatient from '@/components/common-components/CurrentPatient';
import AICLocationDropdown from '@/components/common-components/AICLocationDropdown';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import PatientDocument from '@/components/Patient/PatientDocument';
import { getUTCTimeString } from '@/components/Scheduling/getUTCTimeString';
import { getScheduleEvents } from '@/components/Scheduling/getScheduleEvents';
import { getEndTimeWithSameInterval } from '@/components/Scheduling/getEndTimeWithSameInterval';
import {
  getMaxTime,
  getMinTime
} from '@/components/Scheduling/getSchedulerTimeLimits';
import { getReferralText } from '@/components/Scheduling/getReferralText';
import { DisplayScheduler } from '@/components/Scheduling/DisplayScheduler';
import { getAdminSequenceNumber } from '@/components/Scheduling/schedulingQueries';
import {
  getTimezoneSpecificDateString,
  getAppointmentMinTime,
  getAppointmentMaxTime
} from '@/components/Scheduling/getTimezoneSpecificDate';
import LabDrawModal from './LabDrawModal/LabDrawModal';
import StartInfusionButton from './StartInfusionButton';
import FormButton from '@/components/common-components/Form/FormButton';
import ScheduleBanner from './W2W/ScheduleBanner';
import NewPatientBadge from '@/components/common-components/NewPatientBadge';
import TelehealthVisitModal from './TelehealthVisitModal';
import OfficeVisitModal from './OfficeVisitModal';
import OfficeVisitBadge from '@/components/common-components/OfficeVisitBadge';
import TelehealthVisitBadge from '@/components/common-components/TelehealthVisitBadge';
import CalendarEventPopup from './CalendarEventPopup';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientReferralOrders, getProviderAIC } from '@/graphql/queries';
import {
  createScheduleEvent,
  updateScheduleEvent,
  cancelScheduleEvent,
  cancelPendingEvent,
  addUpdateNotes,
  createPendingEvent,
  updatePendingEvent
} from '@/graphql/mutations';
import {
  onCancelPendingEvent,
  onCancelScheduleEvent,
  onCreatePendingEvent,
  onCreateScheduleEvent,
  onStepCheckInEvent,
  onUpdatePendingEvent,
  onUpdateScheduleEvent,
  onCloseLabDraw
} from '@/graphql/subscriptions';
import {
  stepCheckInCall,
  getNursingProcessCall
} from '@/api/infusion/api.infusion';

// context
import {
  UserContext,
  PatientContext,
  NotifContext,
  LocationContext,
  LogContext,
  ProductContext,
  StatusContext,
  ProviderContext,
  CalendarContext
} from '@/context';

// utils
import {
  DatePickerField,
  validateInput,
  TimePickerField,
  DropDownListField
} from '@/common/Validation';
import {
  calculateToFifteen,
  formatDateTimeToDefault,
  formatDateToAWSDateTime,
  formatDateToDefault,
  isDateFromPast,
  isToday
} from '@/common/DateHelper';
import { getDosageUOM } from '@/common/DoseHelper';
import { getDosageFromEvent } from '@/common/viivHelper';
import {
  getIsLabDraw,
  getIsOfficeVisit,
  getIsTelehealthVisit,
  getModalHeight,
  getProviderTitle
} from './CalendarHelper';
import { Constants } from '@/constants';
import {
  endTimeNotFifteenPastStartTimeValidator,
  getCancelReasons,
  steps
} from './Calendar.utils';
import { eventStatus, SCHEDULE_EVENTS } from '@/constants/enum';

// styles
import styles from './Calendar.module.scss';

// styled components
import { EventBadgeContainer } from './Calendar.styled';

const SchedulingCalendarMain = (props) => {
  const [override, setOverride] = useState('');
  const [biProvider, setBiProvider] = useState('');
  const [, setSchedulerLocationId] = useState(null);
  const [showAllDayNoteCancel, setAllDayNoteCancel] = useState(false);
  const [, setShowSetNewReferral] = useState(false);
  const [overrideProvider, setOverrideProvider] = useState();
  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [currentReferralOrder, setCurrentReferralOrder] = useState(null);
  const [showNewAppointmentDialog, setShowNewAppointmentDialog] =
    useState(false);
  const [showFinshLabDraw, setShowFinshLabDraw] = useState(false);
  const [showFinishTelevisit, setShowFinishTelevisit] = useState(false);
  const [showPendingAppointmentDialog, setShowPendingAppointmentDialog] =
    useState(false);
  const [showPatientSeenDialog, setShowPatientSeenDialog] = useState(false);
  const [verifiedDOB, setVerifiedDOB] = useState(false);
  const [negativeStatusConfirmed, setNegativeStatusConfirmed] = useState(false);
  const [showPatientRescheduleDialog, setShowPatientRescheduleDialog] =
    useState(false);
  const [showPatientNotSeenDialog, setShowPatientNotSeenDialog] =
    useState(false);
  const [showNewNoteDialog, setShowNewNoteDialog] = useState(false);
  const [showNoteDialog, setShowNoteDialog] = useState(false);
  const [startInfusion, setStartInfusion] = useState(false);
  const [showPatientDocsDialog, setShowPatientDocsDialog] = useState(false);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState('');
  const [currentEventEditing, setCurrentEventEditing] = useState({});
  const [selectedEventDate, setSelectedEventDate] = useState(null);
  //   const [adminStageForEvent, setAdminStageForEvent] = useState(null);
  const [listProductsDataFiltered, setListProductsDataFiltered] = useState([]);
  const [statusIsPending, setStatusIsPending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [labDrawStartingInfo, setLabDrawStartingInfo] = useState();
  const [telehealthStartingInfo, setTelehealthStartingInfo] = useState();

  const { showError, showWarning } = useContext(NotifContext);
  const { appointmentListRefresh } = useContext(StatusContext);
  const { products } = useContext(ProductContext);
  const { providers } = useContext(ProviderContext);
  const { logApiException } = useContext(LogContext);
  const { setSelectedPatientInfo, selectedPatientInfo } =
    useContext(PatientContext);
  const { agent } = useContext(UserContext);
  const {
    showNewTelehealthModal,
    setShowNewTelehealthModal,
    showOfficeVisitModal,
    setShowOfficeVisitModal
  } = useContext(CalendarContext);

  const isCurrentEventLabDraw = getIsLabDraw(currentEventEditing?.orderName);
  const isPending = currentEventEditing?.status === 'PENDING';
  const isTelehealth =
    currentEventEditing?.eventType === SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH;
  const isOfficeVisit =
    currentEventEditing?.eventType === SCHEDULE_EVENTS.APPOINTMENT_OFFICE_VISIT;

  useEffect(() => {
    if (products) {
      const filtered = products
        .map((item) => ({
          productId: item.productId,
          productOnSelector: item.productName,
          productName: item.productName,
          possibleUOMs: item.possibleUOMs
        }))
        .sort((a, b) => (a.productName > b.productName ? 1 : -1));
      const unique = Array.from(
        new Set(filtered.map((a) => a.productName))
      ).map((productName) => {
        return filtered.find((a) => a.productName === productName);
      });

      setListProductsDataFiltered(unique);
    }
  }, [products]);

  //////////// DO NOT DELETE-Ilia/Travis 12/14/23/////////////
  //This is part of the viiv logic that may come back to the calendar

  // trigger for fetching target injection dates
  //   useEffect(async () => {
  //     // for now we restrict this to viiv only
  //     if (currentReferralOrder && selectedEventDate && isVIIV) {
  //       if (!isDateFromPast(selectedEventDate)) {
  //         const eventAdminStage = await getAdminSequenceNumber(
  //           selectedPatientInfo.patientId,
  //           currentReferralOrder.referralId,
  //           formatDateToAWSDateTime(selectedEventDate)
  //         );
  //         if (eventAdminStage) {
  //           setAdminStageForEvent(eventAdminStage);
  //         }
  //       }
  //     }
  //   }, [currentReferralOrder, selectedEventDate]);

  const {
    getChairsByLocation,
    aicActiveLocationsOnly,
    currentLocation,
    setCurrentLocation,
    getLocation
  } = useContext(LocationContext);

  const history = useHistory();

  const { pathname } = history.location;

  const path = '/patient-portal';
  const pathsNotEqual = pathname !== path;

  const {
    schedulerLocationId,
    firstView,
    locationEvent,
    handleDataChange,
    handleDateChange,
    schedulingCalendarToCalendar,
    calendarLayout,
    childData,
    incomingDate,
    date,
    isEditable
  } = props;

  const handleDetailsClick = () => {
    history.push('/patient-portal');
  };

  /*
   * This property helps to reset the display scheduler when the location changes
   * Display scheduler uses schedulerLocationId instead of selectedLocationId
   * When the location changes, we first set schedulerLocationId to null
   * And then we set it to the selectedLocationId again after event data is retrieved
   * This helps with the reset of DisplayScheduler
   * This is in place to avoid the bug in the scheduler
   * The bug is that the scheduler resets to a previous date, on certain timezone value changes
   * While our event data still stays to the currently selected date
   */

  const locales = [
    { language: 'en-US', locale: 'en' },
    { language: 'es-ES', locale: 'es' }
  ];

  const [view, setView] = useState(firstView);
  const [locale] = useState(locales[0]);
  const [orientation] = useState('horizontal');
  const [locationEvents, setLocationEvents] = useState(locationEvent);
  const [defaultEvents, setDefaultEvents] = useState(false);
  const [allDayNotes] = useState();

  const [newAppointmentNotes, setNewAppointmentNotes] = useState('');
  const [parentMetaData, setParentMetaData] = useState('');
  const [selectedDataItem, setSelectedDataItem] = useState(null);
  const [reload, setReload] = useState('');
  //   const [providerName, setProviderName] = useState('');
  const [planName, setPlanName] = useState('');
  const [dateSwitch, setDateSwitch] = useState(true);
  const [chairsData, setChairsData] = useState(null);

  const isApretude = selectedDataItem?.adminStage?.drugName === 'Apretude';
  //   const isVIIV = isViiVProduct(currentReferralOrder?.drugId);

  // MAIN INITIATOR
  useEffect(() => {
    onUpdateScheduleEventCall();
    onCreateScheduleEventCall();
    onCancelScheduleEventCall();
    onUpdatePendingEventCall();
    onCreatePendingEventCall();
    onCancelPendingEventCall();
    onStepCheckInEventCall();
    onCloseLabDrawCall();
  }, []);

  useEffect(() => {
    schedulingCalendarToCalendar(listReferralOrdersData);
  }, [listReferralOrdersData]);

  useEffect(() => {
    if (selectedPatientInfo?.patientId) {
      getPatientReferralOrdersCall(selectedPatientInfo);
    }
  }, [selectedPatientInfo?.patientId]);
  useEffect(() => {
    if (selectedDataItem?.patientId) {
      getPatientReferralOrdersCall(selectedDataItem);
    }
  }, [selectedDataItem]);

  useEffect(() => {
    if (childData) setShowNewAppointmentDialog(true);
  }, [childData]);

  const getPatientReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject?.patientId } // patientId
      });
      if (data?.data?.getPatientBucket?.referral?.drugReferrals?.length) {
        setListReferralOrdersData(
          data.data.getPatientBucket.referral.drugReferrals.map((item) => ({
            ...item,
            text: item.referralOrder.orderName,
            value: item.referralId
          }))
        );

        if (requestObject.referralId) {
          const checking =
            data?.data?.getPatientBucket?.benefitInvestigation?.benefitCheckings.find(
              (item) => item.referralId === requestObject.referralId
            );
          const selectedChecking = checking?.checkings.find(
            (item) => item.insuranceKey === 'Primary'
          );
          const primaryPlanName =
            data?.data?.getPatientBucket?.patientProfile?.insuranceInfo?.primary
              ?.planName;
          setPlanName(primaryPlanName);
          const AICLocation = getLocation(selectedChecking?.selectedLocationId);
          let overrideChoice = `LOCATION (${AICLocation?.selectorText})`;
          try {
            const providerData = await connectToGraphqlAPI({
              graphqlQuery: getProviderAIC,
              variables: { providerNPI: selectedChecking?.selectedProviderId }
            });
            if (
              providerData?.data?.getProviderAIC &&
              selectedChecking?.selectedBillingNPI ===
                selectedChecking?.selectedProviderId
            ) {
              setBiProvider(selectedChecking?.selectedProviderId);
              overrideChoice = `PROVIDER: ${providerData.data.getProviderAIC.firstName} ${providerData.data.getProviderAIC.lastName} (NPI: ${providerData.data.getProviderAIC.providerNPI})`;
            }
            // setProviderName(
            //   `${providerData.data.getProviderAIC.firstName} ${providerData.data.getProviderAIC.lastName} (NPI: ${selectedChecking?.selectedProviderId})`
            // );
          } catch (err) {
            logApiException(err, {
              view: 'SchedulingCalendarMain',
              endpoint: 'getProviderAIC',
              patientId: requestObject?.patientId
            });
          } finally {
            setOverride(overrideChoice);
          }
        }
      }
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'getPatientReferralOrders',
        patientId: requestObject?.patientId
      });
      showError('Error: getPatientReferralOrders');
    }
  };

  const handleViewChange = useCallback(
    (event) => {
      setView(event.value);
    },
    [setView]
  );

  useEffect(() => {
    if (props?.locationEvent.length !== 0 && defaultEvents === true) {
      setLocationEvents(props?.locationEvent);
      setDefaultEvents(false);
    }
  }, [props?.locationEvent]);

  useEffect(() => {
    if (defaultEvents === false) {
      if (
        currentLocation?.id &&
        date &&
        (dateSwitch === true || !incomingDate)
      ) {
        getScheduleEventsByLocationIdCall(
          currentLocation?.id,
          formatDateToAWSDateTime(date),
          view
        );
        if (incomingDate) setDateSwitch(false);
      }
    }
  }, [currentLocation, date, view, reload, appointmentListRefresh]);

  useEffect(() => {
    if (currentLocation?.id) {
      setChairsData(
        currentLocation?.chairs
          ? currentLocation.chairs
          : getChairsByLocation(currentLocation.id)
      );
    }
  }, [currentLocation]);

  const getScheduleEventsByLocationIdCall = async (
    locationIdVal,
    dateVal,
    viewVal
  ) => {
    const result = await getScheduleEvents(locationIdVal, dateVal, viewVal);
    if (schedulerLocationId !== locationIdVal) {
      // show the DisplayScheduler again
      setSchedulerLocationId(locationIdVal);
    }
    setLocationEvents(result);
  };

  const handleRemoveClick = () => {
    // console.log("handleRemoveClick");
  };

  const togglePatientDocsDialog = () => {
    setShowPatientDocsDialog(!showPatientDocsDialog);
  };

  const hyperLinkCell = (linkProps) => {
    return (
      <td>
        {linkProps.dataItem.documentPath && (
          <span
            className='blue-link'
            onClick={() => onDocumentRowHandle(linkProps.dataItem)}
          >
            {linkProps.dataItem.documentPath}
          </span>
        )}
      </td>
    );
  };

  const docDateCell = ({ dataItem }) => {
    return <td>{formatDateToAWSDateTime(dataItem?.date)}</td>;
  };

  const onDocumentRowHandle = async (data) => {
    const conf = { download: false };
    const s3ImageURL = await Storage.get(data.documentPath, conf);
    setSelectedDocumentUrl(s3ImageURL);
  };

  const handleNewAppointmentClick = () => {
    setShowNewAppointmentDialog(!showNewAppointmentDialog);
  };
  const handlePendingAppointmentClick = () => {
    setShowPendingAppointmentDialog(!showPendingAppointmentDialog);
  };

  const handleNewAppointmentSubmit = async (dataItem) => {
    setLoading(true);
    const newStartTime = calculateToFifteen(dataItem.startTime);
    const newEndTime = calculateToFifteen(dataItem.endTime);

    if (!dataItem.chairId) {
      showError('Please make a chair selection for the new appointment');
      setLoading(false);
      return;
    }

    if (!agent?.agentId?.length) {
      showError('Cannot create a new appointment. Agent Id is missing');
      setLoading(false);
      return;
    }

    const referralId = dataItem.referralId?.value
      ? dataItem.referralId.value
      : '57894-030-01 2021-04-15';
    const adminSequence = await getAdminSequenceNumber(
      selectedPatientInfo.patientId,
      referralId,
      formatDateToAWSDateTime(dataItem.startDate)
    );

    if (adminSequence.statusCode === '400') {
      showWarning(adminSequence.message);
      setLoading(false);
      return;
    }

    const requestObject = {
      agentId: agent.agentId,
      chairId: dataItem.chairId,
      createdBy: agent.agentId,
      endTime: getUTCTimeString(
        dataItem.startDate,
        newEndTime,
        currentLocation.timeZone
      ),
      endTimeZone: 'UTC',
      locationId: dataItem.locationId?.id,
      notes: dataItem.newAppointmentNotes,
      patientId: selectedPatientInfo.patientId,
      providerId: dataItem.providerId?.value || '1',
      referralId,
      resources: '',
      startTime: getUTCTimeString(
        dataItem.startDate,
        newStartTime,
        currentLocation.timeZone
      ),
      startTimeZone: 'UTC',
      status: 'SCHEDULED',
      title: dataItem.appointmentTitle
        ? dataItem.appointmentTitle
        : 'Infusion Appointment',
      updatedBy: agent.agentId,
      adminSequenceNumber: adminSequence?.adminStage?.adminSequenceNumber,
      eventType: SCHEDULE_EVENTS.APPOINTMENT
    };

    const noteObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: agent.agentId,
      notes: [
        {
          date: moment(new Date()),
          note: dataItem.newAppointmentNotes,
          type: 'APPOINTMENT'
        }
      ]
    };

    createScheduleEventCall(requestObject);
    setShowNewAppointmentDialog(false);
    /* setStatusUpdate(!statusUpdate); */
    /* setAppointmentListRefresh(!appointmentListRefresh); */
    if (!dataItem.newAppointmentNotes) {
      //
    } else {
      addUpdateNotesCall(noteObject);
    }
  };

  const handlePendingAppointmentSubmit = async (dataItem) => {
    setLoading(true);
    const newStartTime = calculateToFifteen(dataItem.startTime);
    const newEndTime = calculateToFifteen(dataItem.endTime);

    if (!dataItem.chairId) {
      showError('Please make a chair selection for the new appointment');
      setLoading(false);
      return;
    }

    if (!agent?.agentId?.length) {
      showError('Cannot create a new appointment. Agent Id is missing');
      setLoading(false);
      return;
    }

    const requestObject = {
      title: dataItem.appointmentTitle
        ? dataItem.appointmentTitle
        : 'Infusion Appointment',
      startTime: getUTCTimeString(
        dataItem.startDate,
        newStartTime,
        currentLocation.timeZone
      ),
      endTime: getUTCTimeString(
        dataItem.startDate,
        newEndTime,
        currentLocation.timeZone
      ),
      locationId: dataItem.locationId?.id,
      chairId: dataItem.chairId,
      patientId: selectedPatientInfo.patientId,
      drugName: dataItem.product.productName,
      resources: '',
      notes: dataItem.newAppointmentNotes,
      startTimeZone: 'UTC',
      endTimeZone: 'UTC',
      idOfWorkItemToClose: ''
    };

    const noteObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: agent.agentId,
      // notes: [NoteInput!]!
      notes: [
        {
          // date: AWSDateTime
          date: moment(new Date()), // .format("YYYY-MM-DDTHH:mm:ss.SSS"),
          // note: String!
          note: dataItem.newAppointmentNotes, // appointmentNotes
          // type: NoteType
          type: 'APPOINTMENT'
        }
      ]
    };

    createPendingEventCall(requestObject);
    setShowPendingAppointmentDialog(false);
    /* setStatusUpdate(!statusUpdate); */
    /* setAppointmentListRefresh(!appointmentListRefresh); */
    if (!dataItem.newAppointmentNotes) {
      //
    } else {
      addUpdateNotesCall(noteObject);
    }
  };

  const endTimeGreaterThanStartTimeValidator = (values) => {
    if (!values?.startTime || !values?.endTime) {
      return;
    }
    const diffInMinutes = Math.round(
      (values.endTime.getTime() - values.startTime.getTime()) / 60000
    );
    if (diffInMinutes < 1) {
      return {
        endTime: 'End Time should be greater than the Start Time.'
      };
    }
  };
  /// /Start New appointment validation
  const newAppointmentForm = {
    referralId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          referralId: { ...newAppointmentForm.referralId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    locationId: {
      value: currentLocation?.id || '',
      inputValidator: (value) => {
        return validateInput({
          locationId: { ...newAppointmentForm.locationId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    startDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startDate: { ...newAppointmentForm.startDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    startTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startTime: { ...newAppointmentForm.startTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentStartTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    endTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          endTime: { ...newAppointmentForm.endTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentEndTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    chairId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          chairId: { ...newAppointmentForm.chairId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };
  /// /Start Pending appointment validation
  const pendingAppointmentForm = {
    product: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          product: { ...pendingAppointmentForm.product, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    locationId: {
      value: currentLocation?.id || '',
      inputValidator: (value) => {
        return validateInput({
          locationId: { ...pendingAppointmentForm.locationId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    startDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startDate: { ...pendingAppointmentForm.startDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    startTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startTime: { ...pendingAppointmentForm.startTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentStartTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    endTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          endTime: { ...pendingAppointmentForm.endTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentEndTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    chairId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          chairId: { ...pendingAppointmentForm.chairId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  /// ///Start check-in modal and cancel modal validation
  const checkAppointmentForm = {
    providerId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          providerId: { ...checkAppointmentForm.providerId, value }
        });
      },
      validations: override?.startsWith('LOCATION')
        ? [
            {
              type: 'required',
              message: 'Provider is required when location based'
            }
          ]
        : []
    },
    checkInStartDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          checkInStartDate: { ...checkAppointmentForm.checkInStartDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    checkInStartTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          checkInStartTime: { ...checkAppointmentForm.checkInStartTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message: Constants.ErrorMessage.checkInStartTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    startDate: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startDate: { ...checkAppointmentForm.startDate, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'datePast',
          message: Constants.ErrorMessage.datePast
        }
      ]
    },
    startTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          startTime: { ...checkAppointmentForm.startTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentStartTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    endTime: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          endTime: { ...checkAppointmentForm.endTime, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        },
        {
          type: 'dateRange',
          message:
            Constants.ErrorMessage.appointmentEndTime_WITHIN_OFFICE_HOURS,
          minDate: getMinTime(),
          maxDate: getMaxTime()
        }
      ]
    },
    reasonPatientNotSeen: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          reasonPatientNotSeen: {
            ...checkAppointmentForm.reasonPatientNotSeen,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    chairId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          chairId: { ...checkAppointmentForm.chairId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    },
    locationId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          locationId: { ...checkAppointmentForm.locationId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  // todo - why are we saving notes like an appointment field?
  const handleNewAllDayNote = (dataItem) => {
    setLoading(true);
    if (dataItem.noteTitle) {
      const thisStartDateTime = `${moment(dataItem.noteDate).format(
        'YYYY-MM-DD'
      )}T11:00:00.000Z`;
      const thisEndDateTime = `${moment(dataItem.noteDate).format(
        'YYYY-MM-DD'
      )}T21:00:00.000Z`;

      const requestObject = {
        agentId: agent.agentId,
        chairId: 'c000',
        createdBy: agent.agentId,
        endTime: thisEndDateTime,
        endTimeZone: 'UTC',
        locationId: currentLocation?.id,
        notes: '',
        patientId: selectedPatientInfo.patientId,
        resources: '',
        startTime: thisStartDateTime,
        startTimeZone: 'UTC',
        status: 'SCHEDULED',
        title: dataItem.noteTitle,
        updatedBy: agent.agentId,
        adminSequenceNumber: 1,
        eventType: SCHEDULE_EVENTS.ALL_DAY_NOTES
      };

      createScheduleEventCall(requestObject).then(() =>
        setShowNewNoteDialog(false)
      );
    }
  };

  const createScheduleEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createScheduleEvent,
        variables: { input: requestObject }
      });
      if (data.data?.createScheduleEvent?.statusCode !== '400') {
        setShowNewAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        setLoading(false);
        getScheduleEventsByLocationIdCall(
          requestObject?.locationId,
          date,
          view
        );
      } else if (data?.data?.createScheduleEvent?.statusCode === '400') {
        showError(data?.data?.createScheduleEvent?.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'createScheduleEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: createScheduleEvent');
    }
  };
  const createPendingEventCall = async (requestObject) => {
    const agentId = agent?.agentId;
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: createPendingEvent,
        variables: { agentId, input: requestObject }
      });
      if (data.data?.createPendingEvent?.statusCode !== '400') {
        setShowNewAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        setLoading(false);
        getScheduleEventsByLocationIdCall(
          requestObject?.locationId,
          date,
          view
        );
      } else if (data?.data?.createPendingEvent?.statusCode === '400') {
        showError(data?.data?.createPendingEvent?.message);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'createPendingEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: createPendingEvent');
    }
  };

  const addUpdateNotesCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: addUpdateNotes,
        variables: { input: requestObject }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'addUpdateNotes',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: addUpdateNotes');
      setLoading(false);
    }
  };

  // Calendar Subscriptions
  const onUpdateScheduleEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdateScheduleEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onUpdateScheduleEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onUpdateScheduleEvent');
    }
  };
  const onCreateScheduleEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onCreateScheduleEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onCreateScheduleEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onCreateScheduleEvent');
    }
  };
  const onCancelScheduleEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onCancelScheduleEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onCancelScheduleEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onCancelScheduleEvent');
    }
  };
  const onUpdatePendingEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdatePendingEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onUpdatePendingEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onUpdatePendingEvent');
      setLoading(false);
    }
  };
  const onCreatePendingEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onCreatePendingEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onCreatePendingEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onCreatePendingEvent');
      setLoading(false);
    }
  };
  const onCancelPendingEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onCancelPendingEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onCancelPendingEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onCancelPendingEvent');
      setLoading(false);
    }
  };
  const onStepCheckInEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onStepCheckInEvent
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onStepCheckInEvent',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onStepCheckInEvent');
    }
  };
  const onCloseLabDrawCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onCloseLabDraw
      }).subscribe({
        next: (result) => {
          setReload(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'onCloseLabDraw',
        patientId: selectedPatientInfo.patientId
      });
      showError('Error: onCloseLabDraw');
    }
  };
  /// //////////////////////////////

  const handlePatientSeenClose = () => {
    setCurrentEventEditing({});
    setShowPatientSeenDialog(!showPatientSeenDialog);
    setOverrideProvider();
  };

  const handlePatientRescheduleClose = () => {
    setCurrentEventEditing({});
    setShowPatientRescheduleDialog(!showPatientRescheduleDialog);
  };

  const handlePatientNotSeenClose = () => {
    setCurrentEventEditing({});
    setShowPatientNotSeenDialog(false);
    setAllDayNoteCancel(false);
  };

  const handleNewAppointmentClickNew = () => {
    setShowNewAppointmentDialog(!showNewAppointmentDialog);
  };
  const handleShowFinshLabDraw = () => {
    setShowPatientSeenDialog(false);
    setShowFinshLabDraw(!showFinshLabDraw);
    setReload(!reload);
  };

  const handleShowFinishTelevisit = () => {
    setShowPatientSeenDialog(false);
    setShowFinishTelevisit(!showFinishTelevisit);
    setReload(!reload);
  };

  const handlePatientSeenSubmit = (dataItem) => {
    setLoading(true);
    if (!verifiedDOB) {
      showWarning('Please select Verified DOB.');
      setLoading(false);
      return false;
    }

    if (isApretude && !negativeStatusConfirmed) {
      showWarning('Please confirm negative infection status');
      setLoading(false);
      return false;
    }

    const isTelehealth =
      currentEventEditing.eventType === SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH;
    const isOfficeVisit =
      currentEventEditing.eventType ===
      SCHEDULE_EVENTS.APPOINTMENT_OFFICE_VISIT;

    if (isTelehealth || isOfficeVisit) {
      if (!dataItem.providerId?.value) {
        showWarning('Please select a provider');
        setLoading(false);
        return false;
      }
    }

    const endTime = getEndTimeWithSameInterval(
      new Date(currentEventEditing.startTime),
      new Date(currentEventEditing.endTime),
      dataItem.checkInStartTime
    );

    const requestObject = {
      id: currentEventEditing.id,
      agentId: agent.agentId,
      chairId: currentEventEditing.chairId
        ? currentEventEditing.chairId
        : 'c001',
      createdBy: agent.agentId,
      endTime: getUTCTimeString(
        currentEventEditing.startTime,
        endTime,
        currentLocation.timeZone
      ),
      endTimeZone: 'UTC',
      locationId: currentEventEditing.locationId,
      notes: dataItem.appointmentNotes,
      patientId: currentEventEditing.patientId
        ? currentEventEditing.patientId
        : '',
      providerId: dataItem.providerId?.value
        ? dataItem.providerId.value
        : biProvider || '1',
      referralId: currentEventEditing.referralId
        ? currentEventEditing.referralId
        : null,
      resources: '',
      startTime: getUTCTimeString(
        currentEventEditing.startTime,
        dataItem.checkInStartTime,
        currentLocation.timeZone
      ),
      startTimeZone: 'UTC',
      status: 'CHECKED_IN',
      title: currentEventEditing.appointmentTitle
        ? currentEventEditing.appointmentTitle
        : 'Appointment',
      updatedBy: agent.agentId,
      ...(!isTelehealth &&
        !isOfficeVisit && {
          adminSequenceNumber: currentEventEditing?.adminSequenceNumber
        }),
      eventType: currentEventEditing.eventType
    };

    updateScheduleEventCall(requestObject);
  };

  const handleStartLabDraw = async (event) => {
    const { id, agentId, chairId, locationId, patientId, referralId } =
      currentEventEditing;
    const input = {
      eventId: id,
      agentId,
      chairId,
      locationId,
      patientId,
      providerId: event.providerId?.value
        ? event.providerId.value
        : biProvider || '1',
      referralId,
      checkInPatient: true,
      verifiedDoB: true
    };
    handleShowFinshLabDraw();
    const currentTimeDate = new Date();
    const labDrawStartTime = formatDateToAWSDateTime(currentTimeDate);
    await stepCheckInCall(input).then((npid) => {
      const labDrawInfo = {
        ...event,
        ...currentEventEditing,
        providerInfo: event.providerId,
        labDrawStartTime,
        npid
      };
      setLabDrawStartingInfo(labDrawInfo);
      handleShowFinshLabDraw();
    });
  };

  const handleStartTelevisit = async (event) => {
    debugger;
    const { id, agentId, chairId, locationId, patientId } = currentEventEditing;
    const input = {
      eventId: id,
      agentId,
      chairId,
      locationId,
      patientId,
      providerId: event.providerId?.value
        ? event.providerId.value
        : biProvider || '1',
      //   referralId,
      checkInPatient: true,
      verifiedDoB: true
    };
    handleShowFinishTelevisit();
    const currentTimeDate = new Date();
    const visitStartTime = formatDateToAWSDateTime(currentTimeDate);
    await stepCheckInCall(input).then((npid) => {
      const visitInfo = {
        ...event,
        ...currentEventEditing,
        providerInfo: event.providerId,
        visitStartTime,
        npid
      };
      setTelehealthStartingInfo(visitInfo);
      handleShowFinishTelevisit();
    });
  };

  const handlePatientRescheduleSubmit = async (dataItem) => {
    setLoading(true);
    const newStartTime = calculateToFifteen(dataItem.startTime);
    const newEndTime = calculateToFifteen(dataItem.endTime);

    if (!agent?.agentId?.length) {
      showError('Cannot reschedule appointment. Agent Id is missing');
      setLoading(false);
      return;
    }

    const referralId = currentEventEditing.referralId
      ? currentEventEditing.referralId
      : '';
    const patientId = currentEventEditing.patientId
      ? currentEventEditing.patientId
      : '';
    const eventToUpdate = currentEventEditing.id ? currentEventEditing.id : '';
    const adminSequence = !isPending
      ? await getAdminSequenceNumber(
          patientId,
          referralId,
          formatDateToAWSDateTime(dataItem.startDate),
          eventToUpdate
        )
      : null;

    if (adminSequence?.statusCode === '400') {
      showWarning(adminSequence.message);
      return;
    }

    const updatedNotes = `REASON: ${dataItem?.reasonPatientNotSeen} NOTES: ${dataItem?.reschedulingNotes}`;
    const requestObject = {
      id: currentEventEditing.id,
      ...(!isPending && {
        agentId: agent.agentId
      }),
      ...(isPending && {
        drugName: currentEventEditing?.drugName
      }),
      chairId: dataItem.chairId || 'c001',
      createdBy: agent.agentId,
      endTime: getUTCTimeString(
        dataItem.startDate,
        newEndTime,
        currentLocation.timeZone
      ),
      endTimeZone: 'UTC',
      locationId: dataItem.locationId?.id,
      notes: updatedNotes,
      patientId: currentEventEditing.patientId
        ? currentEventEditing.patientId
        : '',
      ...(!isPending && {
        providerId: currentEventEditing.providerId
          ? currentEventEditing.providerId
          : '1'
      }),
      ...(!isPending && {
        referralId
      }),
      resources: '',
      startTime: getUTCTimeString(
        dataItem.startDate,
        newStartTime,
        currentLocation.timeZone
      ),
      startTimeZone: 'UTC',
      status: isPending ? 'PENDING' : 'RESCHEDULED',
      title: currentEventEditing.appointmentTitle
        ? currentEventEditing.appointmentTitle
        : 'Appointment',
      updatedBy: agent.agentId,
      ...(!isPending && {
        adminSequenceNumber: currentEventEditing?.adminSequenceNumber
      })
    };

    const noteObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: agent.agentId,
      notes: [
        {
          date: moment(new Date()),
          note: updatedNotes,
          type: 'RESCHEDULE'
        }
      ]
    };

    isPending
      ? updatePendingEventCall(requestObject)
      : updateScheduleEventCall(requestObject);

    if (!!dataItem.reschedulingNotes) {
      addUpdateNotesCall(noteObject);
    }
  };

  const updateScheduleEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateScheduleEvent,
        variables: { input: requestObject }
      });

      if (
        data?.data?.updateScheduleEvent &&
        data.data.updateScheduleEvent.statusCode !== '400'
      ) {
        setShowNewAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        setLoading(false);
        getScheduleEventsByLocationIdCall(
          requestObject?.locationId,
          date,
          view
        );
      } else if (data.data.updateScheduleEvent.statusCode === '400') {
        showError(data.data.updateScheduleEvent.message);
        setLoading(false);
      }
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'updateScheduleEventCall',
        eventId: requestObject?.id
      });
      showError('Error: updateScheduleEventCall');
      setLoading(false);
    }
  };
  const updatePendingEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePendingEvent,
        variables: { agentId: agent?.agentId, input: requestObject }
      });

      if (data.data.updatePendingEvent.statusCode !== '400') {
        setShowNewAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        setLoading(false);
        getScheduleEventsByLocationIdCall(
          requestObject?.locationId,
          date,
          view
        );
      } else if (data.data.updatePendingEvent.statusCode === '400') {
        showError(data.data.updatePendingEvent.message);
        setLoading(false);
      }
    } catch (err) {
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'updatePendingEvent',
        eventId: requestObject?.id
      });
      showError('Error: updatePendingEventCall');
      setLoading(false);
    }
  };

  const handlePatientNotSeenSubmit = (dataItem) => {
    const updatedNotes = `REASON: ${dataItem?.reasonPatientNotSeen} NOTES: ${
      dataItem?.reschedulingNotes ? dataItem?.reschedulingNotes : ' '
    }`;
    const requestObject = {
      // id: ID!
      id: currentEventEditing.id,
      // agentId: ID!
      agentId: agent.agentId,
      // reason: String!
      reason: dataItem.reasonPatientNotSeen
        ? dataItem.reasonPatientNotSeen
        : 'All day note',
      // notes: String
      notes: updatedNotes || 'No Notes'
    };

    const noteObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: agent.agentId,
      notes: [
        {
          // date: AWSDateTime
          date: moment(new Date()),
          // note: String!
          note: updatedNotes,
          // type: NoteType
          type: 'APPOINTMENT'
        }
      ]
    };

    cancelScheduleEventCall(requestObject);
    if (!dataItem.reschedulingNotes) {
      //
    } else {
      addUpdateNotesCall(noteObject);
    }
  };
  const handleCancelSubmit = (dataItem) => {
    const cancelAll = dataItem?.cancelAllPending || false;
    setLoading(true);
    const updatedNotes = `CANCEL REASON: ${
      dataItem?.reasonPatientNotSeen
    } NOTES: ${
      dataItem?.reschedulingNotes ? dataItem?.reschedulingNotes : ' '
    }`;
    const requestObject = {
      // id: ID!
      id: currentEventEditing.id,
      // agentId: ID!
      agentId: agent.agentId,
      // reason: String!
      reason: dataItem.reasonPatientNotSeen
        ? dataItem.reasonPatientNotSeen
        : 'All day note',
      // notes: String
      notes: updatedNotes || 'No Notes',

      ...(isPending && {
        cancelAllRelated: cancelAll
      })
    };

    const noteObject = {
      patientId: selectedPatientInfo.patientId,
      agentId: agent.agentId,
      notes: [
        {
          // date: AWSDateTime
          date: moment(new Date()),
          // note: String!
          note: updatedNotes,
          // type: NoteType
          type: 'APPOINTMENT'
        }
      ]
    };
    isPending
      ? cancelPendingEventCall(requestObject)
      : cancelScheduleEventCall(requestObject);
    if (!dataItem.reschedulingNotes) {
      //
    } else {
      addUpdateNotesCall(noteObject);
    }
  };

  const cancelScheduleEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: cancelScheduleEvent,
        variables: { input: requestObject }
      });

      if (data && data.data && data.data.cancelScheduleEvent) {
        setShowNewAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setLoading(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        getScheduleEventsByLocationIdCall(currentLocation?.id, date, view);
        /*  setAppointmentListRefresh(!appointmentListRefresh); */
        /* setStatusUpdate(!statusUpdate); */
      }
    } catch (err) {
      setLoading(false);
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'cancelScheduleEventCall',
        eventId: requestObject?.id
      });
      showError('Error: cancelScheduleEventCall');
    }
  };
  const cancelPendingEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: cancelPendingEvent,
        variables: { agentId: agent?.agentId, input: requestObject }
      });

      if (data && data.data && data.data.cancelPendingEvent) {
        setShowNewAppointmentDialog(false);
        setShowPendingAppointmentDialog(false);
        setShowPatientSeenDialog(false);
        setShowPatientRescheduleDialog(false);
        setShowPatientNotSeenDialog(false);
        setAllDayNoteCancel(false);
        setLoading(false);
        getScheduleEventsByLocationIdCall(currentLocation?.id, date, view);
        /*  setAppointmentListRefresh(!appointmentListRefresh); */
        /* setStatusUpdate(!statusUpdate); */
      }
    } catch (err) {
      setLoading(false);
      logApiException(err, {
        view: 'SchedulingCalendarMain',
        endpoint: 'cancelPendingEventCall',
        eventId: requestObject?.id
      });
      showError('Error: cancelPendingEventCall');
    }
  };

  const handleNewNoteClick = () => {
    setShowNewNoteDialog(!showNewNoteDialog);
  };

  const handleNewNoteClose = () => {
    setCurrentEventEditing({});
    setShowNewNoteDialog(!showNewNoteDialog);
  };

  const handleNoteClose = () => {
    setCurrentEventEditing({});
    setShowNoteDialog(!showNoteDialog);
  };

  useEffect(() => {
    if (startInfusion) {
      setLoading(false);
      props.history.push({
        pathname: '/infusion-portal',
        // search: `?ref=calendar&nursingProcessId=${currentEventEditing.tNPID?.id}`,
        search: `?ref=calendar&nursingProcessId=${currentEventEditing.id}`,
        state: { dataItem: currentEventEditing }
      });
    }
  }, [startInfusion]);

  const editItemToParent = (dataItem) => {
    setParentMetaData(dataItem);
  };

  const { dob, patientFirstName, patientLastName } =
    parentMetaData?.scheduleEventMetaData
      ? parentMetaData?.scheduleEventMetaData
      : '';

  const EventItem = (eventProps) => {
    /* const editItemToParent = eventProps.editItemToParent; */
    const currentEvent = locationEvents.find(
      (event) => event.id === eventProps.dataItem.id
    );
    const [thisEventMetaData, setThisEventMetaData] = useState({
      dontRun: false
    });

    const [showThisEventMetaData, setShowThisEventMetaData] = useState(false);
    const [thisEventReferralMetaData, setThisEventReferralMetaData] = useState(
      {}
    );
    const [show, setShow] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showPending, setShowPending] = useState(false);
    const anchor = React.useRef(null);
    const { onMouseEnter, onMouseLeave } = eventProps;

    const handleEnter = useCallback(
      (event) => {
        const eventStatus = eventProps.dataItem.status;
        if (eventStatus !== 'INVALID' && eventStatus !== 'PENDING') {
          setShow(true);
        } else if (eventStatus === 'INVALID') {
          setShowInvalid(true);
        } else if (eventStatus === 'PENDING') {
          setShowPending(true);
        }

        if (onMouseEnter) {
          onMouseEnter(event);
        }
      },
      [onMouseEnter]
    );
    const handleLeave = useCallback(
      (event) => {
        setShow(false);
        setShowInvalid(false);
        setShowPending(false);
        if (onMouseLeave) {
          onMouseLeave(event);
        }
      },
      [onMouseLeave]
    );

    useEffect(() => {
      try {
        const scheduleEventMetaData = eventProps?.dataItem;
        if (!thisEventMetaData.dontRun) {
          let tERMD = [];
          tERMD = locationEvents.find(
            (current) => current.referralId === eventProps.dataItem.referralId
          );
          setThisEventReferralMetaData(tERMD);
          const tNPID = eventProps.dataItem?.nursingProcessId || '';

          setThisEventMetaData({ scheduleEventMetaData, tNPID, dontRun: true });
          setShowThisEventMetaData(true);
        }
      } catch (err) {
        logApiException(err, {
          view: 'SchedulingCalendarMain',
          endpoint: 'scheduleEventMetaData',
          nursingProcessId: eventProps.dataItem?.nursingProcessId
        });
        showError('Error: scheduleEventMetaData');
      }
    }, []);

    let itemBGColor = '#5392E4';
    let itemBorderColor = '#005282';
    let itemFontColor = '#FFFFFF';

    switch (eventProps.dataItem.status) {
      case 'SCHEDULED':
        itemBGColor = '#d8e4f7';
        itemBorderColor = '#6392de';
        itemFontColor = '#5a5a5a';
        break;
      case 'CONFIRMED':
        itemBGColor = '#d8e4f7';
        itemBorderColor = '#6392de';
        itemFontColor = '#5a5a5a';
        break;
      case 'CHECKED_IN':
        itemBGColor = '#f0eefa';
        itemBorderColor = '#c5bce9';
        itemFontColor = '#5a5a5a';
        break;
      case 'CANCELLED':
        itemBGColor = '#f4d6cc';
        itemBorderColor = '#d25b32';
        itemFontColor = '#5a5a5a';
        break;
      case 'CANCELLED_INFUSION':
        itemBGColor = '#f4d6cc';
        itemBorderColor = '#d25b32';
        itemFontColor = '#5a5a5a';
        break;
      case 'RESCHEDULED':
        itemBGColor = '#d8e4f7';
        itemBorderColor = '#6392de';
        itemFontColor = '#5a5a5a';
        break;
      case 'ORDER_REQUESTED':
        itemBGColor = '#e1eadf';
        itemBorderColor = '#005282';
        itemFontColor = '#5a5a5a';
        break;
      case 'COMPLETED_INFUSION':
        itemBGColor = '#e1eadf';
        itemBorderColor = '#89ac80';
        itemFontColor = '#5a5a5a';
        break;
      case 'COMPLETED_LAB_DRAW':
        itemBGColor = '#e1eadf';
        itemBorderColor = '#89ac80';
        itemFontColor = '#5a5a5a';
        break;
      case 'INVALID':
        itemBGColor = '#de350c';
        itemBorderColor = '#de350c';
        itemFontColor = '#FFFFFF';
        break;
      case 'PENDING':
        itemBGColor = '#ff9900';
        itemBorderColor = '#f26363';
        itemFontColor = '#FFFFFF';
        break;
      default:
        break;
    }

    switch (eventProps.dataItem.isAllDay) {
      case true:
        itemBGColor = '#f9efd2';
        itemBorderColor = '#e8be4d';
        itemFontColor = '#5a5a5a';
        break;
      default:
        break;
    }

    // @NOTE: temp solution, need to rely on product label from backend
    // as of now it doesn't include to the event response and not supported on schema
    // const calcDosage = () => {
    //   if (currentEvent?.adminStage?.drugName === 'Cabenuva') {
    //     return currentEvent.adminStage.dose === 1500 ? CABENUVA_DOSAGE_1500 : CABENUVA_DOSAGE_1000;
    //   }

    //   return `${currentEvent?.adminStage?.calcDosage} ${getDosageUOM(currentEvent?.adminStage?.unitOfMeas)}`;
    // };
    const eventData = thisEventMetaData?.scheduleEventMetaData;
    const isLabDraw = getIsLabDraw(eventData?.orderName);
    const isTelehealth = getIsTelehealthVisit(eventData?.eventType);
    const isOfficeVisit = getIsOfficeVisit(eventData?.eventType);
    const isAllDay = eventProps.dataItem.isAllDay;
    const patientName = `${thisEventMetaData.scheduleEventMetaData?.patientFirstName} ${thisEventMetaData.scheduleEventMetaData?.patientLastName}`;
    const patientId = thisEventMetaData.scheduleEventMetaData?.patientId;
    const patientInformation = `${patientName} (ID: ${patientId})`;
    const providerInformation = `${thisEventMetaData.scheduleEventMetaData?.providerFirstName} ${thisEventMetaData.scheduleEventMetaData?.providerLastName} (ID:${thisEventMetaData.scheduleEventMetaData?.providerId})`;
    const newPatientStatus = !!eventProps.dataItem.isNewPatient ? (
      <NewPatientBadge show={true} />
    ) : null;

    const render = {
      eventMetaData: () => {
        if (!showThisEventMetaData) return null;
        if (isAllDay) {
          return <>{eventProps.dataItem.title}</>;
        }

        // labdraw
        if (isLabDraw) {
          return (
            <>
              <EventBadgeContainer>
                <div>
                  <AwesomeLabel
                    label={eventProps.dataItem.status}
                    icon='vial'
                    iconFirst={false}
                  />
                </div>
                <div>{newPatientStatus}</div>
              </EventBadgeContainer>
              <div>Patient: {patientInformation}</div>
              <div>
                Referral Type: {getReferralText(thisEventReferralMetaData)}
              </div>
              <div>Notes: {eventProps.dataItem.notes}</div>
            </>
          );
        }

        // telehealth visit
        if (isTelehealth) {
          return (
            <>
              <div>
                <TelehealthVisitBadge />
              </div>
              <EventBadgeContainer>
                <div>
                  <AwesomeLabel
                    label={eventProps.dataItem.status}
                    icon='laptop-medical'
                    iconFirst={false}
                  />
                </div>
                <div>{newPatientStatus}</div>
              </EventBadgeContainer>
              <div>Patient: {patientInformation}</div>
              <div>Notes: {eventProps.dataItem.notes}</div>
            </>
          );
        }

        // office visit
        if (isOfficeVisit) {
          return (
            <>
              <div>
                <OfficeVisitBadge />
              </div>
              <EventBadgeContainer>
                <div>
                  <AwesomeLabel
                    label={eventProps.dataItem.status}
                    icon='user-doctor'
                    iconFirst={false}
                  />
                </div>
                <div>{newPatientStatus}</div>
              </EventBadgeContainer>
              <div>Patient: {patientInformation}</div>
              <div>Notes: {eventProps.dataItem.notes}</div>
            </>
          );
        }

        // pending appointment
        if (eventProps.dataItem.status === 'PENDING') {
          return (
            <>
              <EventBadgeContainer>
                <div>{eventProps.dataItem.status}</div>
                <div>{newPatientStatus}</div>
              </EventBadgeContainer>
              <div>Patient: {patientInformation}</div>
              <div>Drug Name: {eventProps.dataItem.drugName}</div>
              <div>Notes: {eventProps.dataItem.notes}</div>
            </>
          );
        }

        // default behavior
        return (
          <>
            <EventBadgeContainer>
              <div>{eventProps.dataItem.status}</div>
              <div>{newPatientStatus}</div>
            </EventBadgeContainer>
            <div>Patient: {patientInformation}</div>
            <div>Referral: {getReferralText(thisEventReferralMetaData)}</div>
            {eventProps.dataItem.status !== 'COMPLETED_INFUSION' && (
              <>
                <div>Calc. Dos: {getDosageFromEvent(currentEvent)}</div>
                <div>
                  Admin: {currentEvent?.adminStage?.administer},{' '}
                  {currentEvent?.adminStage?.route}
                </div>
                <div>Notes: {eventProps.dataItem.notes}</div>
              </>
            )}
          </>
        );
      }
    };

    return (
      <>
        <SchedulerItem
          {...eventProps}
          style={{
            border: `1px solid ${itemBorderColor}`,
            backgroundColor: itemBGColor,
            color: itemFontColor,
            fontSize: '0.9em'
          }}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          <SchedulerItemContent ref={anchor}>
            {render.eventMetaData()}
          </SchedulerItemContent>
        </SchedulerItem>

        <Popup
          anchor={anchor.current}
          show={show && isAllDay}
          popupClass='popup-content-all-day'
        >
          <>
            <div className='row infusionTable'>
              <div className='col'>
                <span>
                  <b>{eventProps?.dataItem?.Title}</b>
                </span>
                <br />
              </div>
            </div>
          </>
        </Popup>

        <Popup
          anchor={anchor.current}
          show={show && isLabDraw}
          popupClass='popup-content'
        >
          <CalendarEventPopup
            rows={[
              { key: 'PATIENT', value: patientInformation },
              { key: 'CREATED BY', value: currentEvent?.createdBy },
              {
                key: 'CREATED AT',
                value: formatDateToDefault(currentEvent?.createdAt)
              },
              {
                key: 'REFERRAL TYPE',
                value: currentEvent?.adminStage?.drugName
              },
              {
                key: 'ADMIN SEQ NO',
                value: currentEvent?.adminSequenceNumber || '-'
              },
              {
                key: 'ADMIN',
                value: `${currentEvent?.adminStage?.administer}, ${currentEvent?.adminStage?.route}`
              },
              ,
            ]}
          />
        </Popup>

        {/* <tr> cannot appear as a child of <table>. Add a <tbody>, <thead> or <tfoot> to your code to match the DOM tree generated by the browser. */}
        <Popup
          anchor={anchor.current}
          show={
            show &&
            eventProps.dataItem.status !== 'COMPLETED_INFUSION' &&
            !isAllDay &&
            !isLabDraw &&
            !isTelehealth &&
            !isOfficeVisit
          }
          popupClass='popup-content'
        >
          <CalendarEventPopup
            rows={[
              { key: 'PATIENT', value: patientInformation },
              { key: 'CREATED BY', value: currentEvent?.createdBy },
              {
                key: 'CREATED AT',
                value: formatDateToDefault(currentEvent?.createdAt)
              },
              ...(eventProps.dataItem.status !== 'SCHEDULED' &&
              eventProps.dataItem.status !== 'RESCHEDULED'
                ? [{ key: 'PROVIDER', value: providerInformation }]
                : []),
              {
                key: 'DRUG NAME',
                value: currentEvent?.adminStage?.drugName
              },
              { key: 'CALC. DOS', value: getDosageFromEvent(currentEvent) },
              {
                key: 'UPDATED WT',
                value: currentEvent?.patientLatestWeight
                  ? `${currentEvent?.patientLatestWeight}(kg), Dose: ${
                      currentEvent?.adminStage?.dose
                    } ${getDosageUOM(currentEvent?.adminStage?.unitOfMeas)}`
                  : '-'
              },
              {
                key: 'ADMIN SEQ NO',
                value: currentEvent?.adminSequenceNumber || '-'
              },
              {
                key: 'ADMIN',
                value: `${currentEvent?.adminStage?.administer}, ${currentEvent?.adminStage?.route}`
              }
            ]}
          />
        </Popup>

        <Popup
          anchor={anchor.current}
          show={showInvalid}
          popupClass='popup-content'
        >
          <>
            <div
              className='row infusionTable'
              style={{
                fontSize: '12px',
                backgroundColor: '#ffffff'
              }}
            >
              <div className='col'>
                <span>
                  <b>Patient: {patientInformation}</b>
                </span>
                <br />
                <span>
                  <b>Please complete all open work items</b>
                </span>
              </div>
            </div>
          </>
        </Popup>

        <Popup
          anchor={anchor.current}
          show={showPending}
          popupClass='popup-content'
          style={{ maxWidth: '45%' }}
        >
          <CalendarEventPopup
            rows={[
              { key: 'PATIENT', value: patientInformation },
              {
                key: 'DRUG NAME',
                value: currentEvent?.adminStage?.drugName
              },
              { key: 'NOTES', value: currentEvent?.notes }
            ]}
          />
        </Popup>

        <Popup
          anchor={anchor.current}
          show={show && (isTelehealth || isOfficeVisit)}
          popupClass='popup-content'
          style={{ maxWidth: '45%' }}
        >
          <CalendarEventPopup
            rows={[
              { key: 'PATIENT', value: patientInformation },
              { key: 'NOTES', value: currentEvent?.notes }
            ]}
          />
        </Popup>

        <Popup
          anchor={anchor.current}
          show={show && eventProps.dataItem.status === 'COMPLETED_INFUSION'}
          popupClass='popup-content'
        >
          <>
            <div
              className='row infusionTable'
              style={{
                fontSize: '12px',
                backgroundColor: '#ffffff'
              }}
            >
              <div className='col'>
                <span>
                  <b>Patient: {patientInformation}</b>
                </span>
                <br />
                <span>
                  <b>Drug Name: {currentEvent?.adminStage?.drugName}</b>
                </span>
              </div>
            </div>
          </>
        </Popup>
      </>
    );
  };

  const displayWarning = () =>
    showWarning(Constants.ErrorMessage.notEnoughRights);

  const EventEdit = (eventProps) => {
    const [thisEventMetaData, setThisEventMetaData] = useState({
      dontRun: false
    });
    const [thisEventReferralMetaData, setThisEventReferralMetaData] = useState(
      {}
    );
    useEffect(() => {
      try {
        const scheduleEventMetaData = eventProps?.dataItem;
        if (!thisEventMetaData.dontRun) {
          let tERMD = [];
          tERMD = locationEvents.find(
            (current) => current.referralId === eventProps.dataItem.referralId
          );
          setThisEventReferralMetaData(tERMD);
          const tNPID = eventProps.dataItem?.nursingProcessId || '';
          setThisEventMetaData({
            scheduleEventMetaData,
            tNPID,
            dontRun: true
          });
        }
      } catch (err) {
        setLoading(false);
        logApiException(err, {
          view: 'SchedulingCalendarMain',
          endpoint: 'scheduleEventMetaData',
          nursingProcessId: eventProps.dataItem?.nursingProcessId
        });
        showError('Error: scheduleEventMetaData');
      }
    }, []);

    const [showAppointmentCheckInDialog, setShowAppointmentCheckInDialog] =
      useState(false);
    const [showTelehealthCheckInDialog, setShowTelehealthCheckInDialog] =
      useState(false);
    const [showOfficeVisitCheckInDialog, setShowOfficeVisitCheckInDialog] =
      useState(false);
    const [showAppointmentInvalidDialog, setShowAppointmentInvalidInDialog] =
      useState(false);

    const [showStartInfusionDialog, setShowStartInfusionDialog] =
      useState(false);
    const [showRevisitInfusionDialog, setShowRevisitInfusionDialog] =
      useState(false);

    const handleClick = useCallback(
      async (event) => {
        const eventPropsId = eventProps?.dataItem?.patientId;
        const selectedId = selectedPatientInfo?.patientId;
        const status = eventProps?.dataItem?.status;
        const hasAdminStage = eventProps?.dataItem?.adminStage !== null;
        const isTelehealth =
          eventProps?.dataItem?.eventType ===
          SCHEDULE_EVENTS.APPOINTMENT_TELEHEALTH;
        const isOfficeVisit =
          eventProps?.dataItem?.eventType ===
          SCHEDULE_EVENTS.APPOINTMENT_OFFICE_VISIT;

        if (eventProps.isAllDay) {
          setCurrentEventEditing(eventProps.dataItem);
          setAllDayNoteCancel(!showAllDayNoteCancel);
        } else if (status === eventStatus.CHECKED_IN && hasAdminStage) {
          if (!isEditable) {
            displayWarning();
          } else {
            setShowStartInfusionDialog(true);
          }
          if (calendarLayout === 1) {
            setSelectedPatientInfo(eventProps.dataItem);
          }
        } else if (status === eventStatus.COMPLETED_INFUSION) {
          if (calendarLayout === 1) {
            setSelectedPatientInfo(eventProps.dataItem);
          }
        } else if (
          hasAdminStage &&
          [
            eventStatus.SCHEDULED,
            eventStatus.CONFIRMED,
            eventStatus.RESCHEDULED
          ].includes(status)
        ) {
          if (!isEditable) {
            displayWarning();
          } else {
            setShowAppointmentCheckInDialog(true);
            setSelectedDataItem(eventProps.dataItem);
          }
          if (calendarLayout === 1) {
            setSelectedPatientInfo(eventProps.dataItem);
          }
        } else if (status === eventStatus.CANCELLED) {
          showWarning(
            'This appointment has been cancelled and cannot be edited.'
          );
          if (calendarLayout === 1) {
            setSelectedPatientInfo(eventProps.dataItem);
          }
        } else if (status === eventStatus.STARTED_INFUSION) {
          if (calendarLayout === 1) {
            setSelectedPatientInfo(eventProps.dataItem);
          }
        } else if (calendarLayout === 2) {
          if (
            !hasAdminStage &&
            eventPropsId === selectedId &&
            [
              eventStatus.SCHEDULED,
              eventStatus.CONFIRMED,
              eventStatus.INVALID
            ].includes(status)
          ) {
            setSelectedPatientInfo(eventProps.dataItem);
            setShowSetNewReferral(true);
          } else if (
            status === eventStatus.INVALID &&
            eventPropsId === selectedId
          ) {
            setShowAppointmentCheckInDialog(true);
          } else if (
            status === eventStatus.INVALID &&
            eventPropsId !== selectedId
          ) {
            setSelectedPatientInfo(eventProps.dataItem);
            if (!isEditable) {
              displayWarning();
            } else {
              setShowAppointmentInvalidInDialog(true);
            }
          }
        } else if (
          (calendarLayout === 1 && status === eventStatus.INVALID) ||
          status === eventStatus.PENDING
        ) {
          setSelectedPatientInfo(eventProps.dataItem);
          if (!isEditable) {
            displayWarning();
          } else {
            setShowAppointmentInvalidInDialog(true);
            setStatusIsPending(status === eventStatus.PENDING);
          }
        } else if (
          !hasAdminStage &&
          [eventStatus.SCHEDULED, eventStatus.RESCHEDULED].includes(status)
        ) {
          setSelectedPatientInfo(eventProps.dataItem);
          setCurrentEventEditing(eventProps.dataItem);

          if (isOfficeVisit || isTelehealth) {
            setShowTelehealthCheckInDialog(isTelehealth);
            setShowOfficeVisitCheckInDialog(isOfficeVisit);
          } else {
            setShowPatientNotSeenDialog(!showPatientNotSeenDialog);
          }
        } else if (
          status ===
          eventStatus.STARTED_LAB_DRAW /* Whole file needs updated with status mapping once in Devx. Part of it is already done in Devx */
        ) {
          const nursingProcess = await getNursingProcessCall(
            eventProps.dataItem.nursingProcessId
          );
          if (nursingProcess) {
            const labDrawInformation = {
              ...eventProps.dataItem,
              labDrawStartTime: nursingProcess.startTime
            };
            setLabDrawStartingInfo(labDrawInformation);
            handleShowFinshLabDraw();
          }
        }
        if (eventProps.onFocus) {
          eventProps.onFocus(event);
        }
      },
      [
        setShowAppointmentCheckInDialog,
        setShowTelehealthCheckInDialog,
        eventProps
      ]
    );

    const handleCloseClick = useCallback(() => {
      setShowAppointmentCheckInDialog(false);
      setShowPatientSeenDialog(false);
      setShowStartInfusionDialog(false);
      setShowRevisitInfusionDialog(false);
      setShowAppointmentInvalidInDialog(false);
      setShowTelehealthCheckInDialog(false);
      setShowOfficeVisitCheckInDialog(false);
    }, [setShowAppointmentCheckInDialog]);

    const handleCheckInSubmit = () => {
      showError('handleCheckInSubmit error');
    };

    const handleStartInfusionSubmit = () => {
      setLoading(true);
      setStartInfusion(true);
    };

    const handleRevisitInfusionSubmit = () => {
      setLoading(true);
      setStartInfusion(true);
    };

    const runCheckIn = () => {
      setCurrentEventEditing(eventProps?.dataItem);
      setShowPatientSeenDialog(!showPatientSeenDialog);
      setNewAppointmentNotes(eventProps?.dataItem?.notes);
      editItemToParent(thisEventMetaData);
    };

    const checkIn = () => {
      if (process.env.NODE_ENV !== 'development') {
        const checkDate = isToday(
          new Date(thisEventMetaData?.scheduleEventMetaData?.Start)
        );
        if (checkDate === true) {
          runCheckIn();
        } else {
          showError('Error: Infusion can only be started on current date.');
          setLoading(false);
        }
      } else {
        runCheckIn();
      }
    };

    const eventData = thisEventMetaData?.scheduleEventMetaData;
    const patientInformation = `${thisEventMetaData.scheduleEventMetaData?.patientFirstName} ${thisEventMetaData.scheduleEventMetaData?.patientLastName} (ID:${thisEventMetaData.scheduleEventMetaData?.patientId})`;
    const providerInformation = `${thisEventMetaData.scheduleEventMetaData?.providerFirstName} ${thisEventMetaData.scheduleEventMetaData?.providerLastName} (ID:${thisEventMetaData.scheduleEventMetaData?.providerId})`;
    const isLabDraw = getIsLabDraw(eventData?.orderName);
    const modalHeight = getModalHeight(isLabDraw);
    const isPast = isDateFromPast(eventData?.endTime);
    const isCheckinDisabled =
      isPast === true || eventProps?.dataItem?.status === eventStatus.INVALID;

    return (
      <>
        <SchedulerViewItem {...eventProps} onClick={handleClick} />

        {showAppointmentCheckInDialog && (
          <Dialog
            id='dialogAppointmentCheckIn'
            title='Appointment Check-In'
            height={modalHeight}
            width={600}
            initialTop={350}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formAppointmentCheckIn'
              onSubmit={handleCheckInSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <div
                    className='row mt-10 infusionTable'
                    style={{
                      fontSize: '12px',
                      backgroundColor: '#ffffff'
                    }}
                  >
                    <table width='100%' cellPadding='8' border='0'>
                      <tbody>
                        <tr>
                          <th className={styles.backgroundWidthWrap}>
                            PATIENT:
                          </th>
                          <td className={styles.infoBox}>
                            {patientInformation}
                          </td>
                        </tr>
                        <tr className={styles.backgroundBox}>
                          <th className={styles.backgroundWidth}>
                            CREATED BY:
                          </th>
                          <td className={styles.infoBox}>
                            {eventData?.createdBy}
                          </td>
                        </tr>
                        <tr className={styles.backgroundBox}>
                          <th className={styles.backgroundWidth}>CREATED:</th>
                          <td className={styles.infoBox}>
                            {formatDateTimeToDefault(eventData?.createdAt)}
                          </td>
                        </tr>
                        {!isLabDraw && (
                          <>
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>
                                PLAN NAME:
                              </th>
                              <td className={styles.infoBox}>{planName}</td>
                            </tr>
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>
                                BILLING REQ:
                              </th>
                              <td className={styles.infoBox}>{override}</td>
                            </tr>
                            {/* ////////////////////////////////// */}
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>
                                DRUG NAME:
                              </th>
                              <td className={styles.infoBox}>
                                {eventData?.adminStage?.drugName}
                              </td>
                            </tr>

                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>
                                CALC. DOS:
                              </th>
                              <td className={styles.infoBox}>
                                {getDosageFromEvent(eventData)}
                              </td>
                            </tr>
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>
                                UPDATED WT:
                              </th>
                              <td className={styles.infoBox}>
                                {locationEvents.map((index) => {
                                  if (index.id === eventProps.dataItem.id) {
                                    return index?.patientLatestWeight
                                      ? `${index?.patientLatestWeight}(kg), Dose: ${index?.adminStage?.dose} ${index?.adminStage?.unitOfMeas}`
                                      : '-';
                                  }
                                  return null;
                                })}
                              </td>
                            </tr>
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth20}>
                                ADMIN SEQ NO:
                              </th>
                              <td className={styles.infoBoxWrap}>
                                {locationEvents.map((index) => {
                                  if (index.id === eventProps.dataItem.id) {
                                    return index?.adminStage
                                      ?.adminSequenceNumber;
                                  }
                                  return null;
                                })}
                              </td>
                            </tr>
                            <tr className={styles.backgroundBox}>
                              <th className={styles.backgroundWidth}>ADMIN:</th>
                              <td className={styles.infoBox}>
                                {locationEvents.map((index) => {
                                  if (index.id === eventProps.dataItem.id) {
                                    return index?.adminStage?.administer;
                                  }
                                  return null;
                                })}
                                ,{' '}
                                {locationEvents.map((index) => {
                                  if (index.id === eventProps.dataItem.id) {
                                    return index?.adminStage?.route;
                                  }
                                  return null;
                                })}
                              </td>
                            </tr>
                          </>
                        )}
                        {isLabDraw && (
                          <>
                            <tr
                              style={{
                                fontSize: '11px',
                                backgroundColor: '#e2e1e1'
                              }}
                            >
                              <th
                                style={{
                                  fontSize: '11px',
                                  backgroundColor: '#e2e1e1',
                                  width: '15%'
                                }}
                              >
                                REFERRAL:
                              </th>
                              <td
                                style={{
                                  fontSize: '12px',
                                  backgroundColor: '#ffffff'
                                }}
                              >
                                {eventData?.adminStage?.drugName}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className='row mt-10'>
                    <Field
                      name='appointmentNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      value={null}
                      defaultValue={eventProps.dataItem.notes}
                    />
                  </div>

                  <div className='row mt-10'>
                    <div className='col-4 d-flex justify-content-center'>
                      {locationEvents.map((index) => {
                        if (index.id === eventProps?.dataItem.id) {
                          const isPast = isDateFromPast(index?.endTime);
                          if (
                            isPast === true ||
                            eventProps?.dataItem?.status === 'INVALID'
                          ) {
                            return (
                              <FormButton
                                key={index.id}
                                type='button'
                                title='Patient Seen (Check In)'
                                className='btn pageButtonBlue'
                                disabled='true'
                                label='Please Reschedule'
                              />
                            );
                          }
                          return (
                            <FormButton
                              key={index.id}
                              type='button'
                              title='Patient Seen (Check In)'
                              className='btn pageButtonBlue'
                              onClick={() => {
                                checkIn();
                              }}
                              label='Check In Patient'
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn blue'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientRescheduleDialog(
                            !showPatientRescheduleDialog
                          );
                        }}
                        label='Reschedule'
                      />
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientNotSeenDialog(
                            !showPatientNotSeenDialog
                          );
                        }}
                        label='Cancel Appointment'
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
        {showTelehealthCheckInDialog && (
          <Dialog
            id='dialogTelehealthCheckIn'
            title='Telehealth Visit Check-In'
            height='auto'
            width={600}
            initialTop={350}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formTelehealthCheckIn'
              onSubmit={handleCheckInSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <CalendarEventPopup
                    rows={[
                      { key: 'PATIENT', value: patientInformation },
                      { key: 'CREATED BY', value: eventData?.createdBy },
                      {
                        key: 'CREATED',
                        value: formatDateTimeToDefault(eventData?.createdAt)
                      }
                    ]}
                  />
                  <div className='row mt-10'>
                    <Field
                      name='appointmentNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      defaultValue={eventProps.dataItem.notes}
                    />
                  </div>
                  <div className='row mt-10'>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn pageButtonBlue'
                        disabled={isCheckinDisabled}
                        label={
                          isCheckinDisabled
                            ? 'Please Reschedule'
                            : 'Check In Patient'
                        }
                        onClick={isCheckinDisabled ? null : checkIn}
                      />
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      {/* <FormButton
                        type='button'
                        className='btn blue'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientRescheduleDialog(
                            !showPatientRescheduleDialog
                          );
                        }}
                        label='Reschedule'
                      /> */}
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientNotSeenDialog(
                            !showPatientNotSeenDialog
                          );
                        }}
                        label='Cancel Appointment'
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
        {showOfficeVisitCheckInDialog && (
          <Dialog
            id='dialogOfficeVisitCheckIn'
            title='Office Visit Check-In'
            height='auto'
            width={600}
            initialTop={350}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formOfficeVisitCheckIn'
              onSubmit={handleCheckInSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <CalendarEventPopup
                    rows={[
                      { key: 'PATIENT', value: patientInformation },
                      { key: 'CREATED BY', value: eventData?.createdBy },
                      {
                        key: 'CREATED',
                        value: formatDateTimeToDefault(eventData?.createdAt)
                      }
                    ]}
                  />
                  <div className='row mt-10'>
                    <Field
                      name='appointmentNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      defaultValue={eventProps.dataItem.notes}
                    />
                  </div>
                  <div className='row mt-10'>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn pageButtonBlue'
                        disabled={isCheckinDisabled}
                        label={
                          isCheckinDisabled
                            ? 'Please Reschedule'
                            : 'Check In Patient'
                        }
                        onClick={isCheckinDisabled ? null : checkIn}
                      />
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      {/* <FormButton
                        type='button'
                        className='btn blue'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientRescheduleDialog(
                            !showPatientRescheduleDialog
                          );
                        }}
                        label='Reschedule'
                      /> */}
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientNotSeenDialog(
                            !showPatientNotSeenDialog
                          );
                        }}
                        label='Cancel Appointment'
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
        {showAppointmentInvalidDialog && (
          <Dialog
            id='dialogAppointmentInvalid'
            title={
              statusIsPending ? 'Appointment Pending' : 'Appointment Invalid'
            }
            height={250}
            width={600}
            initialTop={350}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formAppointmentInvalid'
              onSubmit={handleCheckInSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <div
                    className='row mt-10 infusionTable'
                    style={{
                      fontSize: '12px',
                      backgroundColor: '#ffffff'
                    }}
                  >
                    <table width='100%' cellPadding='8' border='0'>
                      <tbody>
                        <tr>
                          <th
                            style={{
                              fontSize: '11px',
                              backgroundColor: '#e2e1e1',
                              width: '15%',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            PATIENT:
                          </th>
                          <td
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            {patientInformation}
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1'
                          }}
                        >
                          <th
                            style={{
                              fontSize: '11px',
                              backgroundColor: '#e2e1e1',
                              width: '15%'
                            }}
                          >
                            CREATED BY:
                          </th>
                          <td
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            {eventData?.createdBy}
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1'
                          }}
                        >
                          <th
                            style={{
                              fontSize: '11px',
                              backgroundColor: '#e2e1e1',
                              width: '15%'
                            }}
                          >
                            CREATED:
                          </th>
                          <td
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            {formatDateTimeToDefault(eventData?.createdAt)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className='row mt-10'>
                    <div className='col-4 d-flex justify-content-center'>
                      <Button
                        type='button'
                        className='mr-3'
                        onClick={handleDetailsClick}
                      >
                        <i className='fa-regular fa-hospital-user fa-sm icon-white'>
                          &nbsp;&nbsp;
                        </i>
                        Patient Details
                      </Button>
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      {statusIsPending && (
                        <FormButton
                          type='button'
                          className='btn blue'
                          onClick={() => {
                            setCurrentEventEditing(eventProps.dataItem);
                            setShowPatientRescheduleDialog(
                              !showPatientRescheduleDialog
                            );
                          }}
                          label='Reschedule'
                        />
                      )}
                    </div>
                    <div className='col-4 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowPatientNotSeenDialog(
                            !showPatientNotSeenDialog
                          );
                        }}
                        label='Cancel Appointment'
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}

        {showStartInfusionDialog && (
          <Dialog
            id='dialogStartInfusion'
            title='Start Infusion'
            height={500}
            initialTop={350}
            width={600}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formStartInfusion'
              onSubmit={handleStartInfusionSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <div
                    className='row mt-10 infusionTable'
                    style={{
                      fontSize: '12px',
                      backgroundColor: '#ffffff'
                    }}
                  >
                    <table width='100%' cellPadding='8' border='0'>
                      <tr>
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          PATIENT
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {patientInformation}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          CREATED BY
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {eventData?.createdBy}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          CREATED
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {formatDateTimeToDefault(eventData?.createdAt)}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          PROVIDER
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {providerInformation}
                        </td>
                      </tr>
                      {/* ////////////////////////////////// */}
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          DRUG NAME
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {eventData?.adminStage?.drugName}
                        </td>
                      </tr>

                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          CALCULATED DOSAGE
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {getDosageFromEvent(eventData)}
                          {/* {eventData?.adminStage?.calcDosage}{' '}
                          {getDosageUOM(eventData?.adminStage?.unitOfMeas)} */}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          ADMIN SEQUENCE NUMBER
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {eventData?.adminStage?.adminSequenceNumber}
                        </td>
                      </tr>
                      <tr
                        style={{
                          fontSize: '11px',
                          backgroundColor: '#e2e1e1'
                        }}
                      >
                        <th
                          style={{
                            fontSize: '11px',
                            backgroundColor: '#e2e1e1',
                            width: '15%'
                          }}
                        >
                          ADMINISTRATION
                        </th>
                        <td
                          style={{
                            fontSize: '12px',
                            backgroundColor: '#ffffff'
                          }}
                        >
                          {eventData?.adminStage?.administer},{' '}
                          {eventData?.adminStage?.route}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className='row mt-24'>
                    <div className='col-12 d-flex justify-content-center'>
                      <StartInfusionButton
                        eventDetails={eventProps.dataItem}
                        calendarCallback={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowStartInfusionDialog(!showStartInfusionDialog);
                          handleStartInfusionSubmit(eventProps.dataItem);
                          localStorage.removeItem('narrativeNotes');
                        }}
                        superInfusionCallback={() => {
                          const {
                            id,
                            agentId,
                            chairId,
                            locationId,
                            patientId,
                            providerId,
                            referralId
                          } = eventProps.dataItem;
                          const input = {
                            eventId: id,
                            agentId,
                            chairId,
                            locationId,
                            patientId,
                            providerId,
                            referralId,
                            checkInPatient: true,
                            verifiedDoB: true
                          };

                          stepCheckInCall(input).then((npid) => {
                            // redirect to the new infusion route ===>
                            history.push(`/infusion/${npid}`);
                          });
                        }}
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}

        {showRevisitInfusionDialog && (
          <Dialog
            id='dialogRevisitInfusion'
            title='Revisit Infusion'
            height={400}
            initialTop={350}
            width={600}
            showDialog
            onClose={handleCloseClick}
          >
            <Form
              id='formRevisitInfusion'
              onSubmit={handleRevisitInfusionSubmit}
              render={(formRenderProps) => (
                <form
                  onSubmit={formRenderProps.onSubmit}
                  className='k-form pl-3 pr-3 pt-3'
                >
                  <div className='row mt-10'>
                    <div className='col-12'>
                      <strong>Patient Info:</strong>&nbsp;
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.patientFirstName
                      }
                      &nbsp;
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.patientLastName
                      }
                      &nbsp; (ID:{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.patientId
                      }
                      )
                    </div>
                  </div>
                  <div className='row mt-10'>
                    <div className='col-12'>
                      <strong>Appointment Notes:</strong>{' '}
                      {eventProps.dataItem.notes}
                    </div>
                  </div>
                  <div className='row mt-10'>
                    <div className='col-12'>
                      <strong>Provider Info:</strong>&nbsp;
                      {
                        thisEventMetaData.scheduleEventMetaData?.getProviderAIC
                          ?.firstName
                      }
                      &nbsp;
                      {
                        thisEventMetaData.scheduleEventMetaData?.getProviderAIC
                          ?.lastName
                      }
                      &nbsp; (NPI:{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData?.getProviderAIC
                          ?.providerNPI
                      }
                      )
                      <br />
                      Referral:{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.referral?.drugReferrals[0]
                          ?.referralOrder?.orderName
                      }
                      <br />
                      Dosage:{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.referral?.drugReferrals[0]
                          ?.drugName
                      }{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.referral?.drugReferrals[0]
                          ?.referralOrder?.administrations[0]?.approvedDosage
                      }{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.referral?.drugReferrals[0]
                          ?.referralOrder?.administrations[0]?.unitOfMeas
                      }
                      <br />
                      Administration:{' '}
                      {
                        thisEventMetaData.scheduleEventMetaData
                          ?.getPatientBucket?.referral?.drugReferrals[0]
                          ?.referralOrder?.administrations[0]?.administer
                      }
                    </div>
                  </div>
                  <div className='row mt-10'>
                    <div className='col-12'>
                      <strong>Event ID:</strong>&nbsp;
                      {eventProps.dataItem.id}
                    </div>
                  </div>
                  <div className='row mt-10'>
                    <div className='col-12'>
                      <strong>Nursing Process ID:</strong>&nbsp;
                      {eventProps.dataItem.tNPID?.id}
                    </div>
                  </div>
                  <div className='row mt-24'>
                    <div className='col-12 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn blue'
                        loading={loading}
                        onClick={() => {
                          setCurrentEventEditing(eventProps.dataItem);
                          setShowRevisitInfusionDialog(
                            !showRevisitInfusionDialog
                          );
                          handleRevisitInfusionSubmit(eventProps.dataItem);
                        }}
                        label='Revisit Infusion'
                      />
                    </div>
                  </div>
                </form>
              )}
            />
          </Dialog>
        )}
      </>
    );
  };

  /*  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]); */

  const handleLocationTreeChange = (selectedItem, formRenderProps) => {
    if (selectedItem?.id) {
      setCurrentLocation(selectedItem);
      formRenderProps.onChange('locationId', {
        value: selectedItem
      });
      formRenderProps.onChange('chairId', {
        value: null
      });
    }
  };

  const cancelationReasons = getCancelReasons(currentEventEditing?.eventType);

  return (
    <>
      <div className='col-12'>
        <div className='row'>
          {!schedulerLocationId ||
          !selectedPatientInfo?.patientId ||
          !isEditable ? null : (
            <>
              <div className='col-6 mt-12' style={{ textAlign: 'left' }}>
                <CurrentPatient />
              </div>

              <div className='col-6 mt-12' style={{ textAlign: 'right' }}>
                <Button
                  type='button'
                  className='mr-3'
                  onClick={handleDetailsClick}
                >
                  <i className='fa-regular fa-hospital-user fa-sm icon-white'>
                    &nbsp;&nbsp;
                  </i>
                  Patient Details
                </Button>
                <DropDownButton
                  primary
                  items={[
                    {
                      text: 'Drug Infusion',
                      action: handleNewAppointmentClick
                    },
                    {
                      text: 'Pending Appointment',
                      action: handlePendingAppointmentClick
                    },
                    {
                      text: 'Telehealth Visit',
                      action: () => setShowNewTelehealthModal(true)
                    },
                    {
                      text: 'Office Visit',
                      action: () => setShowOfficeVisitModal(true)
                    }
                  ]}
                  iconClass='fa-regular fa-calendar-days fa-sm icon-white mr-2'
                  text='New Appointment'
                  onItemClick={(event) => event.item.action()}
                />
                <Button
                  type='button'
                  className='ml-3'
                  onClick={handleNewNoteClick}
                >
                  <i className='fa-solid fa-notes fa-sm icon-white'>
                    &nbsp;&nbsp;
                  </i>
                  All-Day Note
                </Button>
              </div>
            </>
          )}
        </div>
        <ScheduleBanner
          view={view}
          date={date}
          currentLocation={currentLocation}
        />
        {!schedulerLocationId ? null : (
          <DisplayScheduler
            locale={locale}
            locationEvents={locationEvents}
            handleDataChange={handleDataChange}
            handleViewChange={handleViewChange}
            view={view}
            handleDateChange={handleDateChange}
            EventEdit={EventEdit}
            EventItem={EventItem}
            timezone={currentLocation?.timeZone}
            handleRemoveClick={handleRemoveClick}
            orientation={orientation}
            history={props.history}
            chairData={chairsData.map((chair) => ({
              text: chair,
              value: chair
            }))}
            date={date}
          />
        )}
      </div>

      {showPatientSeenDialog && (
        <Dialog
          id='dialogPatientSeen'
          title='Patient Seen (Check-In)'
          height='auto'
          width={730}
          showDialog
          onClose={handlePatientSeenClose}
        >
          <Form
            id='formPatientSeen'
            onSubmit={(event) => {
              if (isCurrentEventLabDraw) {
                return handleStartLabDraw(event);
              }

              if (isTelehealth) {
                showWarning('Telehealth visit is not fully implemented yet.');
                return;
                // return handleStartTelevisit(event);
              }

              if (isOfficeVisit) {
                showWarning('Office visits are not fully implemented yet.');
                return;
              }

              return handlePatientSeenSubmit(event);
            }}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3'
              >
                <div className='row'>
                  <div className='col-4'>
                    <h5>Patient Info</h5>
                  </div>
                  <div className='col-8'>
                    <h5>Appointment Details</h5>
                  </div>
                </div>
                <hr style={{ margin: '0.5rem 0' }} />
                <div className='row'>
                  <div className='col-4'>
                    <p>
                      <b>
                        <AwesomeLabel
                          label={`${patientFirstName} ${patientLastName}`}
                          icon='user'
                        />
                      </b>
                    </p>
                  </div>
                  <div className='col-8'>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='calendar-days'
                        title='Appointment Date'
                        label={formatDateToDefault(
                          getTimezoneSpecificDateString(
                            currentEventEditing.startTime,
                            currentLocation.timeZone
                          )
                        )}
                      />
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='cake-candles'
                        title='DOB'
                        label={formatDateToDefault(dob)}
                      />
                    </p>
                  </div>
                  <div style={{ padding: '0 15px' }}>
                    <p>
                      <AwesomeLabel
                        size='duotone'
                        icon='clock'
                        title='Start Time'
                        label={' '}
                      />
                    </p>
                  </div>
                  <div>
                    <Field
                      name='checkInStartTime'
                      component={TimePickerField}
                      min={getAppointmentMinTime(
                        currentEventEditing.startTime,
                        currentLocation.timeZone
                      )}
                      max={getAppointmentMaxTime(
                        currentEventEditing.endTime,
                        currentLocation.timeZone
                      )}
                      nowButton
                      validator={
                        checkAppointmentForm.checkInStartTime.inputValidator
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-4'>
                    <p>
                      <Field
                        name='verifiedDOB'
                        label='Verified DOB'
                        required
                        component={Checkbox}
                        value={verifiedDOB}
                        checked={verifiedDOB}
                        onChange={(e) => setVerifiedDOB(e.value)}
                      />
                    </p>
                  </div>
                  <div className='col-8'>
                    <div className='row'>
                      <div className='col-12'>
                        <div>
                          <p>
                            <AwesomeLabel
                              icon='file-contract'
                              title='Billing Req'
                              label={override || 'LOCATION or PROVIDER'}
                            />
                          </p>
                        </div>
                      </div>
                      <div style={{ display: 'contents' }}>
                        <div style={{ padding: '0 15px' }}>
                          <p>
                            <AwesomeLabel
                              size='duotone'
                              icon='user-doctor-hair'
                              title='Provider'
                              label=' '
                            />
                          </p>
                        </div>
                        <div style={{ flex: '50%' }}>
                          <Field
                            component={DropDownListField}
                            data={providers}
                            textField='text'
                            valueField='value'
                            name='providerId'
                            width='100%'
                            onChange={(e) => setOverrideProvider(e.value.text)}
                            validator={
                              checkAppointmentForm.providerId.inputValidator
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {overrideProvider && (
                      <div className='row'>
                        <div className='col-12'>
                          <p>
                            <AwesomeLabel
                              icon='file-medical'
                              title={getProviderTitle(override)}
                              label={overrideProvider}
                            />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {isApretude ? (
                  <div className='row'>
                    <div className='col-12'>
                      <p>
                        <Field
                          name='negativeInfectionConfirmation'
                          label='Negative Infection Status Confirmed'
                          required
                          component={Checkbox}
                          value={negativeStatusConfirmed}
                          checked={negativeStatusConfirmed}
                          onChange={(e) => setNegativeStatusConfirmed(e.value)}
                        />
                      </p>
                    </div>
                  </div>
                ) : null}
                <hr />
                <div className='row mt-2'>
                  <div className='col-12'>
                    <p>
                      <AwesomeLabel
                        label=' '
                        title='Appointment Notes'
                        icon='notes-medical'
                        size='duotone'
                      />
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <Field
                      name='appointmentNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      defaultValue={newAppointmentNotes}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      type='submit'
                      className='btn blue'
                      loading={loading}
                      label={
                        isCurrentEventLabDraw ? 'Start Lab Draw' : 'Submit'
                      }
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}

      {showPatientRescheduleDialog && (
        <Dialog
          id='dialogPatientReschedule'
          title={
            statusIsPending
              ? 'Reschedule Pending'
              : 'Patient Reschedule (Follow-Up)'
          }
          height={600}
          width={700}
          showDialog
          onClose={handlePatientRescheduleClose}
        >
          <Form
            id='formPatientReschedule'
            validator={endTimeGreaterThanStartTimeValidator}
            onSubmit={handlePatientRescheduleSubmit}
            initialValues={{
              locationId: currentLocation
            }}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3 k-form-red'
              >
                <div className='row'>
                  <div className='col-md-2'>Patient:</div>
                  <div className='col-md-4 p-0'>
                    {selectedPatientInfo.patientFirstName}{' '}
                    {selectedPatientInfo.patientLastName}
                    &nbsp;:&nbsp;
                    {selectedPatientInfo.patientId}
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col'>
                    <Field
                      name='locationId'
                      label='AIC Location'
                      component={() => (
                        <AICLocationDropdown
                          data={aicActiveLocationsOnly}
                          value={currentLocation}
                          style={{ width: '220px' }}
                          onChange={(selected) =>
                            handleLocationTreeChange(selected, formRenderProps)
                          }
                        />
                      )}
                      validator={checkAppointmentForm.locationId.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col'>
                    <Field
                      name='reasonPatientNotSeen'
                      data={cancelationReasons}
                      layout='horizontal'
                      label='Reason'
                      component={DropDownListField}
                      validator={
                        checkAppointmentForm.reasonPatientNotSeen.inputValidator
                      }
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col'>
                    Reschedule (Follow-Up) Date
                    <Field
                      name='startDate'
                      component={DatePickerField}
                      validator={checkAppointmentForm.startDate.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Start Time:</div>
                  <div className='col-3'>
                    <Field
                      name='startTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={checkAppointmentForm.startTime.inputValidator}
                    />
                  </div>
                  <div className='col-md-2'>End Time:</div>
                  <div className='col-3'>
                    <Field
                      name='endTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={checkAppointmentForm.endTime.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Chair:</div>
                  <div className='col-md-3 p-0 mb-2'>
                    <Field
                      name='chairId'
                      component={DropDownListField}
                      data={chairsData}
                      validator={checkAppointmentForm.chairId.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <Field
                      name='reschedulingNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                      // defaultValue={reschedulingNotes}
                      // onChange={(e) => setAppointmentNotes(e.value)}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      type='submit'
                      className='btn blue'
                      loading={loading}
                      label='Submit'
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}

      {showPatientNotSeenDialog && (
        <Dialog
          id='dialogPatientNotSeen'
          title={
            isPending ? 'Cancel Pending Appointment' : 'Cancel Appointment'
          }
          height='auto'
          width={500}
          showDialog
          onClose={handlePatientNotSeenClose}
        >
          <Form
            id='formPatientNotSeen'
            onSubmit={handleCancelSubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3 k-form-red'
              >
                <div className='row'>
                  <div className='col mb-2'>
                    <Field
                      name='reasonPatientNotSeen'
                      data={cancelationReasons}
                      layout='horizontal'
                      label='Reason'
                      component={DropDownListField}
                      validator={
                        checkAppointmentForm.reasonPatientNotSeen.inputValidator
                      }
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <Field
                      name='reschedulingNotes'
                      rows={4}
                      autoSize
                      component={TextArea}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      label='Submit'
                      type='submit'
                      className='btn blue'
                      loading={loading}
                    />
                    {isPending && (
                      <Field
                        className='ml-3'
                        name='cancelAllPending'
                        label='Cancel All'
                        component={Checkbox}
                      />
                    )}
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showAllDayNoteCancel && (
        <Dialog
          id='dialogPatientNotSeen'
          title='Delete All Day Note'
          height={200}
          width={310}
          showDialog
          onClose={handlePatientNotSeenClose}
        >
          <Form
            id='formPatientNotSeen'
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3 k-form-red'
              >
                <div className='row'>
                  <div className='col' />
                </div>
                <div className='row'>
                  <div className='col' />
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      onClick={handlePatientNotSeenSubmit}
                      className='btn blue'
                      label='Delete All Day Note'
                      loading={loading}
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showNewNoteDialog && (
        <Dialog
          id='dialogAddNote'
          title='Add All-Day Note'
          height='auto'
          width={500}
          showDialog
          onClose={handleNewNoteClose}
        >
          <Form
            id='formAddNote'
            onSubmit={handleNewAllDayNote}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row mt-10'>
                  <div className='col-3'>Date:</div>
                  <div className='col-5'>
                    <Field
                      name='noteDate'
                      label='Note Date'
                      component={DatePickerField}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Title:</div>
                  <div className='col-md-8 p-0'>
                    <Field name='noteTitle' component={Input} />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      type='submit'
                      title='Add Note'
                      loading={loading}
                      label='Add Note'
                    />
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}

      {showNoteDialog && (
        <Dialog
          id='dialogNote'
          title='All-Day Note'
          height={440}
          width={500}
          showDialog
          onClose={handleNoteClose}
        >
          <Form
            id='formNote'
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row mt-10'>
                  <div className='col-3'>Date:</div>
                  <div className='col-5'>
                    <Field
                      name='noteDate'
                      label='Note Date'
                      component={DatePickerField}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-12'>
                    <TextArea
                      value={allDayNotes}
                      id='allDayNote'
                      rows={4}
                      autoSize
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <Button type='submit'>Edit Note</Button>
                  </div>
                  <div className='col my-3 d-flex justify-content-center'>
                    <Button type='submit' className='btn red'>
                      Delete Note
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </Dialog>
      )}

      {showPatientDocsDialog && (
        <Dialog
          title='Patient Documents3'
          style={{ backgroundColor: '#fff', minHeight: '300px' }}
          initialHeight={500}
          initialTop={1}
          initialLeft={1}
          width={1000}
          showDialog={showPatientDocsDialog}
          onClose={togglePatientDocsDialog}
        >
          <div className='row'>
            <div className='col-md-6 patient-document'>
              <div className='row'>
                <div className='col-md-12'>
                  <Grid
                    editField='inEdit'
                    selectedField='selected'
                    style={{ height: '400px' }}
                    // data={(selectedPatientInfo && selectedPatientInfo.patientDocuments) || []}
                  >
                    <Column
                      field='documentType'
                      title='Document Type'
                      width='150px'
                    />
                    <Column
                      field='date'
                      title='Date'
                      width='120px'
                      cell={docDateCell}
                    />
                    <Column
                      field='documentPath'
                      cell={hyperLinkCell}
                      title='Document'
                      sortable={false}
                    />
                  </Grid>
                </div>
              </div>
            </div>
            <div className='col-md-6 patient-document'>
              <PatientDocument file={selectedDocumentUrl} />
            </div>
          </div>
        </Dialog>
      )}

      {showNewAppointmentDialog && (
        <Dialog
          id='dialogNewAppointment'
          title='New Appointment'
          width={800}
          height={560}
          showDialog
          onClose={handleNewAppointmentClickNew}
        >
          <Form
            id='formNewAppointment'
            validator={endTimeNotFifteenPastStartTimeValidator}
            onSubmit={handleNewAppointmentSubmit}
            initialValues={{
              locationId: currentLocation,
              referralId: currentReferralOrder,
              newAppointmentNotes: currentReferralOrder?.referralOrder?.notes
            }}
            key={JSON.stringify({
              locationId: currentLocation,
              referralId: currentReferralOrder,
              newAppointmentNotes: currentReferralOrder?.referralOrder?.notes
            })}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-md-2'>Patient:</div>
                  <div className='col-md-4 p-0'>
                    {selectedPatientInfo.patientFirstName}{' '}
                    {selectedPatientInfo.patientLastName}
                    &nbsp;:&nbsp;
                    {selectedPatientInfo.patientId}
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col-md-2'>Referral ID:</div>
                  <div className='col-md-4 p-0'>
                    <Field
                      name='referralId'
                      component={DropDownListField}
                      data={listReferralOrdersData}
                      textField='text'
                      valueField='value'
                      validator={newAppointmentForm.referralId.inputValidator}
                      onChange={(event) =>
                        setCurrentReferralOrder(event.target.value)
                      }
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>AIC Location</div>
                  <div className='col-md-4 p-0'>
                    <Field
                      name='locationId'
                      component={() => (
                        <AICLocationDropdown
                          data={aicActiveLocationsOnly}
                          value={currentLocation}
                          style={{ width: '220px' }}
                          onChange={(selected) =>
                            handleLocationTreeChange(selected, formRenderProps)
                          }
                        />
                      )}
                      validator={newAppointmentForm.locationId.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Title:</div>
                  <div className='col-md-6 p-0'>
                    <Field
                      name='appointmentTitle'
                      component={Input}
                      placeholder='Infusion Appointment'
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Date:</div>
                  <div className='col-md-3 p-0'>
                    <Field
                      name='startDate'
                      component={DatePickerField}
                      validator={newAppointmentForm.startDate.inputValidator}
                      onChange={(e) => setSelectedEventDate(e.value)}
                    />
                  </div>
                  <div className='col-md-7'></div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Start Time:</div>

                  <div className='col-md-3 p-0'>
                    <Field
                      name='startTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={newAppointmentForm.startTime.inputValidator}
                    />
                  </div>
                  <div className='col-md-2'>End Time:</div>

                  <div className='col-md-3 p-0'>
                    <Field
                      name='endTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={newAppointmentForm.endTime.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Chair:</div>
                  <div className='col-md-2 p-0'>
                    <Field
                      name='chairId'
                      component={DropDownListField}
                      data={chairsData}
                      validator={newAppointmentForm.chairId.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Notes:</div>
                  <div className='col-md-10 p-0'>
                    <Field
                      name='newAppointmentNotes'
                      style={{ width: '100%', minHeight: '100px' }}
                      autoSize
                      component={TextArea}
                    />
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      type='submit'
                      className='btn blue'
                      loading={loading}
                      label='Book Appointment'
                    />
                  </div>
                  {pathsNotEqual && (
                    <div className='col my-3 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn'
                        onClick={handleDetailsClick}
                        label='Patient Details'
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showPendingAppointmentDialog && (
        <Dialog
          id='dialogPendingAppointment'
          title='Pending Appointment'
          width={800}
          height={560}
          showDialog
          onClose={handlePendingAppointmentClick}
        >
          <Form
            id='formPendingAppointment'
            validator={endTimeNotFifteenPastStartTimeValidator}
            onSubmit={handlePendingAppointmentSubmit}
            initialValues={{
              locationId: currentLocation
            }}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-1'
              >
                <div className='row'>
                  <div className='col-md-2'>Patient:</div>
                  <div className='col-md-4 p-0'>
                    {selectedPatientInfo.patientFirstName}{' '}
                    {selectedPatientInfo.patientLastName}
                    &nbsp;:&nbsp;
                    {selectedPatientInfo.patientId}
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col-md-2'>Drug Name:</div>
                  <div className='col-md-4 p-0'>
                    <Field
                      name='product'
                      component={DropDownList}
                      data={listProductsDataFiltered}
                      textField='productOnSelector'
                      valueField='productId'
                      validator={pendingAppointmentForm.product.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>AIC Location</div>
                  <div className='col-md-4 p-0'>
                    {false && (
                      <Field
                        name='locationId'
                        component={DropDownListField}
                        valueField='selectorValue'
                        textField='selectorText'
                        data={aicActiveLocationsOnly}
                        onChange={(event) => {
                          const locationData = getLocation(event.value.id);
                          setCurrentLocation(locationData);

                          formRenderProps.onChange('locationId', {
                            value: event.value
                          });
                          formRenderProps.onChange('chairId', {
                            value: null
                          });
                        }}
                        validator={
                          pendingAppointmentForm.locationId.inputValidator
                        }
                        required
                      />
                    )}
                    <Field
                      name='locationId'
                      component={() => (
                        <AICLocationDropdown
                          data={aicActiveLocationsOnly}
                          value={currentLocation}
                          style={{ width: '220px' }}
                          onChange={(selected) =>
                            handleLocationTreeChange(selected, formRenderProps)
                          }
                        />
                      )}
                      validator={
                        pendingAppointmentForm.locationId.inputValidator
                      }
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Title:</div>
                  <div className='col-md-6 p-0'>
                    <Field
                      name='appointmentTitle'
                      component={Input}
                      placeholder='Infusion Appointment'
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Date:</div>
                  <div className='col-md-3 p-0'>
                    <Field
                      name='startDate'
                      component={DatePickerField}
                      validator={
                        pendingAppointmentForm.startDate.inputValidator
                      }
                      onChange={(e) => setSelectedEventDate(e.value)}
                    />
                  </div>
                  <div className='col-md-7'>
                    {/* {adminStageForEvent?.targetInjectionInfo && (
                      <div className="col-md-7">
                        <TargetInjectionDates
                          targetInjectionInfo={
                            adminStageForEvent.targetInjectionInfo
                          }
                          locationTimezone={currentLocation.timeZone}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Start Time:</div>

                  <div className='col-md-3 p-0'>
                    <Field
                      name='startTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={
                        pendingAppointmentForm.startTime.inputValidator
                      }
                    />
                  </div>
                  <div className='col-md-2'>End Time:</div>

                  <div className='col-md-3 p-0'>
                    <Field
                      name='endTime'
                      component={TimePickerField}
                      steps={steps}
                      min={getAppointmentMinTime()}
                      max={getAppointmentMaxTime()}
                      validator={pendingAppointmentForm.endTime.inputValidator}
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Chair:</div>
                  <div className='col-md-2 p-0'>
                    <Field
                      name='chairId'
                      component={DropDownListField}
                      data={chairsData}
                      validator={pendingAppointmentForm.chairId.inputValidator}
                      required
                    />
                  </div>
                </div>
                <div className='row mt-10'>
                  <div className='col-md-2'>Notes:</div>
                  <div className='col-md-10 p-0'>
                    <Field
                      name='newAppointmentNotes'
                      style={{ width: '100%', minHeight: '100px' }}
                      autoSize
                      component={TextArea}
                    />
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <FormButton
                      type='submit'
                      className='btn blue'
                      loading={loading}
                      label='Book Appointment'
                    />
                  </div>
                  {pathsNotEqual && (
                    <div className='col my-3 d-flex justify-content-center'>
                      <FormButton
                        type='button'
                        className='btn'
                        onClick={handleDetailsClick}
                        label='Patient Details'
                      />
                    </div>
                  )}
                </div>
              </form>
            )}
          />
        </Dialog>
      )}
      {showFinshLabDraw && (
        <LabDrawModal
          handleShowFinshLabDraw={handleShowFinshLabDraw}
          labDrawInfo={labDrawStartingInfo}
        />
      )}
      {showNewTelehealthModal && (
        <TelehealthVisitModal
          handleLocationTreeChange={handleLocationTreeChange}
          handleDetailsClick={handleDetailsClick}
          newAppointmentForm={newAppointmentForm}
          pathsNotEqual={pathsNotEqual}
          createScheduleEventCall={createScheduleEventCall}
          addUpdateNotesCall={addUpdateNotesCall}
        />
      )}
      {showOfficeVisitModal && (
        <OfficeVisitModal
          handleLocationTreeChange={handleLocationTreeChange}
          handleDetailsClick={handleDetailsClick}
          newAppointmentForm={newAppointmentForm}
          checkAppointmentForm={checkAppointmentForm}
          pathsNotEqual={pathsNotEqual}
          createScheduleEventCall={createScheduleEventCall}
          addUpdateNotesCall={addUpdateNotesCall}
        />
      )}
    </>
  );
};

export default withRouter(SchedulingCalendarMain);
