import React, { useState, useEffect } from 'react';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';

// components
import AwesomeLink from '@/components/common-components/AwesomeLink';
import OrderHistoryOrderDetails from './OrderHistoryOrderDetails';
import { ReferralIdsDisplay } from '@/components/common-components/ReferralIdsDisplay';

// context
import { SurveyContext } from '@/context/SurveyContext';

// utils
import { getSurveyModel } from '../SurveyEngine/models/surveyModelMapper';
import { DISCONTINUATION_SURVEY } from '../SurveyEngine/surveyDictionary';

const OrderHistoryDiscTab = ({ orders }) => {
  const { setActiveSurvey, setSurveyResults } = React.useContext(SurveyContext);

  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrderInfo, setSelectedOrderInfo] = useState({});

  const [itemAdministrations, setItemAdministrations] = useState([]);
  const [itemPreMeds, setItemPreMeds] = useState([]);

  useEffect(() => {
    if (selectedOrderInfo.archivedReferral) {
      setItemAdministrations(
        selectedOrderInfo.archivedReferral.referralOrder.administrations.map(
          (item) => ({
            ...item,
            text: item.orderName,
            value: item.orderName
          })
        )
      );

      setItemPreMeds(
        selectedOrderInfo.archivedReferral.referralOrder.preMedications.map(
          (item) => ({
            ...item,
            text: item.orderName,
            value: item.orderName
          })
        )
      );
    }
  }, [selectedOrderInfo]);

  const orderNameCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem?.orderName}
        <ReferralIdsDisplay
          referralId={dataItem?.archivedReferral?.referralId}
          referralUUID={dataItem?.archivedReferral?.referralUUID}
          grid
        />
      </td>
    );
  };

  const handleOnRowClick = (dataItem) => {
    if (dataItem.orderName) {
      setSelectedOrderInfo({ ...dataItem });
      setShowOrderDetails(true);
    }
  };

  const dosageCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem.dosage} {dataItem.unitOfMeas}
      </td>
    );
  };

  const calcDosageCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem.calculated} {dataItem.unitOfMeas}
      </td>
    );
  };

  const actionsCell = ({ dataItem }) => {
    return (
      <td>
        <div>
          <AwesomeLink
            onClick={() => handleOnRowClick(dataItem)}
            icon='magnifying-glass'
            label='View Details'
          />
        </div>
        {surveyResultsCell({ dataItem })}
      </td>
    );
  };

  const surveyResultsCell = ({ dataItem }) => {
    const surveyData = dataItem.archivedReferral?.discontinuation?.survey;

    if (surveyData) {
      const jsonData = JSON.parse(surveyData);
      const version = jsonData.version || 1;
      const reasonCode = jsonData.reasonCode;
      const model = getSurveyModel(DISCONTINUATION_SURVEY, version);

      return (
        <div>
          <AwesomeLink
            onClick={() => {
              // set the survey model
              setActiveSurvey({
                model
              });

              // display results in readonly mode
              setSurveyResults(surveyData);
            }}
            icon='square-poll-vertical'
            label='Survey Results'
          />
          {reasonCode ? (
            <div>
              <span className='location-time-label'>
                Reason Code: <b>{reasonCode}</b>
              </span>
            </div>
          ) : null}
        </div>
      );
    }

    return <div>No survey results</div>;
  };

  return (
    <>
      <div className='col-md-12'>
        <div className='row pt-3' style={{ minHeight: '650px' }}>
          <div className='col'>
            <Grid data={orders}>
              <Column title='ORDER NAME' cell={orderNameCell} width={'300px'} />
              <Column field='dosage' title='DOSAGE' cell={dosageCell} />
              <Column
                field='calculated'
                title='CALC DOSAGE'
                cell={calcDosageCell}
              />
              <Column field='orderDate' title='ORDER DATE' />
              <Column field='archivedDate' title='DISC DATE' />
              <Column field='expirationDate' title='EXP DATE' />
              <Column field='agentId' title='AGENT' />
              <Column cell={actionsCell} />
            </Grid>
          </div>
        </div>
      </div>
      {showOrderDetails && (
        <OrderHistoryOrderDetails
          selectedOrderInfo={{
            ...selectedOrderInfo,
            itemAdministrations,
            itemPreMeds
          }}
          onClose={() => setShowOrderDetails(false)}
        />
      )}
    </>
  );
};

export default OrderHistoryDiscTab;
