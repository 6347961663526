import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';

// Kendo Components
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

// components
import { MessageDialog } from '@/components/common-components/MessageDialog';
import IconLabel from '@/components/common-components/IconLabel';
import GridRecord from '@/components/common-components/GridRecordWithFooter';
import AwesomeLabel from '@/components/common-components/AwesomeLabel';
import { LogHeader, getAccessPointText } from './common';
import PanelMeta from './PanelMeta';
import RowField from './RowField';
import RowFieldOrder from './RowFieldOrder';
import TableRowPrint from './TableRowPrint';
import Assessments from './Assessments';

// Context
import { UserRoleTypes, LocationContext, TreatmentContext } from '@/context';

// Utils
import { formatDateToDefault, formatSecondsGently } from '@/common/DateHelper';
import { getDosageUOM } from '@/common/DoseHelper';
import { getCabenuvaDosageLabel } from '@/common/viivHelper';
import { getPatientWeightFromReferral } from '@/common/Converters';
import { findTabletSurveyByDate } from '@/components/Infusion/infusionHelper';
import { getInventorySource, parsePrepDosage } from './TreatmentHistory.utils';
import { orderTypes } from '@/constants/dict';

const TreatmentHistoryDialog = ({
  adminSeqNumber,
  canEdit,
  hideTreatmentHistoryDialog,
  dialogOption,
  jCodeData,
  selectedPatientInfo,
  pdfContainer,
  uploadToS3
}) => {
  const { getDateByLocation, getLocation, getTimeByLocation } =
    useContext(LocationContext);
  const { snapshotInfusion } = useContext(TreatmentContext);

  const staffPerson =
    snapshotInfusion?.infusionDetail?.stepCloseTreatment?.executedBy ||
    snapshotInfusion?.infusionDetail?.stepCheckIn?.agentId;

  const accessPoint =
    snapshotInfusion?.infusionDetail?.stepLabDraw?.accessPoint;

  const patientWeightKg = getPatientWeightFromReferral(
    snapshotInfusion?.referralOrder
  );

  const render = {
    doseCell: ({ dataItem }) => {
      if (dataItem.drugName === 'Cabenuva') {
        return <td>{getCabenuvaDosageLabel(dataItem.approvedDosage)}</td>;
      }
      return (
        <td>
          {dataItem.approvedDosage} {dataItem.unitOfMeas}
        </td>
      );
    },
    calcDoseCell: ({ dataItem }) => {
      if (dataItem.drugName === 'Cabenuva') {
        return <td>{getCabenuvaDosageLabel(dataItem.dose)}</td>;
      }
      return (
        <td>
          {dataItem.calcDosage} {getDosageUOM(dataItem.unitOfMeas)}
        </td>
      );
    },
    patientSurveyNotes: () => {
      const surveyData = findTabletSurveyByDate(
        selectedPatientInfo?.surveys,
        snapshotInfusion?.appoinmentTime
      );
      if (!surveyData) return null;

      const surveyResults = JSON.parse(surveyData.survey);

      if (!surveyResults.notes) return null;

      return (
        <>
          <div className='line'>
            <hr />
          </div>
          <div className='mt-3 ml-0'>
            <b>KEY PATIENT INSIGHTS:</b>
          </div>
          <div className='notesWrapper'>
            {surveyResults.notes.replace(/[\r\n]/g, '; ')}
          </div>
        </>
      );
    },
    renderProvider: () => {
      let provider = `${snapshotInfusion?.providerFirstName} ${snapshotInfusion?.providerLastName} (NPI: ${snapshotInfusion?.providerNPI})`;

      if (
        snapshotInfusion?.updatedProvider?.providerNPI &&
        snapshotInfusion?.updatedProvider?.providerNPI !==
          snapshotInfusion?.providerNPI
      ) {
        provider = `${snapshotInfusion.updatedProvider.providerFirstName} ${snapshotInfusion.updatedProvider.providerLastName} (NPI: ${snapshotInfusion.updatedProvider.providerNPI})`;
      }

      return provider;
    }
  };

  const getOriginalDosage = () => {
    if (
      snapshotInfusion?.referralOrder?.administrations &&
      adminSeqNumber > 0
    ) {
      const treatAdmin = snapshotInfusion?.referralOrder.administrations.find(
        (adm) => adm.adminSequenceNumber === adminSeqNumber
      );

      if (treatAdmin) {
        const uom = getDosageUOM(treatAdmin.unitOfMeas);

        return treatAdmin ? `${treatAdmin.calcDosage} ${uom}` : 'n/a';
      }
    }
  };

  const mapArrivalVitals = (vital) => {
    const time = getLocationTime(vital.enteredAt);

    return (
      <tr>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{vital.temperature || '-'}</td>
        <td>{vital.bloodPressure || '-'}</td>
        <td>{vital.heartRate || '-'}</td>
        <td>{vital.R || '-'}</td>
        <td>{vital.SP02 || '-'}</td>
        <td>{vital.initials || '-'}</td>
      </tr>
    );
  };
  const mapPremedications = (drug) => {
    const time = getLocationTime(drug.time);

    return (
      <tr>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{drug.medication || '-'}</td>
        <td>
          {drug.dosing} {drug.unitOfDosage}
        </td>
        <td>{drug.administeredLocation || '-'}</td>
        <td>{drug.lot || '-'}</td>
        <td>{formatDateToDefault(drug.expiration) || '-'}</td>
        <td>{drug.initials || '-'}</td>
      </tr>
    );
  };
  const mapPiv = (piv) => {
    const pivType = piv.ivInsertion ? 'IV Insertion' : 'IV Discontinued';
    const ivTime = piv.ivInsertion || piv.ivDiscontinuedTime;
    const dateTime = getLocationTime(ivTime);

    return (
      <tr>
        <td>{renderTimeWithLabel(dateTime)}</td>
        <td>{pivType}</td>
        <td>{piv.attempt || '-'}</td>
        <td>{piv.catheter || '-'}</td>
        <td>{piv.location || '-'}</td>
        <td>{!piv.entered ? piv.entered : 'Yes' || 'No'}</td>
        <td>{piv.initials || '-'}</td>
      </tr>
    );
  };

  const mapPicc = (picc) => {
    const time = getLocationTime(picc.portLocal);

    return (
      <tr>
        <td>{picc.portLocal || '-'}</td>
        <td>{picc.lumens || '-'}</td>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{picc.armCircle || '-'}</td>
        <td>{picc.externalLength || '-'}</td>
        <td>{!picc.bloodReturned ? picc.bloodReturned : 'Yes' || 'No'}</td>
        {/* if not null display yes */}
        <td>{!picc.flushed ? picc.flushed : 'Yes' || 'No'}</td>
        {/* if not null display yes */}
        <td>{picc.time || '-'}</td>
        <td>{picc.initials || '-'}</td>
      </tr>
    );
  };

  const mapPort = (port) => {
    const time = getLocationTime(port.deaccessedPort);

    return (
      <tr>
        <td>{port.portLocal || '-'}</td>
        <td>{port.needleLength || '-'}</td>
        <td>{port.needleSize || '-'}</td>
        <td>{port.accessDate || '-'}</td>
        <td>{!port.bloodReturned ? port.bloodReturned : 'Yes' || 'No'}</td>
        {/* if not null display yes */}
        <td>{!port.flushed ? port.flushed : 'Yes' || 'No'}</td>
        {/* if not null display yes */}
        <td>{renderTimeWithLabel(time)}</td>
        <td>{port.details || '-'}</td>
        <td>{port.initials || '-'}</td>
      </tr>
    );
  };

  const mapMedication = (drug) => {
    return (
      <tr>
        <td>
          {drug.vial} ({drug.ndc})
        </td>
        <td>{drug.quantity || '-'}</td>
        <td>{drug.lot || '-'}</td>
        <td>{formatDateToDefault(drug.expiration) || '-'}</td>
      </tr>
    );
  };

  const mapAdminVitals = (vital) => {
    const time = getLocationTime(vital.enteredAt);

    return (
      <tr>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{vital.temperature || '-'}</td>
        <td>{vital.bloodPressure || '-'}</td>
        <td>{vital.heartRate || '-'}</td>
        <td>{vital.R || '-'}</td>
        <td>{vital.SP02 || '-'}</td>
        <td>{vital.initials || '-'}</td>
      </tr>
    );
  };

  const mapAdminIv = (iv) => {
    const total =
      iv.event === 'Stop' ? formatSecondsGently(iv.totalInfusionInSec) : '-';

    const time = getLocationTime(iv.time);
    return (
      <tr>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{iv.event || '-'}</td>
        <td>{iv.rate || '-'}</td>
        <td>{iv.visualNotes || '-'}</td>
        <td>{iv.initials || '-'}</td>
        <td>{total}</td>
      </tr>
    );
  };

  const mapAdminImSubq = (im) => {
    const time = getLocationTime(im.time);

    return (
      <tr>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{im.location || '-'}</td>
        <td>{im.amount || '-'}</td>
        <td>{im.visualNotes || '-'}</td>
        <td>{im.initials || '-'}</td>
      </tr>
    );
  };

  const mapLineFlush = (lineFlush) => {
    const time = getLocationTime(lineFlush.flushTime);

    return (
      <tr>
        <td>{lineFlush.flushType || '-'}</td>
        <td>{lineFlush.ivAccess || '-'}</td>
        <td>{renderTimeWithLabel(time)}</td>
        <td>{lineFlush.executedBy || '-'}</td>
      </tr>
    );
  };

  const renderNoData = () => (
    <div>
      <b>No Data Provided</b>
    </div>
  );

  const getLocationTime = (timeStamp) =>
    getTimeByLocation(snapshotInfusion?.locationId, timeStamp);

  // RENDER HELPERS
  // Step: Pre-Treatment
  const showPreTreatMeds =
    !!snapshotInfusion?.infusionDetail?.stepPreTreatment?.preMedications
      ?.length;

  const showPIV =
    !!snapshotInfusion?.infusionDetail?.stepPreTreatment?.piv?.length;

  const showPICC =
    !!snapshotInfusion?.infusionDetail?.stepPreTreatment?.picc?.length;

  const showPort =
    !!snapshotInfusion?.infusionDetail?.stepPreTreatment?.port?.length;

  const showLineFlush =
    !!snapshotInfusion?.infusionDetail?.stepPreTreatment?.lineFlush?.length;

  // Step: Prep
  const showPrepMedSelection =
    !!snapshotInfusion?.infusionDetail?.stepPreparation?.drugs?.length;

  const showPrepDiluents =
    !!snapshotInfusion?.infusionDetail?.stepPreparation?.diluent?.length;

  const showPrepReconstituted =
    !!snapshotInfusion?.infusionDetail?.stepPreparation?.reconstitutedIn
      ?.length;

  // Step: Administration
  const showAdminVitals =
    !!snapshotInfusion?.infusionDetail?.stepAdministration?.vitals?.length;

  const showAdminIV =
    !!snapshotInfusion?.infusionDetail?.stepAdministration?.ivDrugs?.length;

  const showAdminSUBQ =
    !!snapshotInfusion?.infusionDetail?.stepAdministration?.imDrugs?.length;

  // Step: Assesment
  const showPreMeds = !!snapshotInfusion?.referralOrder?.preMedications?.length;

  const showPatientWeight =
    !!snapshotInfusion?.infusionDetail?.stepAssessment?.patientWeights?.length;

  const showVitals =
    !!snapshotInfusion?.infusionDetail?.stepAssessment?.vitals?.length;

  const showAllergies =
    !!snapshotInfusion?.infusionDetail?.stepAssessment?.allergies?.length;

  const showProfileAllergies = Boolean(
    showAllergies === false &&
      !!selectedPatientInfo.patientProfile?.allergyInfo?.length
  );

  const showCurrMeds =
    !!selectedPatientInfo.patientProfile?.medicationInfo?.length;

  const renderTimeWithLabel = (timeString) => {
    const location = getLocation(snapshotInfusion?.locationId);
    const footerText = location ? `${location.selectorText} Time` : '';

    return <GridRecord mainText={timeString} footerText={footerText} />;
  };

  const showAdminIsEmpty = [showAdminVitals, showAdminIV, showAdminSUBQ].every(
    (el) => el === false
  );

  const showPreTreatmentIsEmpty = [
    showPreTreatMeds,
    showPIV,
    showPICC,
    showPort,
    showLineFlush
  ].every((el) => el === false);

  const prepDosage = parsePrepDosage(snapshotInfusion);
  const orderType = snapshotInfusion?.infusionDetail?.orderType
    ? orderTypes[snapshotInfusion.infusionDetail.orderType]
    : '-';

  return (
    <Dialog
      title='Treatment History'
      style={{
        minHeight: '300px',
        minWidth: '900px'
      }}
      initialHeight={800}
      width={1100}
      onClose={hideTreatmentHistoryDialog}
    >
      {dialogOption && dialogOption.showDialog && (
        <MessageDialog dialogOption={dialogOption} />
      )}
      <div className='col-md-12'>
        <Button
          disabled={!canEdit(UserRoleTypes.TreatmentHistory)}
          className='my-3'
          onClick={(e) => {
            const fileName = `patientDocs/${selectedPatientInfo.patientId}/${
              selectedPatientInfo?.patientFirstName
            }_${
              selectedPatientInfo?.patientLastName
            }_${new Date().getTime()}_TreatmentHistory.pdf`;

            e.preventDefault(); // @NOTE: it works like submit button if we don't prevent like this
            uploadToS3(fileName);
          }}
        >
          <IconLabel label='Save to Patient Docs' icon='upload' />
        </Button>
      </div>
      <div id='treatmentHistory' ref={pdfContainer}>
        <div
          className='col-md-12'
          style={{ background: '#fffffff !important' }}
        >
          <div className='fax-header'>
            <LogHeader />
          </div>
          <div className='line'>
            <hr />
          </div>
          <div className='col-md-12'>
            <div className='row'>
              <div className='col-md-3'>
                <RowField
                  label='Patient Id'
                  value={snapshotInfusion?.infusionDetail?.patientId}
                />
                <RowField
                  label='Patient'
                  value={`${snapshotInfusion?.infusionDetail?.patientFirstName} ${snapshotInfusion?.infusionDetail?.patientLastName}`}
                  capitalize
                />
                <RowField
                  label='DOB'
                  value={formatDateToDefault(selectedPatientInfo?.dob)}
                />
                <RowField
                  label='Gender'
                  value={selectedPatientInfo?.patientProfile?.patientInfo?.gender?.toProperCase()}
                />
              </div>
              <div className='col-md-3'>
                <RowField
                  label='Prescriber'
                  value={`${snapshotInfusion?.prescriberFirstName} ${snapshotInfusion?.prescriberLastName}`}
                  capitalize
                />
                <RowField
                  label='Prescr Ph'
                  value={snapshotInfusion?.prescriberPhoneNumber}
                />
                <RowField
                  label='Prescr Fax'
                  value={snapshotInfusion?.prescriberFax}
                />
                <RowField
                  label='Prescr NPI'
                  value={snapshotInfusion?.prescriberNPI}
                />
              </div>
              <div className='col-md-3'>
                <RowField
                  label='Appt Time'
                  value={getLocationTime(snapshotInfusion?.appoinmentTime)}
                />
                <RowField
                  label='Arrival Time'
                  value={getLocationTime(snapshotInfusion?.arrivalTime)}
                />
                <RowField
                  label='Depart Time'
                  value={getLocationTime(snapshotInfusion?.departTime)}
                />
                <RowField
                  label='Time In Office'
                  value={formatSecondsGently(
                    moment(snapshotInfusion?.departTime).diff(
                      moment(snapshotInfusion?.arrivalTime),
                      'seconds'
                    )
                  )}
                />
              </div>
              <div className='col-md-3'>
                <RowField
                  label='Supervising Provider'
                  value={render.renderProvider()}
                />
                <RowField
                  label='TN Last Updated'
                  value={
                    snapshotInfusion?.departTime
                      ? getDateByLocation(
                          snapshotInfusion?.locationId,
                          snapshotInfusion?.departTime
                        )
                      : '-'
                  }
                />
              </div>
            </div>
          </div>

          <div className='col-md-12' style={{ border: '1px solid #afaaaa' }}>
            <div className='row'>
              <div
                className='col-12 ml-0 pl-3 py-3 mr-0'
                style={{ background: '#dfdfdf' }}
              >
                <div className='row'>
                  <RowFieldOrder
                    label='Order Name'
                    value={snapshotInfusion?.infusionDetail?.orderName}
                  />
                  <RowFieldOrder label='Order Type' value={orderType} />
                  <RowFieldOrder
                    label='Medication Codes'
                    value={jCodeData || '-'}
                  />
                  <RowFieldOrder
                    label='Inventory Source'
                    value={getInventorySource(snapshotInfusion)}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12 mt-08'>
                <Grid
                  className='infusion-grid'
                  data={snapshotInfusion?.referralOrder?.administrations}
                >
                  <Column field='drugName' title='Product Name' />
                  <Column field='route' title='Route' />
                  <Column field='administer' title='Administer' />
                  <Column field='maxOfTreatments' title='Max #' />
                  <Column title='Dose' cell={render.doseCell} />
                  <Column title='Calc Dose' cell={render.calcDoseCell} />
                </Grid>
              </div>
            </div>

            {showPreMeds && (
              <div className='row'>
                <div className='col-md-12 mt-08'>
                  <Grid
                    className='infusion-grid'
                    data={snapshotInfusion?.referralOrder?.preMedications}
                  >
                    <Column field='drugName' title='Pre-Medications' />
                    <Column field='route' title='Route' width='80px' />
                    <Column field='administer' title='Administer' />
                    <Column field='maxOfTreatments' title='Max #' />
                    <Column title='Dose' cell={render.doseCell} />
                  </Grid>
                </div>
              </div>
            )}

            <div className='infusion-details col-12 ml-0 pl-3 py-3 mr-0'>
              <div>
                <div className='row'>
                  <div className='col-md-12 headerText mt-1'>
                    Order Date:&nbsp;
                    {formatDateToDefault(
                      snapshotInfusion?.referralOrder?.orderDate
                    )}
                  </div>
                  <div className='col-md-12 headerText mt-1'>
                    Order Expires:&nbsp;
                    {formatDateToDefault(
                      snapshotInfusion?.referralOrder?.orderExpires
                    )}
                  </div>
                  {patientWeightKg > 0 && (
                    <div className='col-md-12 headerText mt-1'>
                      Original Order Weight: {patientWeightKg} KG
                    </div>
                  )}
                  <div className='col-md-12 headerText mt-1'>
                    <strong>DX Codes:</strong>
                    <br />
                    {
                      snapshotInfusion?.referralOrder?.primaryDX
                        .primaryDiagnosis
                    }
                    {' - '}
                    {snapshotInfusion?.referralOrder?.primaryDX.description}
                  </div>
                  {snapshotInfusion?.referralOrder?.otherDX?.length > 0 && (
                    <>
                      <div className='col-md-12 headerText mt-1'>
                        {/* Other DX:{" "} */}
                        {snapshotInfusion?.referralOrder?.otherDX?.map(
                          (dx, i) => (
                            <div key={i}>
                              {dx.primaryDiagnosis}
                              {' - '}
                              {dx.description}
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                  <div className='col-12 headerText mt-1 notesWrapper'>
                    <strong>Notes:</strong> <br />
                    &nbsp;
                    {snapshotInfusion?.referralOrder?.notes}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='panelstyle'>
            <div className='row mt-3' />

            {/* Panel Bars */}

            <PanelBar className='mt-3'>
              <PanelBarItem
                title={
                  <PanelMeta
                    panelTitle='Assessment'
                    selectedPatientInfo={selectedPatientInfo}
                  />
                }
                expanded='yes'
              >
                <div
                  className='col-md-12 mt-3 ml-0 '
                  style={{
                    fontWeight: '400',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='line'>
                    <hr />
                  </div>
                  {showPatientWeight && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>Patient Weight</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>WEIGHT (LB)</th>
                            <th>WEIGHT (KG)</th>
                            <th>ENTERED</th>
                            <th>LAST KNOWN (KG)</th>
                            <th>CHANGE FROM LAST KNOWN</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepAssessment?.patientWeights.map(
                            (drug, index) => (
                              <tr key={index}>
                                <td>{`${drug.weightLB || '-'}`}</td>
                                <td>{`${drug.weight || '-'}`}</td>
                                <td>{`${drug.entered || '-'}`}</td>
                                <td>{`${drug.lastKnown || '-'}`}</td>
                                <td>{`${drug.changeFromLastKnown || '-'}`}</td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  {showVitals && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>Arrival Vitals</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>ENTERED AT</th>
                            <th>TEMP</th>
                            <th>BLOOD PRESSURE</th>
                            <th>HEART RATE</th>
                            <th>R</th>
                            <th>SP02</th>
                            <th>INITIALS</th>
                          </tr>
                          <tbody>
                            {snapshotInfusion?.infusionDetail?.stepAssessment?.vitals.map(
                              mapArrivalVitals
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {showAllergies && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>Allergies</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>ALLERGEN</th>
                            <th>ENTERED</th>
                            <th>REACTION</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepAssessment?.allergies.map(
                            (drug, index) => (
                              <tr key={index}>
                                {Object.keys(drug).map((item, i) => {
                                  if (item !== 'recordNumber') {
                                    return (
                                      <td key={i}>{`${drug[item] || '-'}`}</td>
                                    );
                                  }
                                  return null;
                                })}
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  {showProfileAllergies && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>Allergies</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>ALLERGEN</th>
                            <th>REACTION</th>
                            <th>ENTERED</th>
                          </tr>
                          {selectedPatientInfo.patientProfile.allergyInfo.map(
                            (allergy, index) => (
                              <tr key={index}>
                                <td>{`${allergy.allergen || '-'}`}</td>
                                <td>{`${allergy.reaction || '-'}`}</td>
                                <td>{`${allergy.entered || '-'}`}</td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  {showCurrMeds && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>Current Medications</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>MEDICATION</th>
                            <th>DOSAGE</th>
                            <th>FREQUENCY</th>
                            <th>START DATE</th>
                            <th>DISC. DATE</th>
                          </tr>
                          {selectedPatientInfo.patientProfile.medicationInfo.map(
                            (medication, index) => (
                              <tr key={index}>
                                <td>{`${medication.medication || '-'}`}</td>
                                <td>{`${medication.dosage || '-'}`}</td>
                                <td>{`${medication.frequency || '-'}`}</td>
                                <td>{`${medication.startDate || '-'}`}</td>
                                <td>{`${
                                  medication.discontinuationDate || '-'
                                }`}</td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  <Assessments />
                </div>
              </PanelBarItem>
              <PanelBarItem
                title={
                  <PanelMeta
                    panelTitle='Pre-Treatment'
                    selectedPatientInfo={selectedPatientInfo}
                  />
                }
                expanded='yes'
              >
                <div
                  className='col-md-12 mt-3 ml-0'
                  style={{
                    fontWeight: '400',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='line'>
                    <hr />
                  </div>
                  {showPreTreatMeds && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>PRE MEDICATIONS</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>TIME</th>
                            <th>MEDICATION</th>
                            <th>DOSING</th>
                            <th>ADMINISTER</th>
                            <th>LOT</th>
                            <th>EXPIRATION</th>
                            <th>INITIALS</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepPreTreatment?.preMedications.map(
                            mapPremedications
                          )}
                        </table>
                      </div>
                    </>
                  )}

                  {showPIV && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>PIV</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>TIME</th>
                            <th>TYPE</th>
                            <th>ATTEMPT</th>
                            <th>CATHETER</th>
                            <th>LOCATION</th>
                            <th>IV ENTERED</th>
                            <th>INITIALS</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepPreTreatment?.piv.map(
                            mapPiv
                          )}
                        </table>
                      </div>
                    </>
                  )}

                  {showPICC && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>PICC/CVC(0)</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>PORT LOCAL</th>
                            <th>LUMENS</th>
                            <th>DATE PLACED</th>
                            <th>ARM CIRC</th>
                            <th>EXT LENGTH</th>
                            <th>BLOOD RETURNED</th>
                            <th>FLUSHED</th>
                            <th>DRESSING CHANGED</th>
                            <th>INITIALS</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepPreTreatment?.picc.map(
                            mapPicc
                          )}
                        </table>
                      </div>
                    </>
                  )}

                  {showPort && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>PORT</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>PORT LOCAL</th>
                            <th>NEEDLE LENGTH</th>
                            <th>NEEDLE SIZE</th>
                            <th>ACCESS DATE</th>
                            <th>BLOOD RETURN</th>
                            <th>FLUSHED</th>
                            <th>DEACCESSED DATE</th>
                            <th>DETAILS</th>
                            <th>STAFF</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepPreTreatment?.port.map(
                            mapPort
                          )}
                        </table>
                      </div>
                    </>
                  )}

                  {showLineFlush && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>LINE FLUSH</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>TYPE</th>
                            <th>QTY</th>
                            <th>TIME</th>
                            <th>STAFF</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepPreTreatment?.lineFlush.map(
                            mapLineFlush
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  {showPreTreatmentIsEmpty && renderNoData()}
                </div>
              </PanelBarItem>
              <PanelBarItem
                title={
                  <PanelMeta
                    panelTitle='Prep'
                    selectedPatientInfo={selectedPatientInfo}
                  />
                }
                expanded='yes'
              >
                <div
                  className='col-md-12 mt-3 ml-0'
                  style={{
                    fontWeight: '400',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='line'>
                    <hr />
                  </div>
                  {/*  display if med are not administered Or NOT  */}
                  {!snapshotInfusion?.infusionDetail?.stepPreparation
                    ?.noMedsAdministrated && (
                    <>
                      {/* need to display if med are not administered do not show below */}
                      <div className='mt-3 ml-0'>
                        <b>MEDICATION ADMINISTERED</b>
                      </div>
                      <div className='col-md-12 mt-3'>
                        <table width='100%' cellPadding='5' border='0'>
                          <tr>
                            <TableRowPrint
                              label='ORDER'
                              value={
                                snapshotInfusion?.infusionDetail?.orderName
                              }
                            />
                            <TableRowPrint label='TYPE' value={orderType} />
                          </tr>
                          <tr>
                            <TableRowPrint
                              label='ORIGINAL ORDER DOSAGE'
                              value={getOriginalDosage()}
                            />
                            <TableRowPrint
                              label='CALCULATED ORDER DOSAGE'
                              value={prepDosage.calcDosage}
                            />
                          </tr>
                          <tr>
                            <TableRowPrint
                              label='ADJUSTED DOSAGE'
                              value={prepDosage.adjustedDosage}
                            />
                          </tr>
                          <tr>
                            <TableRowPrint
                              label='SELECTED QTY'
                              value={prepDosage.selectedQuantity}
                            />
                            <TableRowPrint
                              label='WASTAGE'
                              value={prepDosage.wastage}
                            />
                          </tr>
                        </table>
                      </div>
                      {showPrepMedSelection && (
                        <>
                          <div className='mt-3 ml-0'>
                            <b>MEDICATION SELECTION</b>
                          </div>
                          <div
                            className='infusionTable mt-2'
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            <table width='100%' cellPadding='8' border='0'>
                              <tr
                                style={{
                                  fontSize: '10px',
                                  backgroundColor: '#e2e1e1'
                                }}
                              >
                                <th>PRODUCT</th>
                                <th>QTY</th>
                                <th>LOT</th>
                                <th>EXP</th>
                              </tr>
                              {snapshotInfusion?.infusionDetail?.stepPreparation?.drugs.map(
                                mapMedication
                              )}
                            </table>
                          </div>
                        </>
                      )}

                      {showPrepDiluents && (
                        <>
                          <div className='mt-3 ml-0'>
                            <b>DILUTED IN</b>
                          </div>
                          <div
                            className='infusionTable mt-2'
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            <table width='100%' cellPadding='8' border='0'>
                              <tr
                                style={{
                                  fontSize: '10px',
                                  backgroundColor: '#e2e1e1'
                                }}
                              >
                                <th>PRODUCT</th>
                                <th>LOT</th>
                                <th>QTY</th>
                                <th>EXP</th>
                              </tr>
                              {snapshotInfusion?.infusionDetail?.stepPreparation?.diluent.map(
                                (drug, index) => (
                                  <tr key={index}>
                                    {Object.keys(drug).map((item, i) => {
                                      if (item !== 'recordNumber') {
                                        return (
                                          <td key={i}>
                                            {`${drug[item] || '-'}`}
                                          </td>
                                        );
                                      }
                                      return null;
                                    })}
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </>
                      )}

                      {showPrepReconstituted && (
                        <>
                          <div className='mt-3 ml-0'>
                            <b>RECONSTITUTED WITH</b>
                          </div>
                          <div
                            className='infusionTable mt-2'
                            style={{
                              fontSize: '12px',
                              backgroundColor: '#ffffff'
                            }}
                          >
                            <table width='100%' cellPadding='8' border='0'>
                              <tr
                                style={{
                                  fontSize: '10px',
                                  backgroundColor: '#e2e1e1'
                                }}
                              >
                                <th>EXP</th>
                                <th>PRODUCT</th>
                                <th>LOT</th>
                                <th>QTY</th>
                              </tr>
                              {snapshotInfusion?.infusionDetail?.stepPreparation?.reconstitutedIn.map(
                                (drug, index) => (
                                  <tr key={index}>
                                    {Object.keys(drug).map((item, i) => {
                                      if (item !== 'recordNumber') {
                                        return (
                                          <td key={i}>{`${
                                            drug[item] || '-'
                                          }`}</td>
                                        );
                                      }
                                      return null;
                                    })}
                                  </tr>
                                )
                              )}
                            </table>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {snapshotInfusion?.infusionDetail?.stepPreparation
                    ?.noMedsAdministrated && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>No Meds Administered</b>
                      </div>
                    </>
                  )}
                </div>
              </PanelBarItem>
              <PanelBarItem
                title={
                  <PanelMeta
                    panelTitle='Administration'
                    selectedPatientInfo={selectedPatientInfo}
                  />
                }
                expanded='yes'
              >
                <div
                  className='col-md-12 mt-3 ml-0'
                  style={{
                    fontWeight: '400',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='line'>
                    <hr />
                  </div>
                  {showAdminVitals && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>VITALS</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>ENTERED AT</th>
                            <th>TEMP</th>
                            <th>BLOOD PRESSURE</th>
                            <th>HEART RATE</th>
                            <th>R</th>
                            <th>SP02</th>
                            <th>INITIALS</th>
                          </tr>
                          <tbody>
                            {snapshotInfusion?.infusionDetail?.stepAdministration?.vitals?.map(
                              mapAdminVitals
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  {showAdminIV && (
                    <>
                      <div className='mt-3 ml-0'>
                        <b>IV</b>
                      </div>
                      <div
                        className='infusionTable mt-2'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>TIME</th>
                            <th>EVENT</th>
                            <th>RATE</th>
                            <th>VISUAL NOTE</th>
                            <th>INITIALS</th>
                            <th>TOTAL INF TIME</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepAdministration?.ivDrugs.map(
                            mapAdminIv
                          )}
                        </table>
                      </div>
                    </>
                  )}

                  {showAdminSUBQ && (
                    <>
                      <div className='mt-3 ml-0 mb-3'>
                        <b>IM / SUBQ</b>
                      </div>
                      <div
                        className='infusionTable mt-2 mb-3'
                        style={{
                          fontSize: '12px',
                          backgroundColor: '#ffffff'
                        }}
                      >
                        <table width='100%' cellPadding='8' border='0'>
                          <tr
                            style={{
                              fontSize: '10px',
                              backgroundColor: '#e2e1e1'
                            }}
                          >
                            <th>TIME</th>
                            <th>LOCATION</th>
                            <th>AMOUNT(MG)</th>
                            <th>VISUAL NOTE</th>
                            <th>INITIALS</th>
                          </tr>
                          {snapshotInfusion?.infusionDetail?.stepAdministration?.imDrugs.map(
                            mapAdminImSubq
                          )}
                        </table>
                      </div>
                    </>
                  )}
                  {showAdminIsEmpty && renderNoData()}
                </div>
              </PanelBarItem>
              <PanelBarItem
                title={
                  <PanelMeta
                    panelTitle='Close Treatment'
                    selectedPatientInfo={selectedPatientInfo}
                  />
                }
                expanded='yes'
              >
                <div
                  className='col-md-12 mt-3 ml-0'
                  style={{
                    fontWeight: '400',
                    backgroundColor: '#ffffff'
                  }}
                >
                  <div className='line'>
                    <hr />
                  </div>
                  <div className='mt-3 ml-0'>
                    <b>NARRATIVE NOTES:</b>
                  </div>
                  <div className='notesWrapper'>
                    {snapshotInfusion?.infusionDetail?.notes}
                  </div>
                  <div className='col-md-12 mt-3'>
                    <table width='100%' cellPadding='10' border='0'>
                      <tr>
                        <TableRowPrint
                          label='RENDERING PROVIDER'
                          value={staffPerson}
                        />
                        <TableRowPrint
                          label='DEPARTURE TIME'
                          value={getLocationTime(
                            snapshotInfusion?.infusionDetail?.stepCloseTreatment
                              ?.departureTime
                          )}
                        />
                      </tr>
                      <tr>
                        <TableRowPrint label='SIGNATURE' value={staffPerson} />
                        {accessPoint && (
                          <TableRowPrint
                            label='ACCESS POINT'
                            value={getAccessPointText(accessPoint)}
                          />
                        )}
                      </tr>
                    </table>
                  </div>
                  {render.patientSurveyNotes()}
                </div>
              </PanelBarItem>
            </PanelBar>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TreatmentHistoryDialog;
