import React, { useState, useEffect, useContext, useRef } from 'react';

// kendo
import { Input, Switch, TextArea } from '@progress/kendo-react-inputs';
import { Form, Field } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Typography } from '@progress/kendo-react-common';

// components
import { FormRadioGroup } from '@/components/common-components/FormRadioGroup';
import FormButton from '@/components/common-components/Form/FormButton';
import Badge from '@/components/common-components/Badge';
import PrescriberAddressesCurrent from '@/components/Prescriber/PrescriberAddressesCurrent';
import RequiredFormLabel from '@/components/common-components/RequiredFormLabel';
import { ReferralIdsDisplay } from '@/components/common-components/ReferralIdsDisplay';

// gql
import { connectToGraphqlAPI } from '@/provider';
import { getPatientReferralOrders } from '@/graphql/queries';
import { updateReferralOrder } from '@/graphql/mutations';

// context
import {
  UserContext,
  PatientContext,
  StatusContext,
  NotifContext,
  LogContext,
  UserRoleTypes,
  ReferralContext
} from '@/context';

// utils
import {
  DatePickerField,
  InputField,
  validateInput
} from '@/common/Validation';
import { isViiVProduct } from '@/common/viivHelper';
import { getPatientWeightFromReferral } from '@/common/Converters';
import { formatPrescriberAddress } from '@/components/Prescriber/prescriberHelper';
import {
  formatDateToAWS,
  formatDateToAWSDateTime,
  isoStringToDate
} from '@/common/DateHelper';
import {
  drugTypes,
  orderTypes,
  referralType
} from '@/components/Referral/ReferralMaps';
import { Constants } from '@/constants';
import { getItemAdmin } from './ReferralHelpers';

// partials
import ClinicalNotes from '../Patient/ClinicalNotes';
import ReferralLabTests from './ReferralLabTests';
import ReferralAdverseEvents from './ReferralAdverseEvents';
import DxUpdateForm from './DxUpdateForm';
import PatientAllergiesLog from '../Patient/PatientAllergiesLog';
import PatientMedsLog from '../Patient/PatientMedsLog';
import PatientLogInfo from '../Patient/PatientLogInfo';
import OrderPreMeds from './OrderPreMeds';
import DiscontinueOrder from './DiscontinueOrder';
import ArchiveOrder from './ArchiveOrder';
import OralLeadIn from './OralLeadIn';
import ArchiveCurrentOrderModal from './ArchiveCurrentOrderModal';
import AttachReferralDocument from './AttachDocument/AttachReferralDocument';
import ReferralAdmin from './ReferralAdmin';

const schedulingTypes = [
  { label: 'Standard', value: 'STANDARD' },
  { label: 'Priority', value: 'PRIORITY' },
  { label: 'Priority Antibiotic', value: 'PRIORITY_ANTIBIOTIC' }
];

const inventorySources = ['BUY_AND_BILL', 'FREE_DRUG'];

const CurrentOrder = ({ onChangeTab, onSelectArchiveOrder }) => {
  const { user, canEdit } = useContext(UserContext);
  const { logApiException } = useContext(LogContext);
  const {
    referralOrderUrl,
    originalOrderDate,
    setOriginalOrderDate,
    itemAdministrations,
    setItemAdministrations
  } = useContext(ReferralContext);

  const { selectedPatientInfo, setSelectedPatientInfo } =
    useContext(PatientContext);
  const { showError, showSuccess } = useContext(NotifContext);
  const { statusUpdate, setStatusUpdate } = useContext(StatusContext);

  const [listReferralOrdersData, setListReferralOrdersData] = useState([]);
  const [listPrescriberOfficeAddresses, setListPrescriberOfficeAddresses] =
    useState([]);
  const [listCardPrescriberAddresses, setListCardPrescriberAddresses] =
    useState([]);
  const [otherOrdersThatNeedSaving, setOtherOrdersThatNeedSaving] = useState(
    []
  );
  const [showLabDrawForm, setShowLabDrawForm] = useState(false);
  const [orderFormData, setOrderFormData] = useState({});
  const [referralFormData, setReferralFormData] = useState({});
  const [showReferralForm, setShowReferralForm] = useState(false);
  const [prescriberName, setPrescriberName] = useState();
  const [documentsData, setDocumentsData] = useState();
  const [labTestResultData, setLabTestResultData] = useState([]);
  const [adverseEventsData, setAdverseEventsData] = useState([]);
  const [visibleNotesDialog, setVisibleNotesDialog] = useState(false);
  const refFormSubmitted = useRef(false);
  const referralDetailsFormRef = useRef(null);
  const [startedTherapy, setStartedTherapy] = useState(false);
  const [isReferralApproved, setIsReferralApproved] = useState(false);
  const [itemPreMeds, setItemPreMeds] = useState([]);
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDxForm, setShowDxForm] = useState(false);
  const [rxOrderValue, setRxOrderValue] = useState('');
  const [reload, setReload] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const isVIIV = isViiVProduct(orderFormData?.drugId);

  const hasItemAdministrations = itemAdministrations.length !== 0;

  const toggleDxForm = () => setShowDxForm(!showDxForm);

  useEffect(() => {
    if (orderFormData && documentsData) {
      const documentValue = documentsData.filter(
        (items) => items.documentPath === orderFormData.rxOrderFile
      );
      setRxOrderValue(
        documentValue?.length > 0
          ? documentValue
          : [{ documentPath: orderFormData.rxOrderFile }]
      );
    }
  }, [orderFormData, documentsData]);

  useEffect(() => {
    setOriginalOrderDate();
  }, []);
  const handleSelect = (e) => {
    const idx = Number(e.selected);
    const order = listReferralOrdersData[idx];
    setSelected(idx);
    handleLoadOrder(order);
  };
  const handleArchiveClick = (e) => {
    setShowArchiveModal(true);
  };

  const preMedicationForm = {
    routePreMed: {
      value: orderFormData?.routePreMed || '',
      inputValidator: (value) => {
        return validateInput({
          routePreMed: { ...preMedicationForm.routePreMed, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    approvedDosagePreMed: {
      value: orderFormData?.approvedDosagePreMed || '',
      inputValidator: (value) => {
        return validateInput({
          approvedDosagePreMed: {
            ...preMedicationForm.approvedDosagePreMed,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    unitOfMeasPreMed: {
      value: orderFormData?.unitOfMeasPreMed || '',
      inputValidator: (value) => {
        return validateInput({
          unitOfMeasPreMed: { ...preMedicationForm.unitOfMeasPreMed, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    maxNumTreatmentsPreMed: {
      value: orderFormData?.maxNumTreatmentsPreMed || '',
      inputValidator: (value) => {
        return validateInput({
          maxNumTreatmentsPreMed: {
            ...preMedicationForm.maxNumTreatmentsPreMed,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const referralForm = {
    patientHasStartedTherapy: {
      value: orderFormData.patientHasStartedTherapy || false,
      inputValidator: (value) => {
        return validateInput({
          patientHasStartedTherapy: {
            ...referralForm.patientHasStartedTherapy,
            value
          }
        });
      },
      validations: []
    },
    noOfTreatments: {
      value: orderFormData.noOfTreatments || '',
      inputValidator: (value) => {
        return validateInput({
          noOfTreatments: { ...referralForm.noOfTreatments, value }
        });
      },
      validations: []
    },
    startTreatment: {
      value: isoStringToDate(orderFormData.firstTreatmentDate),
      inputValidator: (value) => {
        return validateInput({
          startTreatment: { ...referralForm.startTreatment, value }
        });
      },
      validations: []
    },
    lastTreatment: {
      value: isoStringToDate(orderFormData.lastTreatmentDate),
      inputValidator: (value) => {
        return validateInput({
          lastTreatment: { ...referralForm.lastTreatment, value }
        });
      },
      validations: []
    },
    inventorySource: {
      value: orderFormData.inventorySource || '',
      inputValidator: (value) => {
        return validateInput({
          inventorySource: { ...referralForm.inventorySource, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    drugType: {
      value: orderFormData.drugType || '',
      inputValidator: (value) => {
        return validateInput({
          drugType: { ...referralForm.drugType, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    scheduling: {
      value: orderFormData.scheduling || '',
      inputValidator: (value) => {
        return validateInput({
          scheduling: { ...referralForm.scheduling, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED // added required aic-2858
        }
      ]
    },
    specPharmName: {
      value: orderFormData.specialPharmName || '',
      inputValidator: (value) => {
        return validateInput({
          specPharmName: { ...referralForm.specPharmName, value }
        });
      },
      validations: []
    },
    specPharmPhone: {
      value: orderFormData.specialPharmPhoneNumber || '',
      inputValidator: (value) => {
        return validateInput({
          specPharmPhone: { ...referralForm.specPharmPhone, value }
        });
      },
      validations: []
    },
    isReferralApproved: {
      value: orderFormData.referralApproved || false,
      inputValidator: (value) => {
        return validateInput({
          isReferralApproved: { ...referralForm.isReferralApproved, value }
        });
      },
      validations: []
    },
    selectedPrescriberOfficeAddress: {
      value: orderFormData?.selectedPrescriberOfficeAddress || '',
      inputValidator: (value) => {
        return validateInput({
          selectedPrescriberOfficeAddress: {
            ...referralForm.selectedPrescriberOfficeAddress,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    },
    orderType: {
      value: orderFormData?.referralOrder?.orderType || '',
      inputValidator: (value) => {
        return validateInput({
          orderType: {
            ...referralForm.orderType,
            value
          }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.is_REQUIRED
        }
      ]
    }
  };

  const toggleNotes = () => {
    setVisibleNotesDialog(!visibleNotesDialog);
  };

  // MAIN INITIATOR
  useEffect(() => {
    getPatientReferralOrdersCall(selectedPatientInfo.patientId);
  }, []);

  const getPatientReferralOrdersCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: getPatientReferralOrders,
        variables: { patientId: requestObject } // patientId
      });
      if (data?.data?.getPatientBucket?.patientDocuments) {
        setDocumentsData(data.data.getPatientBucket.patientDocuments);
      }
      if (data?.data?.getPatientBucket?.referral?.drugReferrals?.length) {
        // sort tabe by drugName alphabetically
        const referrals =
          data.data.getPatientBucket.referral.drugReferrals.sort((a, b) =>
            a.drugName.localeCompare(b.drugName)
          );
        setListReferralOrdersData(referrals);

        const defaultReferral = referrals[0];
        const updatedPatientInfo = { ...selectedPatientInfo };
        updatedPatientInfo.referral.drugReferrals = referrals;
        setSelectedPatientInfo(updatedPatientInfo);
        // populate first order by default
        handleLoadOrder(defaultReferral);
      }
    } catch (err) {
      logApiException(err, {
        view: 'CurrentOrder',
        endpoint: 'getPatientReferralOrders',
        patientId: requestObject
      });
      showError('Error: getPatientReferralOrders');
    }
  };

  const formatReferralFormData = (order) => {
    const nameText = order?.rxOrderFile?.split('/');
    return {
      patientHasStartedTherapy: order.patientHasStartedTherapy || false,
      noOfTreatments: order.noOfTreatments || null,
      startTreatment: isoStringToDate(order.firstTreatmentDate),
      lastTreatment: isoStringToDate(order.lastTreatmentDate),
      inventorySource: order.inventorySource || '',
      scheduling: order.scheduling || '',
      specPharmName: order.specialPharmName || '',
      specPharmPhone: order.specialPharmPhoneNumber || '',
      isReferralApproved: order.referralApproved || false,
      reasonArchivingOrder: order.reasonForArchiving || '',
      isReadyToViewForm: true,
      referralApproved: order.referralApproved || false,
      headerNotes: order.referralOrder.notes || '',
      orderType: order.referralOrder.orderType || '',
      oralLeadInDate: isoStringToDate(
        order.orderNotes?.oralLeadIn?.dateOfLastDose
      ),
      firstInjectionDate: isoStringToDate(
        order.orderNotes?.oralLeadIn?.dateOfFirstInjection
      ),
      referralDocument: {
        expanded: false,
        receivedAt: rxOrderValue?.receivedAt,
        selected: null,
        text: nameText ? nameText[nameText?.length - 1] : [],
        path: order.rxOrderFile
      }
    };
  };

  useEffect(() => {
    // OOTNS -- OTHER ORDERS THAT NEED SAVING
    let ootns = listReferralOrdersData.filter(
      (referral) => referral.referralId !== orderFormData.referralId
    );

    ootns = ootns.map((item) => {
      if (item?.referralOrder?.administrations?.length > 0) {
        item.referralOrder.administrations =
          item.referralOrder.administrations.map((admin) => {
            admin.calcDosage = parseFloat(admin.calcDosage);
            admin.approvedDosage = parseFloat(admin.approvedDosage);
            return admin;
          });
      }
      return item;
    });
    setOtherOrdersThatNeedSaving([...ootns]);

    setShowReferralForm(false);

    if (orderFormData.referralId) {
      const formData = formatReferralFormData(orderFormData);
      setReferralFormData(formData);

      setShowReferralForm(true);
    }
  }, [orderFormData]);

  useEffect(() => {
    setShowReferralForm(false);
    if (referralFormData.isReadyToViewForm) {
      setShowReferralForm(true);
    }
  }, [referralFormData]);

  const handleLoadOrder = (dataObject) => {
    const selectedOrder = dataObject;
    const orderName = selectedOrder?.drugName;
    setShowLabDrawForm(orderName === referralType.LAB_DRAW_TEXT);
    setOriginalOrderDate();
    setOrderFormData(selectedOrder);
    // grab prescriber name to show on screen aic-2628
    const prescriber = selectedPatientInfo?.hcpProfile.items.find(
      (hp) => hp.prescriberId === selectedOrder.prescriberId
    );

    // [VIIV-607]: keep current address even if doesn't have fax number
    // also filter out other addresses w/o fax number
    const officeAddresses = (prescriber?.prescriber?.officeAddresses || [])
      .filter(
        (address) =>
          selectedOrder.prescriberOfficeAddressId === address.id ||
          address.faxNumber !== null
      )
      .map((address) => ({
        value: address.id,
        text: formatPrescriberAddress(address),
        default: address.default
      }));
    const cardAddresses = (
      prescriber?.prescriber?.officeAddresses || []
    ).filter(
      (address) =>
        selectedOrder.prescriberOfficeAddressId === address.id ||
        address.faxNumber !== null
    );
    setListCardPrescriberAddresses(cardAddresses);
    setListPrescriberOfficeAddresses(officeAddresses);

    const presName = `${prescriber?.prescriber?.prescriberFirstName} ${prescriber?.prescriber?.prescriberLastName}`;
    setPrescriberName(presName);

    const formData = formatReferralFormData(selectedOrder);
    setReferralFormData(formData);

    if (selectedOrder.orderNotes?.labTests) {
      setLabTestResultData(selectedOrder.orderNotes.labTests);
    }
    if (selectedOrder.adverseEvent) {
      setAdverseEventsData(selectedOrder.adverseEvent);
    }

    setStartedTherapy(selectedOrder.patientHasStartedTherapy);
    setIsReferralApproved(selectedOrder.referralApproved);

    setItemAdministrations(
      selectedOrder.referralOrder?.administrations.map((item) => ({
        ...item,
        text: item.orderName,
        value: item.orderName
      }))
    );

    setItemPreMeds(
      selectedOrder.referralOrder?.preMedications.map((item) => ({
        ...item,
        text: item.orderName,
        value: item.orderName
      }))
    );
  };

  useEffect(() => {
    if (selectedPatientInfo?.referral?.labTestResults?.length > 0) {
      setLabTestResultData(
        selectedPatientInfo?.referral?.labTestResults.map((item, index) => {
          return {
            id: index + 1,
            date: formatDateToAWS(item.date),
            labTakenDate: item.note,
            note: item.note,
            labTest: item.labTest,
            labExpiration: formatDateToAWS(item.labExpiration),
            selected: false,
            inEdit: true
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    const orderDefault = orderTypes.find(
      (item) => orderFormData.referralOrder?.orderType === item.value
    );
    const drugDefault = drugTypes.find(
      (item) => orderFormData?.drugType === item.value
    );
    const defaultAddress =
      listPrescriberOfficeAddresses?.find(
        (address) => address.value === orderFormData?.prescriberOfficeAddressId
      ) || null;
    // populate address dropdown
    if (referralDetailsFormRef?.current?.onChange) {
      referralDetailsFormRef.current.onChange(
        'selectedPrescriberOfficeAddress',
        {
          value: defaultAddress
        }
      );
    }
    // populate orderType dropdown
    if (referralDetailsFormRef?.current?.onChange) {
      referralDetailsFormRef.current.onChange('orderType', {
        value: orderDefault
      });
    }
    // populate drugType dropdown
    if (referralDetailsFormRef?.current?.onChange) {
      referralDetailsFormRef.current.onChange('drugType', {
        value: drugDefault
      });
    }
  }, [listPrescriberOfficeAddresses, orderFormData]);

  const updateContextPatientLabTests = (labTests) => {
    const curReferral = selectedPatientInfo?.referral?.drugReferrals?.find(
      ({ referralId }) => referralId === orderFormData.referralId
    );
    if (!curReferral) return;

    const updatedReferrals = selectedPatientInfo.referral.drugReferrals.map(
      (referral) => {
        if (referral.referralId === orderFormData.referralId) {
          return {
            ...referral,
            orderNotes: {
              ...referral.orderNotes,
              labTests
            }
          };
        }
        return referral;
      }
    );

    const updatedPatientInfo = { ...selectedPatientInfo };
    updatedPatientInfo.referral.drugReferrals = updatedReferrals;
    setSelectedPatientInfo(updatedPatientInfo);
  };

  const addLabTest = (labTest) => {
    const results = JSON.stringify({ labTestValue: labTest.labTestValue });
    const newLabTests = [
      ...labTestResultData,
      { date: labTest.labTestDate, results, ...labTest }
    ];
    setLabTestResultData(newLabTests);
    updateContextPatientLabTests(newLabTests);
  };

  const addAdverseEvent = ({
    advEventDate,
    advEventDrugName,
    advEventDetails
  }) => {
    setAdverseEventsData([
      ...adverseEventsData,
      {
        date: advEventDate,
        drugName: advEventDrugName,
        details: advEventDetails
      }
    ]);
  };

  const removeLabTest = (index) => {
    const newLabTests = [...labTestResultData];
    newLabTests.splice(index, 1);
    setLabTestResultData(newLabTests);
    updateContextPatientLabTests(newLabTests);
  };

  const removeAdverseEvent = (index) => {
    const advEvents = [...adverseEventsData];
    advEvents.splice(index, 1);
    setAdverseEventsData([...advEvents]);
  };

  const handleSubmitOrder = async (dataItem) => {
    let myOtherDX = [];
    if (orderFormData?.referralOrder?.otherDX) {
      myOtherDX = [
        {
          primaryDiagnosis:
            orderFormData?.referralOrder?.otherDX[0]?.primaryDiagnosis,
          description: orderFormData?.referralOrder?.otherDX[0]?.description,
          diagnosedBy: ''
        },
        {
          primaryDiagnosis:
            orderFormData?.referralOrder?.otherDX[1]?.primaryDiagnosis,
          description: orderFormData?.referralOrder?.otherDX[1]?.description,
          diagnosedBy: ''
        }
      ];
    }

    const oralLeadIn = {};
    if (dataItem.firstInjectionDate || dataItem.oralLeadInDate) {
      if (dataItem.firstInjectionDate) {
        oralLeadIn.dateOfFirstInjection = formatDateToAWSDateTime(
          dataItem.firstInjectionDate
        );
      }
      if (dataItem.oralLeadInDate) {
        oralLeadIn.dateOfLastDose = formatDateToAWSDateTime(
          dataItem.oralLeadInDate
        );
      }
    }

    const requestObject = {
      agentId: user.username,
      patientId: selectedPatientInfo.patientId,
      referral: {
        drugReferrals: [
          ...otherOrdersThatNeedSaving,
          {
            referralType: orderFormData?.referralType,
            referralUUID: orderFormData.referralUUID,
            referralId: orderFormData.referralId,
            originalReceivedDate:
              originalOrderDate || orderFormData?.originalReceivedDate,
            rxOrderFile: referralOrderUrl,
            drugId: orderFormData.drugId,
            drugName: orderFormData.drugName,
            isCompleted: !!dataItem.referralApproved,
            prescriberId: orderFormData.prescriberId,
            prescriberOfficeAddressId:
              dataItem.selectedPrescriberOfficeAddress?.value || 0,
            drugType: orderFormData.drugType || dataItem?.drugType?.value,
            patientHasStartedTherapy: !!dataItem.patientHasStartedTherapy,
            noOfTreatments: dataItem.noOfTreatments,
            firstTreatmentDate: dataItem.startTreatment
              ? formatDateToAWS(dataItem.startTreatment)
              : null,
            lastTreatmentDate: dataItem.lastTreatment
              ? formatDateToAWS(dataItem.lastTreatment)
              : null,
            inventorySource: dataItem.inventorySource
              ? dataItem.inventorySource
              : null,
            specialPharmName: dataItem.specPharmName,
            specialPharmPhoneNumber: dataItem.specPharmPhone,
            scheduling: dataItem.scheduling ? dataItem.scheduling : null,
            referralApproved: !!dataItem.referralApproved,
            reasonForArchiving: dataItem.reasonArchivingOrder
              ? dataItem.reasonArchivingOrder
              : null,
            orderNotes: {
              allergies: [],
              labTests: labTestResultData.map((item) => ({
                date: formatDateToAWSDateTime(item.date),
                drugName: item.drugName,
                note: `${item.note}`,
                type: 'LAB_TESTS',
                labTest: item.labTest,
                labExpiration: formatDateToAWS(item.labExpiration),
                results: JSON.stringify({ labTestValue: item.labTestValue })
              })),
              oralLeadIn
            },
            adverseEvent: adverseEventsData.map((item) => ({
              date: formatDateToAWS(item.date),
              drugName: item.drugName,
              details: item.details
            })),
            referralOrder: {
              referralOrderUUID: orderFormData.referralOrder?.referralOrderUUID,
              orderName: orderFormData.referralOrder?.orderName,
              orderType:
                orderFormData.referralOrder?.orderType ||
                dataItem?.orderType?.value,
              medicationType: orderFormData.referralOrder?.drugType,
              orderDate: orderFormData.referralOrder?.orderDate,
              orderExpires: orderFormData.referralOrder?.orderExpires,
              patientWeight: orderFormData.referralOrder?.patientWeight,
              unitOfWeight: orderFormData.referralOrder?.unitOfWeight,
              primaryDX: {
                primaryDiagnosis:
                  orderFormData.referralOrder.primaryDX?.primaryDiagnosis,
                description: orderFormData.referralOrder.primaryDX?.description,
                diagnosedBy: ''
              },
              otherDX: myOtherDX,
              administrations: getItemAdmin(itemAdministrations),
              preMedications: itemPreMeds,
              notes: dataItem.headerNotes
            },
            clinical: orderFormData.clinical
          }
        ]
      }
    };

    refFormSubmitted.current = false;
    updateReferralOrderCall(requestObject);
    let referrals = requestObject.referral.drugReferrals;

    // archived orders shouldn't be presented at the list anymore
    if (dataItem.referralArchived) {
      // remove selected order from the list
      referrals = referrals.filter(
        (ref) => ref.referralId !== orderFormData.referralId
      );

      // set first order by default (if any)
      if (referrals.length) {
        setSelected(0);
        handleLoadOrder(referrals[0]);
      }
    }

    // also need to update state to prevent data inconsistency
    setListReferralOrdersData(
      referrals.sort((a, b) => a.drugName.localeCompare(b.drugName))
    );
  };

  const removeReferralCallback = async (refList, refId, isReferralCopied) => {
    if (!isReferralCopied)
      await getPatientReferralOrdersCall(selectedPatientInfo.patientId);

    let referrals = refList;

    // remove selected order from the list
    referrals = referrals.filter((ref) => ref.referralId !== refId);

    // set first order by default (if any)
    if (referrals.length) {
      setSelected(0);
      handleLoadOrder(referrals[0]);
    }

    // also need to update state to prevent data inconsistency
    setListReferralOrdersData(
      referrals.sort((a, b) => a.drugName.localeCompare(b.drugName))
    );
  };

  const handleUpdateDxs = ({ primaryDX, otherDX }) => {
    const requestObject = {
      agentId: user.username,
      patientId: selectedPatientInfo.patientId,
      referral: {
        drugReferrals: [
          ...otherOrdersThatNeedSaving,
          {
            referralType: orderFormData.referralType,
            referralUUID: orderFormData.referralUUID,
            referralId: orderFormData.referralId,
            originalReceivedDate:
              originalOrderDate || orderFormData?.originalReceivedDate,
            rxOrderFile: referralOrderUrl,
            drugId: orderFormData.drugId,
            drugName: orderFormData.drugName,
            isCompleted: referralFormData.isReferralApproved,
            prescriberId: orderFormData.prescriberId,
            drugType: orderFormData.drugType,
            patientHasStartedTherapy: referralFormData.patientHasStartedTherapy,
            noOfTreatments: referralFormData.noOfTreatments,
            firstTreatmentDate: referralFormData.startTreatment
              ? formatDateToAWS(referralFormData.startTreatment)
              : null,
            lastTreatmentDate: referralFormData.lastTreatment
              ? formatDateToAWS(referralFormData.lastTreatment)
              : null,
            inventorySource:
              referralFormData.inventorySource &&
              referralFormData.inventorySource !== ''
                ? referralFormData.inventorySource
                : null,
            specialPharmName: referralFormData.specPharmName,
            specialPharmPhoneNumber: referralFormData.specPharmPhone,
            scheduling: referralFormData.scheduling
              ? referralFormData.scheduling
              : null,
            referralApproved: referralFormData.referralApproved,
            reasonForArchiving:
              referralFormData.reasonArchivingOrder &&
              referralFormData.reasonArchivingOrder !== ''
                ? referralFormData.reasonArchivingOrder
                : null,
            orderNotes: {
              allergies: [],
              labTests: labTestResultData.map((item) => ({
                date: formatDateToAWSDateTime(item.date),
                drugName: item.drugName,
                note: `${item.note}`,
                type: 'LAB_TESTS',
                labTest: item.labTest,
                labExpiration: formatDateToAWS(item.labExpiration)
              }))
            },
            adverseEvent: adverseEventsData.map((item) => ({
              date: formatDateToAWS(item.date),
              drugName: item.drugName,
              details: item.details
            })),
            referralOrder: {
              referralOrderUUID: orderFormData.referralOrder?.referralOrderUUID,
              orderName: orderFormData.referralOrder?.orderName,
              orderType: orderFormData.referralOrder?.orderType,
              medicationType: orderFormData.referralOrder?.drugType,
              orderDate: orderFormData.referralOrder?.orderDate,
              orderExpires: orderFormData.referralOrder?.orderExpires,
              patientWeight: orderFormData.referralOrder?.patientWeight,
              unitOfWeight: orderFormData.referralOrder?.unitOfWeight,
              primaryDX,
              otherDX,
              administrations: getItemAdmin(itemAdministrations),
              preMedications: itemPreMeds,
              notes: referralFormData.headerNotes
            },
            clinical: orderFormData.clinical
          }
        ]
      }
    };

    refFormSubmitted.current = false;

    updateReferralOrderCall(requestObject).then(() => {
      toggleDxForm(); // close update DX modal

      // update local state for selected order (Dxs)
      setOrderFormData({
        ...orderFormData,
        referralOrder: { ...orderFormData.referralOrder, primaryDX, otherDX }
      });

      // also need to update same Dx codes within a list of orders, not just currently selected one
      const orders = [...listReferralOrdersData];
      const orderInList = orders.find(
        (order) => order.referralId === orderFormData.referralId
      );

      if (orderInList) {
        orderInList.referralOrder = {
          ...orderInList.referralOrder,
          primaryDX,
          otherDX
        };
        setListReferralOrdersData(orders);
      }
    });
  };

  const updateReferralOrderCall = async (requestObject) => {
    setLoading(true);

    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateReferralOrder,
        variables: { input: requestObject }
      });

      if (data?.data?.updateReferral) {
        showSuccess('Referral updated sucessfully.');
        setStatusUpdate(!statusUpdate);
        setLoading(false);
        setReload(!reload);
        /* parentToChild(null);
        setParentDeleteData(null); */
      }
    } catch (err) {
      logApiException(err, {
        view: 'CurrentOrder',
        endpoint: 'updateReferralOrderCall',
        patientId: requestObject
      });
      setLoading(false);
      showError('Error: updateReferralOrderCall');
    }
  };

  const patientKilos = getPatientWeightFromReferral(
    orderFormData.referralOrder
  );

  const preMedAdd = (medItem) => {
    setItemPreMeds([...itemPreMeds, medItem]);
  };

  const removePreMed = (itemIndex) => {
    const preMedList = [...itemPreMeds];
    preMedList.splice(itemIndex, 1);
    setItemPreMeds(preMedList);
  };
  const renderReferral = () => {
    return (
      <>
        <Form
          onSubmit={handleSubmitOrder}
          initialValues={referralFormData}
          ref={referralDetailsFormRef}
          key={JSON.stringify(referralFormData)}
          render={(formRenderProps) => (
            <form
              id='formOrder'
              onSubmit={formRenderProps.onSubmit}
              className='k-form pl-3 pr-3 mt-3'
            >
              <article>
                <div className='row'>
                  <div className='col-md-2'>ORDER NAME:</div>
                  <div className='col-md-3'>
                    <strong>{orderFormData.referralOrder?.orderName}</strong>
                  </div>
                  {!showLabDrawForm && (
                    <>
                      <div className='col-md-2'>
                        <RequiredFormLabel text='ORDER TYPE' />
                      </div>
                      <div className='col-md-3'>
                        <Field
                          name='orderType'
                          label=''
                          component={DropDownList}
                          data={orderTypes}
                          textField='text'
                          valueField='value'
                          validator={referralForm.orderType.inputValidator}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className='row mt-14'>
                  <div className='col-md-2'>PRESCRIBING HCP:</div>
                  <div className='col-md-3'>
                    <Typography.p textTransform='capitalize' fontWeight='bold'>
                      {prescriberName} (#{orderFormData.prescriberId})
                    </Typography.p>
                  </div>
                  {!showLabDrawForm && (
                    <>
                      <div className='col-md-2'>
                        <RequiredFormLabel text='DRUG TYPE' />
                      </div>
                      <div className='col-md-3'>
                        <Field
                          name='drugType'
                          label=''
                          component={DropDownList}
                          data={drugTypes}
                          textField='text'
                          valueField='value'
                          validator={referralForm.drugType.inputValidator}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className='row mt-14'>
                  <div className='col-md-3'>
                    PRESCRIBING HCP OFFICE ADDRESS:
                  </div>
                  <div className='col-md-6'>
                    <Field
                      name='selectedPrescriberOfficeAddress'
                      label=''
                      component={DropDownList}
                      data={listPrescriberOfficeAddresses}
                      textField='text'
                      valueField='value'
                      validator={
                        referralForm.selectedPrescriberOfficeAddress
                          .inputValidator
                      }
                    />
                  </div>
                </div>
                <Field
                  name='OfficeAddress'
                  component={() => (
                    <PrescriberAddressesCurrent
                      addresses={listCardPrescriberAddresses}
                    />
                  )}
                />

                <div className='row mt-14'>
                  <div className='col-md-2'>ORDER DATE:</div>
                  <div className='col-md-3'>
                    <strong>{orderFormData.referralOrder?.orderDate}</strong>
                  </div>
                  <>
                    <div className='col-md-2'>PRIMARY DX:</div>
                    <div className='col-md-4'>
                      <strong>
                        {
                          orderFormData.referralOrder?.primaryDX
                            ?.primaryDiagnosis
                        }
                      </strong>
                      <br />
                      {orderFormData.referralOrder?.primaryDX?.description}
                      <br />
                      <div className='mt-2'>
                        <span className='help-link' onClick={toggleDxForm}>
                          Add/Update DX codes
                        </span>
                      </div>
                    </div>
                  </>
                </div>
                <div className='row mt-14'>
                  <div className='col-md-2'>ORDER EXP DATE:</div>
                  <div className='col-md-3'>
                    <strong>{orderFormData.referralOrder?.orderExpires}</strong>
                  </div>
                  {orderFormData.referralOrder?.otherDX?.length > 0 && (
                    <>
                      <div className='col-md-2'>OTHER DX:</div>
                      <div className='col-md-4'>
                        {orderFormData.referralOrder?.otherDX.map((dx, i) => (
                          <div key={i}>
                            <strong>{dx.primaryDiagnosis}</strong>
                            {'  '}
                            {dx.description && <div>{dx.description}</div>}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
                {orderFormData.referralOrder && patientKilos > 0 && (
                  <div className='row mt-14'>
                    <div className='col-md-2'>ORIGINAL ORDER WEIGHT:</div>
                    <div className='col-md-3'>
                      <strong>{patientKilos} KG</strong>
                    </div>
                    <div className='col-md-2'>&nbsp;</div>
                    <div className='col-md-4'>&nbsp;</div>
                  </div>
                )}

                <hr />
                <AttachReferralDocument
                  orderData={orderFormData}
                  rxOrderValue={rxOrderValue?.[0]}
                />
                <hr />
                {/* ADMINISTRATION */}
                <ReferralAdmin
                  orderFormData={orderFormData}
                  showLabDrawForm={showLabDrawForm}
                />

                <hr />

                {/* ORAL LEAD-IN */}
                {isVIIV && (
                  <>
                    <OralLeadIn formRenderProps={formRenderProps} />
                    <hr />
                  </>
                )}
                {/* PRE-MEDICATIONS */}
                {!showLabDrawForm && (
                  <>
                    <div className='row'>
                      <div className='col-md-2 mt-08'>PRE-MEDICATION:</div>
                      <div className='col-md-10'>
                        <OrderPreMeds
                          preMeds={itemPreMeds}
                          addPreMed={preMedAdd}
                          removePreMed={removePreMed}
                          formRenderProps={formRenderProps}
                        />
                      </div>
                    </div>

                    <hr />

                    {/* ALLERGIES */}
                    <div className='row'>
                      <div className='col-md-2 mt-08'>ALLERGIES:</div>
                      <div className='col-md-10'>
                        <PatientAllergiesLog />
                        <div className='mt-3'>
                          <PatientLogInfo />
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className='row'>
                      <div className='col-md-2 mt-08'>CURRENT MEDS:</div>
                      <div className='col-md-10'>
                        <PatientMedsLog />
                        <div className='mt-3'>
                          <PatientLogInfo />
                        </div>
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                <div className='row'>
                  <div className='col-md-2 mt-16'>ORDER NOTES:</div>
                  <div className='col-10'>
                    <Field
                      name='headerNotes'
                      style={{ width: '100%', minHeight: '100px' }}
                      autoSize
                      component={TextArea}
                    />
                  </div>
                </div>

                <hr />
                {!showLabDrawForm && (
                  <>
                    <div className='row mt-12'>
                      <div className='col-md-2 mt-16'>PROGRESS NOTES:</div>
                      <div className='col-md-10'>
                        {/* LAB / TESTS */}
                        <ReferralLabTests
                          labTests={labTestResultData}
                          addLabTest={addLabTest}
                          removeLabTest={removeLabTest}
                          formRenderProps={formRenderProps}
                        />
                        {/* ADVERSE EVENTS */}
                        <ReferralAdverseEvents
                          adverseEvents={adverseEventsData}
                          addAdverseEvent={addAdverseEvent}
                          removeAdverseEvent={removeAdverseEvent}
                          formRenderProps={formRenderProps}
                        />
                      </div>
                    </div>

                    <hr />
                  </>
                )}
                {showReferralForm && (
                  <>
                    {!showLabDrawForm && (
                      <>
                        <div className='row'>
                          <div className='col-md-2 mt-08'>
                            Continuation of Care: &nbsp;
                            <Field
                              component={Switch}
                              name='patientHasStartedTherapy'
                              onLabel='Yes'
                              offLabel='No'
                              checked={startedTherapy}
                              onChange={(event) =>
                                setStartedTherapy(event.value)
                              }
                            />
                          </div>

                          {startedTherapy && (
                            <>
                              <div className='col-md-2'>
                                # Treatments
                                <Field
                                  name='noOfTreatments'
                                  label=''
                                  component={Input}
                                />
                              </div>
                              <div className='col-md-3'>
                                First Treatment Date
                                <Field
                                  name='startTreatment'
                                  label=''
                                  component={DatePickerField}
                                />
                              </div>
                              <div className='col-md-3'>
                                Last Treatment Date
                                <Field
                                  name='lastTreatment'
                                  label=''
                                  component={DatePickerField}
                                />
                              </div>
                            </>
                          )}
                        </div>

                        <div className='row mt-22'>
                          <div className='col-md-2'>
                            <RequiredFormLabel text='INVENTORY SOURCE' />
                          </div>
                          <div className='col-md-3'>
                            <Field
                              name='inventorySource'
                              component={DropDownList}
                              data={inventorySources}
                              validator={
                                referralForm.inventorySource.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-3'></div>
                          <div className='col-md-3'></div>
                        </div>

                        <div className='row mt-08'>
                          <div className='col-md-2 mt-08'>
                            <RequiredFormLabel text='SCHEDULING' />
                          </div>
                          <div className='col-md-6'>
                            <Field
                              name='scheduling'
                              component={FormRadioGroup}
                              layout='horizontal'
                              data={schedulingTypes}
                              validator={referralForm.scheduling.inputValidator}
                            />
                          </div>
                        </div>

                        <div className='row mt-08'>
                          <div className='col-md-2 mt-16'>
                            PREFERRED SPECIALTY PHARMACY:
                          </div>
                          <div className='col-md-3 mt-06'>
                            <Field
                              name='specPharmName'
                              label='Specialty Pharm Name'
                              component={InputField}
                              validator={
                                referralForm.specPharmName.inputValidator
                              }
                            />
                          </div>
                          <div className='col-md-3 mt-06'>
                            <Field
                              name='specPharmPhone'
                              label='Specialty Pharm Phone'
                              component={InputField}
                              validator={
                                referralForm.specPharmPhone.inputValidator
                              }
                            />
                          </div>
                        </div>

                        <hr />
                      </>
                    )}
                    <div className='row'>
                      <div className='col-md-3 mt-08'>
                        REFERRAL IS COMPLETE: &nbsp;
                        <Field
                          component={Switch}
                          name='referralApproved'
                          onLabel='Yes'
                          offLabel='No'
                          checked={isReferralApproved}
                          onChange={(event) =>
                            setIsReferralApproved(event.value)
                          }
                        />
                      </div>

                      <div className='col-md-1 mt-06'>
                        {isReferralApproved && <></>}
                      </div>
                    </div>

                    <hr />

                    <div className='row p-3 mt-18'>
                      <div className='col-2'>
                        <FormButton
                          disabled={
                            !canEdit(UserRoleTypes.CurrentOrders) ||
                            !referralOrderUrl ||
                            !hasItemAdministrations
                          }
                          className='k-button pageButton'
                          type='submit'
                          label='Update'
                          loading={loading}
                        />
                      </div>
                      <div className='col-2'>
                        {!hasItemAdministrations && (
                          <div
                            style={{ color: 'red', fontSize: '8pt' }}
                            className='mt-2'
                          >
                            Alert: Please add an Administration.
                          </div>
                        )}
                      </div>
                      <div className='col-4'>&nbsp;</div>
                      <div className='col-2' style={{ textAlign: 'right' }}>
                        {!orderFormData.archiveOrder &&
                          canEdit(UserRoleTypes.CurrentOrders) && (
                            <ArchiveOrder
                              agentId={user.username}
                              refList={listReferralOrdersData}
                              referralId={orderFormData.referralId}
                              patientId={selectedPatientInfo.patientId}
                              orderFormData={orderFormData}
                              onArchiveClick={(e) => {
                                e.preventDefault();
                                handleArchiveClick();
                              }}
                            />
                          )}
                      </div>
                      <div className='col-2' style={{ textAlign: 'right' }}>
                        <DiscontinueOrder
                          agentId={user.username}
                          refList={listReferralOrdersData}
                          referralId={orderFormData.referralId}
                          patientId={selectedPatientInfo.patientId}
                          removeReferralCallback={removeReferralCallback}
                        />
                      </div>
                    </div>
                    <hr />
                    <ReferralIdsDisplay
                      referralId={orderFormData.referralId}
                      referralUUID={orderFormData.referralUUID}
                    />
                  </>
                )}
              </article>
            </form>
          )}
        />
        {showDxForm && (
          <DxUpdateForm
            primaryDx={orderFormData.referralOrder?.primaryDX}
            otherDx={orderFormData.referralOrder?.otherDX}
            toggleDialog={toggleDxForm}
            handleSubmit={handleUpdateDxs}
          />
        )}
        {showArchiveModal && (
          <ArchiveCurrentOrderModal
            agentId={user.username}
            refList={listReferralOrdersData}
            referralId={orderFormData.referralId}
            removeReferralCallback={removeReferralCallback}
            patientId={selectedPatientInfo.patientId}
            orderFormData={orderFormData}
            onClose={() => setShowArchiveModal(false)}
            onChangeTab={onChangeTab}
            onSelectArchiveOrder={() =>
              onSelectArchiveOrder(listReferralOrdersData[selected])
            }
          />
        )}
      </>
    );
  };

  return (
    <div className='row'>
      <div className='col'>
        <div className='k-form pl-3 pr-3 pt-1'>
          <div className='row'>
            <div className='col-md-3 pageTitle'>Manage Current Orders</div>
          </div>
        </div>

        <div className='col-md-12 mt-3' id='tab-strip-gray'>
          {listReferralOrdersData && listReferralOrdersData.length > 0 ? (
            <TabStrip
              selected={selected}
              onSelect={handleSelect}
              className='tsg-container'
            >
              {listReferralOrdersData.map((item) => (
                <TabStripTab title={item.drugName} key={item.referralId}>
                  {renderReferral()}
                </TabStripTab>
              ))}
            </TabStrip>
          ) : (
            <div className=''>
              <Badge text='No active orders available' type='warning' />
            </div>
          )}
        </div>
      </div>
      {visibleNotesDialog && (
        <Dialog
          title='Clinical Notes'
          width={800}
          height={500}
          onClose={toggleNotes}
        >
          <ClinicalNotes />
        </Dialog>
      )}
    </div>
  );
};

export default CurrentOrder;
