import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// kendo
import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { orderBy, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form } from '@progress/kendo-react-form';

// components
import { getAdminSequenceNumber } from '@/components/Scheduling/schedulingQueries';
import { ReferralIdsDisplay } from '@/components/common-components/ReferralIdsDisplay';
import WindowDialog from '@/components/common-components/WindowDialog';
import { AppointmentDetails } from './components/AppointmentDetails';

// gql
import { updateScheduleEvent, updatePendingEvent } from '@/graphql/mutations';
import { connectToGraphqlAPI } from '@/provider';
import {
  onUpdateScheduleEvent,
  onUpdatePendingEvent
} from '@/graphql/subscriptions';

// context
import { LocationContext } from '@/context/LocationContext';
import { NotifContext } from '@/context/NotifContext';
import { UserContext } from '@/context/UserContext';
import { StatusContext } from '@/context/StatusContext';
import { LogContext } from '@/context/LogContext';

// utils
import { formatDateToAWSDateTime } from '@/common/DateHelper';
import { DropDownListField, validateInput } from '@/common/Validation';
import { Constants } from '@/constants';
import { initialSort } from './AppointmentsList.constants';
import { getStatusTitle } from './AppointmentsList.utils';
import { SCHEDULE_EVENTS } from '@/constants/enum';
import { mapCalendarEventType } from '@/common/Mappers';

const initialDataState = {};

const AppointmentsList = ({
  childToParent,
  patientAppointments,
  patientData,
  listReferralOrdersData,
  selectedLocationId
}) => {
  const [patientAppointmentData, setPatientAppointmentData] = useState([]);
  const [appointmentDataItem, setDataItem] = useState(null);
  const [showSetNewReferral, setShowSetNewReferral] = useState(false);
  const [showUpdatePending, setShowUpdatePending] = useState(false);
  const [sort, setSort] = useState(initialSort);
  const [dataState, setDataState] = useState();
  const [resultState, setResultState] = useState(
    process(patientAppointmentData, initialDataState)
  );
  const { logApiException } = useContext(LogContext);

  const history = useHistory();

  const {
    getLocation,
    getTimeByLocation,
    getDateByLocation,
    setCurrentLocation
  } = useContext(LocationContext);
  const { agent } = useContext(UserContext);
  const { appointmentListRefresh, setAppointmentListRefresh } =
    useContext(StatusContext);
  const { showError, showWarning } = useContext(NotifContext);

  const onDataStateChange = useCallback(
    (e) => {
      setDataState(e.dataState); // store for use
      setResultState(process(patientAppointmentData, e.dataState));
    },
    [patientAppointments]
  );

  const onUpdateScheduleEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdateScheduleEvent
      }).subscribe({
        next: (result) => {
          childToParent(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'AppointmentList',
        endpoint: 'onUpdateScheduleEvent'
      });
      showError(err);
    }
  };
  const onUpdatePendingEventCall = async () => {
    try {
      await connectToGraphqlAPI({
        graphqlQuery: onUpdatePendingEvent
      }).subscribe({
        next: (result) => {
          childToParent(result);
        }
      });
    } catch (err) {
      logApiException(err, {
        view: 'AppointmentList',
        endpoint: 'onUpdatePendingEvent'
      });
      showError(err);
    }
  };

  /// //Start Add referral/////
  const handleAddReferralClose = () => {
    setShowSetNewReferral(false);
  };
  const handleAddToPendingClose = () => {
    setShowUpdatePending(false);
  };

  const handleNewReferralSubmit = async (dataItem) => {
    const {
      providerId,
      chairId,
      patientId,
      title,
      locationId,
      oldEndTime,
      oldStartTime,
      id
    } = appointmentDataItem;

    const referralId = dataItem?.referralId?.referralId
      ? dataItem?.referralId?.referralId
      : '';

    const adminSequence = await getAdminSequenceNumber(
      patientId,
      referralId,
      formatDateToAWSDateTime(oldStartTime),
      id
    );

    if (adminSequence?.statusCode === '400') {
      showWarning(adminSequence.message);
      return;
    }

    const requestObject = {
      id,
      adminSequenceNumber: adminSequence?.adminStage?.adminSequenceNumber,
      agentId: agent?.agentId,
      chairId: chairId || 'c001',
      createdBy: agent?.agentId,
      endTime: oldEndTime,
      endTimeZone: 'UTC',
      locationId: locationId || selectedLocationId,
      patientId,
      providerId: providerId || '1124166244',
      referralId,
      resources: '',
      startTime: oldStartTime,
      startTimeZone: 'UTC',
      status: 'SCHEDULED',
      title,
      updatedBy: agent?.agentId
    };
    updateScheduleEventCall(requestObject);
    handleAddReferralClose();
  };
  const handleAddToPendingSubmit = async (dataItem) => {
    const {
      providerId,
      chairId,
      patientId,
      title,
      locationId,
      oldEndTime,
      oldStartTime,
      id
    } = appointmentDataItem;

    const referralId = dataItem?.referralId?.referralId
      ? dataItem?.referralId?.referralId
      : '';

    const adminSequence = await getAdminSequenceNumber(
      patientId,
      referralId,
      formatDateToAWSDateTime(oldStartTime),
      id
    );

    if (adminSequence?.statusCode === '400') {
      showWarning(adminSequence.message);
      return;
    }
    const requestObject = {
      id,
      adminSequenceNumber: adminSequence?.adminStage?.adminSequenceNumber,
      chairId: chairId || 'c001',
      createdBy: agent?.agentId,
      endTime: oldEndTime,
      endTimeZone: 'UTC',
      locationId: locationId || selectedLocationId,
      patientId,
      providerId: providerId || '1124166244',
      referralId,
      resources: '',
      startTime: oldStartTime,
      startTimeZone: 'UTC',
      status: 'SCHEDULED',
      title,
      updatedBy: agent?.agentId,
      eventType: SCHEDULE_EVENTS.PLACEHOLDER,
      drugName: dataItem.referralId.drugName
    };
    updatePendingEventCall(requestObject);
    handleAddToPendingClose();
  };

  const updateScheduleEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updateScheduleEvent,
        variables: { input: requestObject }
      });

      if (data?.data?.updateScheduleEvent?.statusCode !== '400') {
        setAppointmentListRefresh(!appointmentListRefresh);
      } else if (data.data.updateScheduleEvent.statusCode === '400') {
        showError(data.data.updateScheduleEvent.message);
      }
    } catch (err) {
      logApiException(err, {
        view: 'AppointmentList',
        endpoint: 'updateScheduleEvent',
        patientId: requestObject?.patientId
      });
      showError('Error: updateScheduleEventCall');
    }
  };
  const updatePendingEventCall = async (requestObject) => {
    try {
      const data = await connectToGraphqlAPI({
        graphqlQuery: updatePendingEvent,
        variables: { agentId: agent?.agentId, input: requestObject }
      });

      if (data?.data?.updatePendingEvent?.statusCode !== '400') {
        setAppointmentListRefresh(!appointmentListRefresh);
      } else if (data.data.updatePendingEvent.statusCode === '400') {
        showError(data.data.updatePendingEvent.message);
      }
    } catch (err) {
      logApiException(err, {
        view: 'AppointmentList',
        endpoint: 'updatePendingEvent',
        patientId: requestObject?.patientId
      });
      showError('Error: updatePendingEventCall');
    }
  };

  const sendToCalendar = (dataItem) => {
    const locationData = getLocation(dataItem?.locationId);
    if (locationData) {
      setCurrentLocation(locationData);
      history.push('/scheduler', {
        searchDate: dataItem?.serviceDate,
        searchLocationId: dataItem?.locationId,
        locationName: locationData?.locationName,
        state: locationData?.state,
        chairData: locationData?.chairs
      });
    }
  };

  const expandChange = (event) => {
    const newData = patientAppointmentData.map((item) => {
      if (item.id === event.dataItem.id) {
        item.expanded = !event.dataItem.expanded;
      }
      return item;
    });
    setResultState(process(newData, dataState));
  };

  /// ///End add referral
  const formating = () => {
    const patientAppointmentsFormating = patientAppointments?.map(
      ({
        startTime,
        endTime,
        locationId,
        locationName,
        orderName,
        status,
        referralId,
        adminSequenceNumber,
        drugName,
        eventType,
        ...rest
      }) => {
        const checkOrderName = !adminSequenceNumber
          ? drugName
          : patientData.referral?.drugReferrals?.find(
              (ref) => ref.referralId === referralId
            )?.referralOrder?.orderName ||
            `${orderName} (A)` ||
            'Order Not Found';

        const orderToDisplay = checkOrderName
          ? checkOrderName.toUpperCase()
          : mapCalendarEventType(eventType);

        return {
          ...rest,
          serviceDate: startTime
            ? getDateByLocation(locationId, startTime)
            : startTime,
          startTime: startTime
            ? getTimeByLocation(locationId, startTime)
            : startTime,
          endTime: endTime ? getTimeByLocation(locationId, endTime) : endTime,
          locationName,
          orderName: orderToDisplay,
          status: getStatusTitle(status),
          oldStartTime: startTime,
          oldEndTime: endTime,
          locationId,
          referralId
        };
      }
    );

    setPatientAppointmentData(patientAppointmentsFormating);
  };

  const orderNameCell = ({ dataItem }) => {
    return (
      <td>
        {dataItem?.orderName}
        <ReferralIdsDisplay
          referralId={dataItem?.referralId}
          referralUUID={dataItem?.referralUUID}
          grid
        />
      </td>
    );
  };

  const checkAppointmentForm = {
    providerId: {
      value: '',
      inputValidator: (value) => {
        return validateInput({
          providerId: { ...checkAppointmentForm.providerId, value }
        });
      },
      validations: [
        {
          type: 'required',
          message: Constants.ErrorMessage.FirstName_REQUIRED
        }
      ]
    }
  };

  // MAIN INITIATOR
  useEffect(() => {
    onUpdateScheduleEventCall();
    onUpdatePendingEventCall();
  }, []);

  useEffect(() => {
    formating();
  }, [patientAppointments]);

  useEffect(() => {
    setDataState(initialDataState);
    setResultState(process(patientAppointmentData, initialDataState));
  }, [patientAppointmentData]);

  const render = {
    statusCell: (patientAppointmentsFormating) => {
      const { status, reason } = patientAppointmentsFormating.dataItem;
      const isCancelled = status === 'Appointment Cancelled';
      const isInvalid = status === 'Invalid';

      const renderReasonHint = () => {
        return isCancelled || isInvalid ? (
          <>
            <br />
            <small>({reason})</small>
          </>
        ) : (
          <div />
        );
      };

      return (
        <td>
          {status}
          {renderReasonHint()}
        </td>
      );
    },
    selectAction: ({ dataItem }) => {
      const checkStatus = ((dataItem?.orderName?.includes(' (A)') &&
        dataItem?.status === 'Invalid') ||
        (dataItem?.status === 'Pending' &&
          listReferralOrdersData?.length !== 0)) && (
        <Button
          type='button'
          title='Edit Quantity'
          onClick={() => {
            if (dataItem.status === 'Pending') {
              setShowUpdatePending(true);
            } else {
              setShowSetNewReferral(true);
            }
            setDataItem(dataItem);
          }}
        >
          Add Referral
        </Button>
      );
      return (
        <td>
          <div className='row'>
            <div className='col-md-4'>{checkStatus}</div>
          </div>
        </td>
      );
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-12 ml-1 mr-1 mt-3'>
          <div>
            <div className='a-grid__header'>
              <div>Appointments</div>
            </div>
            <Grid
              className='a-grid'
              selectedField='selected'
              data={{ data: orderBy(resultState.data, sort) }}
              onDataStateChange={onDataStateChange}
              {...dataState}
              sortable
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
              fixedScroll
              onRowDoubleClick={(e) => sendToCalendar(e.dataItem)}
              expandField='expanded'
              onExpandChange={expandChange}
              detail={AppointmentDetails}
              total={1} // total set to stop paging error in the browser. Remove if you want to use paging.
            >
              <Column field='serviceDate' title='DATE' sortable />
              <Column
                title='ORDER NAME'
                cell={orderNameCell}
                sortable
                width={'300px'}
              />
              <Column field='startTime' title='START TIME' sortable />
              <Column field='endTime' title='END TIME' sortable />
              <Column field='locationName' title='LOCATION' sortable />
              <Column
                field='status'
                title='APPOINTMENT STATUS'
                sortable
                cell={render.statusCell}
              />
              <Column
                field='select'
                title='ADD REFERRAL'
                cell={render.selectAction}
              />
            </Grid>
          </div>
        </div>
      </div>

      {showSetNewReferral && (
        <WindowDialog
          id='dialogPatientNotSeen'
          title='Add Referral'
          height={260}
          width={500}
          initialTop={10}
          showDialog
          onClose={handleAddReferralClose}
        >
          <Form
            id='formPatientNotSeen'
            onSubmit={handleNewReferralSubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3 k-form-red'
              >
                <div className='row mt-4'>
                  <div className='col-3'>Add Referral:</div>
                  <div className='col-9'>
                    <Field
                      component={DropDownListField}
                      data={listReferralOrdersData}
                      textField='text'
                      valueField='value'
                      name='referralId'
                      validator={checkAppointmentForm.providerId.inputValidator}
                    />
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <Button type='submit' className='btn blue'>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </WindowDialog>
      )}
      {showUpdatePending && (
        <WindowDialog
          id='dialogAddToPending'
          title='Add Referral To Pending Appointment'
          height={260}
          width={500}
          initialTop={10}
          showDialog
          onClose={handleAddToPendingClose}
        >
          <Form
            id='formPatientNotSeen'
            onSubmit={handleAddToPendingSubmit}
            render={(formRenderProps) => (
              <form
                onSubmit={formRenderProps.onSubmit}
                className='k-form pl-3 pr-3 pt-3 k-form-red'
              >
                <div className='row mt-4'>
                  <div className='col-3'>Add Referral:</div>
                  <div className='col-9'>
                    <Field
                      component={DropDownListField}
                      data={listReferralOrdersData}
                      textField='text'
                      valueField='value'
                      name='referralId'
                      validator={checkAppointmentForm.providerId.inputValidator}
                    />
                  </div>
                </div>

                <div className='row mt-10'>
                  <div className='col my-3 d-flex justify-content-center'>
                    <Button type='submit' className='btn blue'>
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        </WindowDialog>
      )}
    </>
  );
};

export { AppointmentsList };
